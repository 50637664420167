import { getErrorModel, getFieldJSONConfig, getModel, getReadOnlyFields, onSumbitStatus } from "../Store/FormConfig/form.selectors";
import { AppInput } from "../AppComponents/Input";
import { AppSelect } from "../AppComponents/Select";
import { AppFile } from "../AppComponents/File";
import { useSelector } from "react-redux";
import React from "react";
import styled from "styled-components";
import AppDatePicker from "../AppComponents/AppDatePicker";
import { AppCheckbox } from "../AppComponents/checkbox";
import { AppPhone } from "../AppComponents/AppPhone";
export const Field = (props: any) => {
    const fieldConfig = useSelector(getFieldJSONConfig);
    const errorModelData = useSelector(getErrorModel)
    const modelData = useSelector(getModel);
    const submitStatus = useSelector(onSumbitStatus);
    const readOnlyFields = useSelector(getReadOnlyFields); 

    return (
        <FiledConfigWrapper>
            <div className="fieldContainer" style={{ margin: "20px 0px" }}>
                {props?.field.label && !props?.field.lable_hide ?
                    // <Label field={field} />
                    <label className="field-lable" title={props?.field.label}>
                        <span>
                            {props?.field.label}
                            {/* <span className="file-info">
                                {props?.field.displayText ?
                                    <i className="fa fa-info-circle"
                                        data-toggle="tooltip" data-placement="top"
                                        title={props?.field.displayText}
                                        aria-hidden="true"></i>
                                    : null}
                            </span> */}
                            <span>
                                {props?.field?.required ? (
                                    <span className="required"> *</span>
                                ) : null}
                            </span>
                        </span>
                    </label>

                    : null}
                {props?.field.type === "dropdown" ?
                    <AppSelect
                        field={props?.field}
                        model={modelData}
                        errorModel={errorModelData}
                        fieldConfig={fieldConfig}
                        sectionId={props?.sectionId}
                        setLoading={props?.setLoading}
                    />
                    : null}
                {props?.field.type === "multiselect" ?
                    // <SelectField
                    //     field={field}
                    //     model={modelData}
                    //     errorModel={errorModelData}
                    //     fieldConfig={fieldConfig}
                    //     isMulti={true}
                    // />
                    <></>
                    : null}
                {props?.field.type === "text" || props?.field.type === "string" ?
                    <AppInput
                        field={props?.field}
                        model={modelData}
                        errorModel={errorModelData}
                        fieldConfig={fieldConfig}
                        sectionId={props?.sectionId}
                        setLoading={props?.setLoading}

                    />
                    : null}
                {props?.field.type === "number" ?
                    <AppInput
                        field={props?.field}
                        model={modelData}
                        errorModel={errorModelData}
                        fieldConfig={fieldConfig}
                        sectionId={props?.sectionId}
                        type="number"
                    />
                    : null}
                {props?.field.type === "checkbox" ?
                    <AppCheckbox
                        field={props?.field}
                        model={modelData}
                        errorModel={errorModelData}
                        fieldConfig={fieldConfig}
                        sectionId={props?.sectionId}
                        type="checkbox"
                    />
                    : null}
                {props?.field.type === "email" ?
                    <AppInput
                        field={props?.field}
                        model={modelData}
                        errorModel={errorModelData}
                        fieldConfig={fieldConfig}
                        sectionId={props?.sectionId}
                    />
                    : null}
                {props?.field.type === "phone" ?
                    <AppPhone
                        field={props?.field}
                        model={modelData}
                        errorModel={errorModelData}
                        fieldConfig={fieldConfig}
                        showIcon={true}
                        iconTitle={"+91"}
                        type="number"
                    />
                    : null}
                {props?.field.type === "datetime" ?
                    <AppDatePicker
                        field={props?.field}
                        model={modelData}
                        errorModel={errorModelData}
                        fieldConfig={fieldConfig}
                        sectionId={props?.sectionId} showError={false} />
                    : null}
                {props?.field.type === "password" ?
                    <AppInput
                        field={props?.field}
                        model={modelData}
                        errorModel={errorModelData}
                        sectionId={props?.sectionId}
                        type="password"
                    />
                    : null}
                {props?.field.type === "radio" ?
                    // <Appradio
                    //     field={field}
                    //     model={modelData}
                    //     errorModel={errorModelData}
                    //     fieldConfig={fieldConfig}
                    // />
                    <></>
                    : null}
                {props?.field.type === "file" ?
                    <AppFile
                        field={props?.field}
                        model={modelData}
                        fieldConfig={fieldConfig}
                        errorModel={errorModelData}
                        type="file"
                        sectionId={props?.sectionId}
                    />
                    : null}
                {/* <InfoWapper>
                    In case you do not have a last name use dot (.)
                </InfoWapper> */}
                {/* <ErrorWapper>
                    {submitStatus && errorModelData[field.name] ? errorModelData[field.name] : <br />}
                </ErrorWapper> */}

                {props?.field?.showInfo ? <div style={{ fontSize: "12px", color: props.field.infoColor }}>
                    {props?.field?.showInfo}
                </div> : null}
                {submitStatus && errorModelData[props?.field.id] && !readOnlyFields.includes(props?.field?.id) ? <ul style={{ padding: 0 }} className="parsley-errors-list filled" id="parsley-id-29">
                    <li className="parsley-required" style={{
                        padding: "8px",
                        backgroundColor: "#EFD9E2",
                        color: "#E61B47"
                    }}>
                        {errorModelData[props?.field.id]}
                    </li>
                </ul> : null}
            </div>
        </FiledConfigWrapper>
    )
}

const FiledConfigWrapper = styled.div`
&& {
    .file-info {
        margin-left: 4px;
    }
    .field-lable{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
`;