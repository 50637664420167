import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { LoaderComponentProps, withLoader } from "../AppComponents/AppLoader/LoaderComponent";
import AppTable from "../AppComponents/AppTable";
import { AppConfig } from "../Config/app-config";
import { useAppDispatch } from "../Store";
import { FormAction } from "../Store/FormConfig/form.actions";
import {
    getCurrentStage,
    getErrorModel,
    getFieldJSONConfig,
    getHideFields,
    getModel,
    getPortalUserId,
    getSelectApplicationDetails,
    isPaymentSection
} from "../Store/FormConfig/form.selectors";
import {
    resetModelAndErrorModel,
    setCurrentStage,
    setFieldJSONConfig,
    setModel,
    setOnSumbit,
    setPortalUserId,
} from "../Store/FormConfig/form.slice";
import { FieldJSONConfig, findObjectByName, isEmptyObject, navBar, setReadOnlyToTrue, splitPhoneNumber } from "../utils/commonService";
import { Field } from "./FieldConfig";
import useInternetConnection from "../AppComponents/UseInternetConnection/useInternetConnection";
const { useHistory, useParams } = require("react-router-dom")

interface FormConfigProps extends LoaderComponentProps {

}

const ViewForm: React.FC<FormConfigProps> = ({
    setLoading
}) => {
    const dispatch = useAppDispatch();
    const navigate = useHistory();
    const fieldConfigs: any = useSelector(getFieldJSONConfig);
    let { step, id } = useParams();
    const errorModelData = useSelector(getErrorModel);
    const hideFields = useSelector(getHideFields)
    const currentStage: any = useSelector(getCurrentStage);
    const applicationDetails = useSelector(getSelectApplicationDetails);
    const isPaymentSectionData = useSelector(isPaymentSection)
    const portal_user_id = localStorage.getItem("portal_user_id");
    const [showProgressBar, setShowProgressBar] = useState<any>(false);
    // const createFormApplicationResponse = useSelector(getCreateApplicationFormResponse)
    const portalUserId = useSelector(getPortalUserId);
    const { isOnline } = useInternetConnection();
    let checkInternet = ""
    if(!isOnline){
      checkInternet = "You are offline. Please connect to the internet to continue."
    }else{
      checkInternet = ""
    }

    // const location = useLocation
    const [config, setConfig] = useState<any>([]);


    useEffect(() => {
        if (fieldConfigs.length !== 0) {
            setConfig(fieldConfigs)
            getConfig()
        }
    }, [fieldConfigs, currentStage]) // eslint-disable-line react-hooks/exhaustive-deps

    //method to disable all the fields
    const getConfig = async () => {
        if (currentStage >= 0 && fieldConfigs) {
            const data = await setReadOnlyToTrue(fieldConfigs)
            setConfig(data)
        }
    }

    useEffect(() => {
        AppConfig.setConfigData(FieldJSONConfig);
    }, [])
    const getApplicationResponse = (id: any) => {
        dispatch(FormAction.getApplicationResponseDetails(id))
            .then((res: any) => {
                if (res?.payload?.data?.Status) {
                    dispatch(setFieldJSONConfig(FieldJSONConfig));
                    setShowProgressBar(false)
                    let mobile_phone_number_id = findObjectByName(FieldJSONConfig, "mobile_phone_number")
                    let mobileNumber = "+"+ res.payload.data.data.fields[mobile_phone_number_id]
                    res.payload.data.data.fields[mobile_phone_number_id] = splitPhoneNumber(mobileNumber);
                    let payment_status = res?.payload?.data?.data?.payment_status
                    let applicant_id = res?.payload?.data?.data?.applicant_id
                    let application_fee = res?.payload?.data?.data?.application_fee
                    let payment_mode = res?.payload?.data?.data?.payment_mode
                    let payment_initiated = res?.payload?.data?.data?.payment_initiated
                    let  payment_details = res?.payload?.data?.data?.payment_details
                    let data = { ...res?.payload?.data?.data?.fields, payment_status: payment_status, application_fee: application_fee,applicant_id:applicant_id,payment_initiated:payment_initiated,payment_mode: payment_mode,payment_details:payment_details}
                    dispatch(setModel(data))
                    setTimeout(function () {
                        setLoading(false)
                    }, 600);
                }
            })
            .catch((error: any) => {
                console.error("error", error);
            })
    }
    const getPortalUserDetails = () => {
        dispatch(FormAction.getPortalUserDetails(portalUserId || portal_user_id))
            .then((res: any) => {
                if (res?.payload?.data?.Status) {
                    dispatch(setPortalUserId(res?.payload?.data?.data?.portal_user_id))
                    // setLoading(false)
                    setShowProgressBar(false)
                }
            })
            .catch((error: any) => {
                console.error("error", error);
            })
    }
    useEffect(() => {
        if (portalUserId || portal_user_id) {
            if (step >= 0 && id) {
                dispatch(resetModelAndErrorModel({}))
                dispatch(setCurrentStage(parseInt(step)))
                getApplicationResponse(id)
                getPortalUserDetails()
            }

        } else {
            getApplicationResponse(applicationDetails?.application_response_id)
        }
    }, [portalUserId]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setLoading(true)
        dispatch(setOnSumbit(false))
        dispatch(setModel(modelData));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const modelData = useSelector(getModel);

    useEffect(() => {
        if (currentStage || currentStage === 0) {
            dispatch(setOnSumbit(false))
            setConfig(fieldConfigs)
        }
    }, [currentStage]) // eslint-disable-line react-hooks/exhaustive-deps
    const goToNextStep = (value: boolean) => {
        getApplicationResponse(id)
        dispatch(setCurrentStage(currentStage + 1))
        setShowProgressBar(true)
    }
    const goToNextStepToNevBar = (value: any) => {
      if (value < Number(currentStage)) {
        dispatch(setOnSumbit(true));
        // if (isEmptyObject(errorModelData)) {
        dispatch(setCurrentStage(value));
        dispatch(resetModelAndErrorModel({}));
        setConfig([]);
        // }
        var myElement = document.getElementById("body-form");
        if (myElement) {
          myElement.scrollIntoView();
        }
      }
  
    }
    const goToPrevious = () => {
        setShowProgressBar(true)
        getApplicationResponse(id)
        setLoading(true)
        dispatch(setCurrentStage(currentStage - 1))
        let step = currentStage - 1
        navigate.push("/view/form/" + step + "/" + id, { replace: true });
        dispatch(resetModelAndErrorModel({}))
        setConfig([])
    }
    return (
        <BodyWrapper>
        <div className="col-md-12">
          <div className="card">
            <div className="body">
            <ul className="nav nav-tabs-new m-b-20 top-tab">
              {navBar.map((nav: any, index: number) => {
                return (
                  <li
                    className={`nav-item mr-1 ${index > currentStage && "disabled"
                      }`}
                    onClick={() => goToNextStepToNevBar(index)}
                  >
                    <a
                      className={`nav-link ${Number(currentStage) === index ? "active" : "alert-success"
                        } ${index > Number(currentStage) ? "disabled" : ""}`}
                      style={{
                        backgroundColor: index < Number(currentStage) ? "#d4edda" : ""
                      }}
                      onClick={() => goToNextStepToNevBar(index)}
                    >
                      {nav.label}
                    </a>
                  </li>
                );
              })}
            </ul>
            {showProgressBar ? (
                <div
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)", // Translucent black overlay
                    zIndex: 9999, // Ensure it's above other elements
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      padding: "16px",
                      textAlign: "center",
                      fontSize: "15px",
                      // backgroundColor: "antiquewhite",
                      color: "white",
                      borderRadius: "8px",
                    }}
                  >
                    Please wait, we are processing your request...
                  </div>
                </div>
              ) : null}
            {checkInternet ? (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.5)", // Translucent black overlay
                  zIndex: 9999, // Ensure it's above other elements
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    padding: "16px",
                    textAlign: "center",
                    fontSize: "15px",
                    // backgroundColor: "antiquewhite",
                    color: "white",
                    borderRadius: "8px",
                  }}
                >
                  You are offline. Please connect to the internet to continue.
                </div>
              </div>
            ) : null}
              <form
                className="ng-untouched ng-dirty ng-invalid body-form"
                id="body-form"
              >
                <React.Fragment>
                  {config?.[currentStage]?.child?.map((fields: any) => {
                    return (
                      <React.Fragment>
                        {
                          !hideFields.includes(fields.name) && (
                            <div
                              className="alert alert-secondary m-t-20"
                              role="alert"
                            >
                              {fields.label}
                            </div>
                          )}
                        {!fields?.is_hidden &&
                          !hideFields.includes(fields.name) &&
                          fields?.rows?.childs?.map((row: any) => {
                            return (
                              <div className="row">
                                {row?.fields?.map((field: any) => {
                                  let length =
                                    row?.fields?.filter(
                                      (field: any) => !field?.is_hidden,
                                    )?.length / 12;
                                  return (
                                    <React.Fragment>
                                      {(!hideFields.includes(field.id)
                                      ) ||
                                        (modelData.hasOwnProperty(field?.name) &&
                                          !field?.read_only) ? (
                                        field?.type !== "table" ? (
                                          <div
                                            className={
                                              length === 12
                                                ? "col-lg-12 col-md-12"
                                                : length === 6
                                                  ? "col-lg-6 col-md-6"
                                                  : length === 4
                                                    ? "col-lg-4 col-md-4"
                                                    : "col-lg-3 col-md-3"
                                            }
                                          >
                                            <Field
                                              field={field}
                                              sectionId={fields?.name}
                                              setLoading={setLoading}
                                            ></Field>
                                          </div>
                                        ) : (
                                          <AppTable
                                            headers={field?.header_value}
                                            listing={field?.config}
                                            isPaymentSection={
                                              field?.payment_section
                                            }
                                            isButton={false}
                                            buttonText="Add Column"
                                          />
                                        )
                                      ) : null}
                                    </React.Fragment>
                                  );
                                })}
                                <br />
                              </div>
                            );
                          })}
                      </React.Fragment>
                    );
                  })}
                  <br />
                </React.Fragment>
                <br />
              </form>
  
              <div className=" footer" style={{
               position: "fixed",
               bottom: "0",
               left: "0",
               width: "100%",
               backgroundColor: "white"
              }}>
                <div className="btn-wrapper">
                  {currentStage !== 0 && (
                    <button
                      className="btn btn-danger"
                      onClick={() => goToPrevious()}
                      disabled={currentStage === 0}
                    >
                      Previous
                    </button>
                  )}
  
                  {/* {isPaymentSectionData ? (
                    <button
                      className="btn btn-success"
                      id="ebz-checkout-btn"
                      onClick={setPayment}
                    >
                      Proceed to Pay
                    </button>
                  ) : ( */}
                    {!isPaymentSectionData ? <button
                      className="btn btn-success"
                      // disabled={!isEmptyObject(errorModelData) || currentStage === navBar.length - 1}
                      onClick={() => {
                        goToNextStep(navBar.length === currentStage + 1);
                      }}
                      disabled={isPaymentSectionData ? true:false}

                    >
                      {isPaymentSectionData
                        ? "paynow"
                        : navBar.length === currentStage + 1
                          ? "Sumbit"
                          : "Next"}
                      {navBar.length === currentStage + 1 ? (
                        ""
                      ) : (
                        <i
                          style={{ marginLeft: "10px" }}
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>
                      )}
                    </button> : null}
                  {/* )} */}
                </div>
                {/* <Toaster /> */}
              </div>
            </div>
          </div>
        </div>
      </BodyWrapper>
    );
};
export default withLoader(ViewForm);

const BodyWrapper = styled.div`
&& {
    // height: calc(100vh - 112px);
    margin-top: 27px;
    // overflow: auto;
    .card {
      margin: 0px;
    }
    .body {
      padding: 0px;
    }
    .top-tab {
      height: 60px;
      display: flex;
      // justify-content: center;
      align-items: center;
      padding: 0 16px;
      position: relative;
      z-index: 1;
      margin: 0;
      box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px;
    }
    .footer {
      height: 60px;
      padding: 0 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-shadow: rgba(0, 0, 0, 0.08) 0px -2px 4px;
      position: relative;
      z-index: 1;
    }
    .btn-wrapper {
      display: flex;
      gap: 8px;
      justify-content: flex-end;
    }
    .body-form {
      max-height: calc(100vh - 235px);
      overflow: auto;
      padding: 16px;
    }
}
`;