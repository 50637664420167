
import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../Store";
// import { fieldOnChange, onErrorHandle } from "../../Utils/commonService";;
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
	getErrorModel,
	getHideFields,
	getReadOnlyFields,
	onSumbitStatus,
} from "../../Store/FormConfig/form.selectors";
import {
	ITheme,
	checkRule,
	fieldOnChange,
	onErrorHandle,
	rule
} from "../../utils/commonService";
import COLORS from "../../utils/constant";

type InputSizeType = "large" | "small" | "default";
type IconPosition = "left" | "right";

export const AppPhone = (props: any) => {
	const { field, model, errorModel, type, fieldConfig, iconTitle, sectionId, showError, setLoading } = props
	const dispatch = useAppDispatch()
	const submitStatus = useSelector(onSumbitStatus);
	const errorModelData = useSelector(getErrorModel);
	const readOnlyField = useSelector(getReadOnlyFields)
	const hideFields = useSelector(getHideFields)
	const [mobileCountry, setMobileCountry] = useState<any>({});
	const [textInput, setTextInput] = useState("")
	const changeFn = (event: any) => {
		setTextInput(event)
		fieldOnChange(event, model, field, fieldConfig, sectionId, errorModelData, dispatch, setLoading, rule);
	}
	const onBlur = (e: any) => {
		const data = { ...field };
		data["country_info"] = mobileCountry;
		onErrorHandle(field, errorModel, model, dispatch, sectionId, hideFields)
	}
	useEffect(() => {
		if (model[field?.id] || model[field?.name]) {
			fieldOnChange(model[field?.id] || model[field?.name], model, field, fieldConfig, sectionId, errorModelData, dispatch, setLoading, rule);
		}
		checkRule(model, field, fieldConfig, sectionId, errorModelData, dispatch, setLoading, rule)
		setTextInput("")
		onErrorHandle(field, errorModel, model, dispatch, sectionId, hideFields)
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<React.Fragment>
			<PhoneWrapper>
				<PhoneStyled
					value={model[field?.id]}
					searchPlaceholder={"Enter Country Code"}
					onChange={(value: any) => changeFn(value)}
					onBlur={(value: any) => onBlur(value)}
					placeholder={field?.placeholder + (field?.is_required ? " *" : "")}
					enableLongNumbers={17}
					//   country={formSettings?.county_code ? formSettings?.county_code : "in"}
					inputStyle={{ width: "-webkit-fill-available", height: "40px" }}
					countryCodeEditable={true}
					jumpCursorToEnd={true}
					enableSearch={true}
					disabled={readOnlyField.includes(field.id) ? true : field?.read_only ? true :false}
					autocompleteSearch={true}
					isValid={(info: any, country: any) => {
						setMobileCountry(country);
						return true;
					}}
					country={"in"}
					inputProps={{
						name: "ads",
						required: false,
					}}
				/>
			</PhoneWrapper>
			{showError && submitStatus && errorModelData[props?.field.id] ? <ul className="parsley-errors-list filled" id="parsley-id-29">
				<li className="parsley-required">
					{errorModelData[props?.field.id]}
				</li>
			</ul> : null}

		</React.Fragment>
	);
}

const PhoneStyled = styled(PhoneInput)``;
export const StyledInput = styled(PhoneInput) <{
	error?: boolean;
	disabled?: boolean;
	inputSize?: InputSizeType;
	iconPosition?: IconPosition;
	theme: ITheme;
}>`
  box-sizing: border-box;
  border: 0;
  border: 1px solid ${({ theme }) => theme.GREY4};
  padding: 12px 48px 12px 16px;
  border-radius: 4px;
  color: ${({ theme }) => theme.GREY1};
  width: 100%;
  font-size: 14px;
  outline-color: ${({ theme }) => theme.PRIMARY_LIGHT};
`;
const PhoneWrapper = styled.div`
  && {
    display: flex;
    flex-direction: column;
    .otp_btn {
      min-width: 100px;
      cursor: pointer;
      margin-left: 5px;
      max-width:100%;
      &.btn-danger {
        background: ${COLORS.DANGER};
        border: 1px solid ${COLORS.DANGER};
        outline: ${COLORS.DANGER};
        box-shadow: none;
        border-radius: 5px;
        color: ${COLORS.WHITE};
        &:hover {
          background: ${COLORS.WHITE};
          color: ${COLORS.DANGER};
        }
      }
    }
    .otp_container{
        display: flex;
        align-items: center;
        gap: 15px;
    }
    .button{
      cursor: pointer;
      margin-bottom:-4px;
    }
    .info_text{
      color: green;
      font-size:14px;
    }
    .disabled {
      color: gray;
      cursor: not-allowed;  
      pointer-events: none; 
      opacity: 0.6;
    }
  }
`;
