import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AppSelect } from "../Select";
import { AppInput } from "../Input";
import { useSelector } from "react-redux";
import {
  getErrorModel,
  getFieldJSONConfig,
  getHideFields,
  getModel,
  onSumbitStatus,
} from "../../Store/FormConfig/form.selectors";
import { setPaymentSection } from "../../Store/FormConfig/form.slice";
import { useAppDispatch } from "../../Store";
import AppDatePicker from "../AppDatePicker";

interface AppTableProps {
  headers: any[];
  listing: any[];
  isButton?: boolean;
  buttonText?: string;
  isPaymentSection?: any;
}

const AppTable: React.FC<AppTableProps> = ({
  headers,
  listing,
  isButton = false,
  buttonText = "Continue",
  isPaymentSection,
}) => {
  var path: any = window.location.pathname;
  const fieldConfig = useSelector(getFieldJSONConfig);
  const errorModelData = useSelector(getErrorModel);
  const hideFields = useSelector(getHideFields);
  const modelData = useSelector(getModel);
  const dispatch = useAppDispatch();
  const submitStatus = useSelector(onSumbitStatus);
  const [errorModel, setErrorModel] = useState<any>({});
  const [hideField, setHideField] = useState<any>([]);
  const [showTimer, setShowTimer] = useState(true);
  const [countDown, setCountDown] = useState(60); // Initial countdown time in seconds

  useEffect(() => {
    let a: any = { ...errorModelData };
    setErrorModel(a);
    Object.preventExtensions(errorModelData);
  }, [errorModelData]);

  useEffect(() => {
    dispatch(setPaymentSection(isPaymentSection));
    return () => {
      dispatch(setPaymentSection(false));
    };
  }, []);
  useEffect(() => {
    if (modelData["payment_initiated"] && showTimer && !path?.includes("view") && modelData['payment_status'] !== "completed") {
      setCountDown(60); // Reset countdown timer to 60 seconds
      const intervalId = setInterval(() => {
        setCountDown((prevCount) => {
          if (prevCount === 0) {
            clearInterval(intervalId); // Clear interval when countdown reaches 0
            setShowTimer(false); // Hide timer after countdown
            return 60; // Reset countdown timer to 60 seconds
          }
          return prevCount - 1; // Decrease countdown by 1 second
        });
      }, 1000); // Update countdown every second
    } else {
      setShowTimer(false);
    }
  }, [modelData["payment_initiated"]])
  useEffect(() => {
    if (hideFields) {
      setHideField(hideFields);
    }
  }, [hideFields]);

  const onClick = () => {
    window.location.reload()
  };
  return (
    <div className="col-lg-12">
      <div className="card">
        <TableWrapper>
          <div className="body table-responsive">
            <table className="table table-bordered" style={{textTransform: "capitalize"}}>
              <thead>
                <tr>
                  {headers?.map((header: any, index: number) => (
                    <th key={index}>
                      <div
                        data-toggle="tooltip"
                        data-placement="top"
                        title={header.label}
                        aria-hidden="true"
                      >
                        {header.label}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {listing.map((student: any, rowIndex: any) =>
                  !student?.is_hidden || !hideFields.includes(student.name) ? (
                    <tr key={rowIndex}>
                      {headers?.map((header: any, colIndex: number) => (
                        <td key={colIndex}>
                          {student.fields.map((col: any) =>
                            col.header_value === header.value &&
                              ["number", "string", "datetime"].includes(col.type) &&
                              !col?.is_hidden &&
                              !hideFields.includes(col.name) ? (
                              ["datetime",].includes(col.type) ?
                                <AppDatePicker
                                  key={col.label}
                                  field={col}
                                  model={modelData}
                                  errorModel={errorModel}
                                  fieldConfig={fieldConfig}
                                  showError={false} sectionId={undefined} /> :
                                <AppInput
                                  key={col.label}
                                  field={col}
                                  model={modelData}
                                  errorModel={errorModel}
                                  fieldConfig={fieldConfig}
                                  showError={true}
                                />
                            ) : col.header_value === header.value &&
                              col.type === "info" ? (
                              <a style={{ whiteSpace: 'normal', wordWrap: 'break-word', maxWidth: '100%' }} >
                                {
                                  col?.default_value ?
                                    col?.default_value :
                                    col?.name === "payment_status" && typeof modelData['payment_status'] !== 'object' ?
                                      modelData["payment_initiated"] && !path?.includes("view") ?
                                        <span>
                                          {modelData["payment_status"] || "pending"}
                                          {modelData['payment_status'] !== "completed" ? <span style={{ color: "red" }}>
                                            {countDown && showTimer ? "(Your payment request is in progress, it usually takes up to 15 mins. Please wait...)" : "(Please click here to check your payment status)"} {countDown && showTimer && modelData['payment_status'] !== "completed" ?
                                              <a style={{ color: "blue" }}>{countDown + " Seconds"}</a> :
                                              <a style={{ color: "blue", cursor: "pointer" }} onClick={() => onClick()}>{"click here"}</a>
                                            }
                                          </span>:null}
                                        </span> :
                                        <span>{modelData["payment_status"] || "pending"}</span> :
                                      col?.name === "application_fee" && modelData['payment_mode'] === "offline" ?
                                        modelData['payment_details']?.amount + " (" + modelData['payment_details']?.offline_payment_type + ") " :
                                        modelData[col?.name]
                                }
                              </a>
                            ) : null
                          )}
                        </td>
                      ))}
                    </tr>
                  ) : null,
                )}
              </tbody>
            </table>

            {isButton ? (
              <div className="btn-footer">
                <button className="btn btn-primary" onClick={() => { }}>
                  {buttonText}
                </button>
              </div>
            ) : null}
          </div>
        </TableWrapper>
      </div>
    </div>
  );
};

export default AppTable;

const TableWrapper = styled.div`
  && {
    .table th div {
      min-width: 100px; /* Set minimum width for table data cells */
      max-width: 240px; /* Set maximum width for table data cells */
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 15px;
    }

    .table td {
      min-width: 100px; /* Set minimum width for table data cells */
      max-width: 120px; /* Set maximum width for table data cells */
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .btn-footer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
`;
