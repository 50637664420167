import CryptoJS from 'crypto-js';

const PROD_AES_KEY = process.env.REACT_APP_SECRET_KEY;
// const PROD_AES_KEY = '&;!J<ThjEGYV=t98W7<@]kxj*<9kr4t*';

export class EncryptDecrypt {
  env: string;
  AES_KEY: CryptoJS.lib.WordArray;
  IV: CryptoJS.lib.WordArray;

  constructor(secret_key: any) {
    this.env = 'prod';
    this.AES_KEY = CryptoJS.enc.Utf8.parse(secret_key);
    this.IV = CryptoJS.lib.WordArray.random(128 / 8);
  }

  encrypt_node(payload: string) {
    let cipher_text = CryptoJS.AES.encrypt(payload, this.AES_KEY, {
      iv: this.IV,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.format.Hex);
    return cipher_text
  }
  decrypt_node(payload: string, iv: any) {
    const parsed_iv = CryptoJS.enc.Utf8.parse(iv)
    const decoded_payload = CryptoJS.enc.Hex.parse(payload)
    // CryptoJS.lib.CipherParams
    const decryption_params = CryptoJS.lib.CipherParams.create({
      ciphertext: decoded_payload,
      key: this.AES_KEY,
      iv: parsed_iv,
      algorithm: CryptoJS.algo.AES
    });
    let decrypted = CryptoJS.AES.decrypt(decryption_params, this.AES_KEY, {
      iv: parsed_iv,
      mode: CryptoJS.mode.CBC,
    });
    // CryptoJS.pad.Pkcs7.unpad(decrypted)
    const plain_text = decrypted.toString(CryptoJS.enc.Utf8)
    return plain_text
  }
}


export class EncryptionService {
  env?: string;
  static secret_key = PROD_AES_KEY;


  static encrypt(data: any) {
    const decrypt = new EncryptDecrypt(EncryptionService.secret_key);
    data = JSON.stringify(data)
    data = decrypt.encrypt_node(data)
    return data
  }
  static decrypt(payload: any) {
    const [iv, data] = payload.split(":");
    const decrypt = new EncryptDecrypt(EncryptionService.secret_key);
    const decrypted_data = decrypt.decrypt_node(data, iv)
    return decrypted_data
  }
  static json_encrypt(data: any) {
    const decrypt = new EncryptDecrypt(EncryptionService.secret_key);
    data = JSON.stringify(data)
    data = decrypt.encrypt_node(data)
    const words = CryptoJS.enc.Utf8.parse(data); // WordArray object
    var base64Data = CryptoJS.enc.Base64.stringify(words);
    return base64Data
  }
  static decrypt_json(payload: any) {
    const words = CryptoJS.enc.Base64.parse(payload);
    const encryptedData = CryptoJS.enc.Utf8.stringify(words);
    const data = this.decrypt(encryptedData);
    return data
  }



  // decrypt(server_header: any):
  //   iv,data = server_header.split(':')
  //   decrypt = encrypt_decrypt(secret_key,iv)
  //   data = decrypt.decrypt_node(data)
  //   #data = server_header # Comment
  //   data = json.loads(data)
  //   return data


}

