import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { useAppDispatch } from "../../Store";
import { FormAction } from "../../Store/FormConfig/form.actions";
import { showSnackBar } from "../../Store/FormConfig/form.slice";

const ForgotPassword = ({ history }) => {
  const [email, setEmail] = React.useState("");
  const dispatch = useAppDispatch();

  const handleForgotPassword = () => {
    const data = { email: email,domain: window.location.hostname, };
    dispatch(FormAction.forgotPassword(data)).then((res) => {
      if (res?.payload?.status === 200) {
        dispatch(
          showSnackBar({
            open: true,
            message: "Please check your email for password reset link",
            severity: "success",
          })
        );
        history.push("login");
      } else {
        dispatch(
          showSnackBar({
            open: true,
            message: res?.payload?.data,
            severity: "error",
          })
        );
      }
    });
  };

  return (
    <div className="theme-cyan">
      <div>
        <div className="vertical-align-wrap">
          <div className="vertical-align-middle auth-main">
            <div className="auth-box">
              <div className="card">
                <div className="header">
                  <p className="lead">Recover my password</p>
                </div>
                <div className="body">
                  <p>
                    Please enter your email address below to receive
                    instructions for resetting password.
                  </p>
                  <form className="form-auth-small ng-untouched ng-pristine ng-valid">
                    <div className="form-group">
                      <input
                        className="form-control"
                        placeholder="Email"
                        type="email"
                        value={email}
                        onChange={(e) => {
                          const lowercaseValue = e.target.value.toLowerCase();
                          setEmail(lowercaseValue);
                        }}
                      />
                    </div>
                    <button
                      className="btn btn-primary btn-lg btn-block"
                      type="button"
                      onClick={() => {
                        handleForgotPassword();
                        // history.push("login");
                      }}
                    >
                      RESET PASSWORD
                    </button>
                    <div className="bottom">
                      <span className="helper-text">
                        Know your password? <a href="login">Login</a>
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
