import React, { useEffect } from "react";
import { useAppDispatch } from "../Store";
import { setContactId, setPortalUserId } from "../Store/FormConfig/form.slice";
import { getLoginDataFromToken } from "../utils/commonService";
const { useHistory } = require("react-router-dom")

const Home = (): JSX.Element => {
  let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
  consoleBaseUrl = consoleBaseUrl + "api/v1";
  const navigate = useHistory();
  const dispatch = useAppDispatch();
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const getaHost = searchParams.get('geta_host');
    const portal_user_id = searchParams.get('portal_user_id');
    const isView = searchParams.get('view');
    const application_response_id = searchParams.get('application_response_id');

    if (getaHost && portal_user_id) {
      fetch(`${consoleBaseUrl}/workspace/application-token`, {
        method: 'GET',
        headers: {
          'geta-host': getaHost,
          'Portal-User-Id': portal_user_id
        }
      })
        .then(response => {
          return response.json(); // Assuming response is JSON data
        })
        .then(data => {
          if (data?.data?.status) {
            let headers = {
              token: data?.data?.token_serialized?.token
            }
            localStorage.setItem("portal_user_id", data?.data?.portal_user_id);
            dispatch(setPortalUserId(data?.data?.portal_user_id))
            dispatch(setContactId(data?.data?.contact_id))
            const decodedToken = getLoginDataFromToken(headers);
            if (isView) {
              navigate.push(`/view/form/0/${application_response_id}`, { replace: true });
              return;
            }

            navigate.push("/", { replace: true });

          } else {
            navigate.push("/login", { replace: true });

          }
        })
        .catch(error => {
          navigate.push("/login", { replace: true });
          console.error('Error:', error);
        });
    }
    // Now you can use getaHost and token in your component
  }, [window.location.search]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      {/* <FormsListing/> */}
    </>
  )
}
export default Home
