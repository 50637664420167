import { getMonth, getYear } from "date-fns";
// import { range } from "lodash";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getErrorModel,
  getHideFields,
  getReadOnlyFields,
  onSumbitStatus,
} from "../../Store/FormConfig/form.selectors";
import {
  checkRule,
  convertDateToDatePickerFormant,
  convertDateToString,
  convertStringToFormattedDate,
  defaultValue,
  fieldOnChange,
  onErrorHandle,
  rule
} from "../../utils/commonService";
import styled from "styled-components";
import moment from "moment";
const range = require('lodash/range');

interface Props {
  themeColor?: any;
  secondaryColor?: any;
  field: any;
  model: any;
  errorModel: any;
  fieldConfig: any;
  sectionId: any;
  showError: any;
  setLoading?:any
}

const AppDatePicker: React.FC<Props> = (props) => {
  const {
    field,
    model,
    errorModel,
    fieldConfig,
    sectionId,
    showError,
    secondaryColor = "",
    themeColor ="",
    setLoading
  } = props;
  let years = range(1950, getYear(new Date()) + 1, 1);
  const submitStatus = useSelector(onSumbitStatus);
  const errorModelData = useSelector(getErrorModel);
  const hideFields = useSelector(getHideFields);
  const readOnlyField = useSelector(getReadOnlyFields);
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  if (field.allowedFutureYears) {
    years = range(new Date().getFullYear(), getYear(new Date()) + field.allowedFutureYears, 1);
  }
  const changeFn = (value: any) => {
    setSelectedDate(value);
    
    const date = convertStringToFormattedDate(value);

    fieldOnChange(
      date.toString(),
      model,
      field,
      fieldConfig,
      sectionId,
      errorModelData,
      dispatch,
      setLoading,
      rule
    );
  };

  const onBlur = () => {
    onErrorHandle(field, errorModel, model, dispatch, sectionId, hideFields);
  };

  useEffect(() => {
    if(model[field?.id] || model[field?.name]){
      fieldOnChange(model[field?.id] || model[field?.name], model, field, fieldConfig, sectionId, errorModelData, dispatch,setLoading,rule);
  }
    checkRule( model, field, fieldConfig, sectionId, errorModelData, dispatch,setLoading,rule)
    onErrorHandle(field, errorModel, model, dispatch, sectionId, hideFields);
  }, []);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // useEffect(() => {
  //   let date =
  //     model[field?.id] || model[field?.name] || defaultValue[field?.id];
  //   date = date ? convertDateToString(date) : null;

  //   if (date) {
  //     setSelectedDate(new Date(date));
  //   }
  // }, [defaultValue[field?.id], model[field?.id], model[field?.name]]);
  return (
    <DatePickerWrapper secondaryColor={secondaryColor} themeColor={themeColor}>
      <DatePicker
        className={`form-control ${
          submitStatus && errorModelData[field.id] && !readOnlyField.includes(props?.field?.id) && "parsley-error scrollTOError"
        }`}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 10,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button
              onClick={(e) => {
                e.preventDefault();
                decreaseMonth();
              }}
              disabled={prevMonthButtonDisabled}
            >
              {"<"}
            </button>
            <select
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(Number(value))}
            >
              {years.map((option :any) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button
              onClick={(e) => {
                e.preventDefault();
                increaseMonth();
              }}
              disabled={nextMonthButtonDisabled}
            >
              {">"}
            </button>
          </div>
        )}
        selected={ model[field?.id] ? new Date(convertDateToDatePickerFormant(model[field?.id])) || null : null}
        dateFormat={"dd-MM-YYYY"}
        onChange={(date) => changeFn(date as Date)}
        onBlur={() => onBlur()}
        maxDate={field.hasOwnProperty("allowFutureDate") && field?.allowFutureDate ? new Date() : null}
        minDate={field.hasOwnProperty("allowPastDate") && !field?.allowPastDate ? new Date() : null}
        placeholderText="Choose a Date"
        disabled={readOnlyField.includes(field.id) ? true : field?.read_only ? true :false}
      />
      {showError && submitStatus && errorModelData[props?.field.id] ? (
        <ul className="parsley-errors-list filled" id="parsley-id-29">
          <li className="parsley-required">
            {errorModelData[props?.field.id]}
          </li>
        </ul>
      ) : null}
    </DatePickerWrapper>
  );
};

export default AppDatePicker;

const DatePickerWrapper = styled.div<any>`
&& {

  .react-datepicker__input-container {
    input:focus {
      border: 1px solid #2d2d2d;
    }
  }


  .react-datepicker__day--keyboard-selected {
    background-color: ${({ themeColor } :any) => themeColor || '#bad9f1'};  
  }

  .react-datepicker__day--selected {
    background-color: ${({ secondaryColor } :any) => secondaryColor || "#216ba5" };
  }

  .react-datepicker__day--selected:hover {
    background-color: ${({ secondaryColor } :any) => secondaryColor || "#216ba5" };
  }

   .react-datepicker__triangle {
    // fill:   ${({ themeColor } :any) => themeColor || ''};
    // color: ${({ themeColor } :any) => themeColor || ''};
    fill: red;
    color:;
   }

  .react-datepicker {
    border: none;
    // box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  }

  .react-datepicker__header {
    // background-color:   ${({ themeColor } :any) => themeColor || ''};
        border-bottom: 1px solid #d5d5d5;
    background-color: #fff;

    div {
      gap: 4px;
      align-items: center;
    }

    div > select {
      border-radius: 4px;
      background-color: #fff;
      border: 1px solid #d5d5d5;
      height: 28px;
      // min-height: 22px;
      // border: 1px solid ${({ secondaryColor } :any) => secondaryColor || "" };
    }

    div > button {
      border-radius: 4px;
      background-color: #fff;
      // border: 1px solid ${({ secondaryColor } :any) => secondaryColor || "" };
      border: none;
    }

    div > button:last-child {
      position: absolute;
      right: 10px;
    }

    div > button:first-child {
      position: absolute;
      left: 10px;
    }
  }
}
`;
