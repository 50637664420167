import http from "../../NetworkServices/http";

let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";
export class FormNetworkService {
  static getAllCountry(data?: any) {
    return http.get(
      `${consoleBaseUrl}${Endpoints.GET_ALL_OPTIONS}${data?.moduleId}/field/${data?.fieldId}/options`,
    );
  }
  static getAllState(data?: any) {
    return http.get(
      `${consoleBaseUrl}${Endpoints.GET_ALL_OPTIONS}${data?.moduleId}/field/${data?.fieldId}/options?is_dependant_field=true&selection=${data?.selectionId}`,
    );
  }
  static loginApplication(data?: any) {
    return http.post(`${consoleBaseUrl}${Endpoints.APPLICATION_LOGIN}`, data);
  }
  static verifyLogin(payload: any) {
    return http.get(`${consoleBaseUrl}/${Endpoints.TOKEN_VERIFY}`);
  }
  static getPortalUserDetails(portal_user_id: string) {
    return http.get(
      `${consoleBaseUrl}${Endpoints.GET_PORTAL_USER_DETAILS}${portal_user_id}`,
    );
  }

  static createFormApplication(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.CREATE_FORM_APPLICATION}`,
      data,
    );
  }

  static updateValue(data: any) {
    return http.put(
      `${consoleBaseUrl}/workspace/application-response/update/${data?.application_response_id}`,
      data,
    );
  }

  //application form list
  static getApplicationFormsList(data: any) {
    return http.get(
      `${consoleBaseUrl}${Endpoints.GET_APPLICATION_LIST}?portal_id=${data?.portal_id}`,
    );
  }

  //application form response list
  static getApplicationFormsResponseList(data: any) {
    return http.get(
      `${consoleBaseUrl}${Endpoints.GET_APPLICATION_RESPONSE_LIST}?portal_id=${data?.portal_id}&portal_user_id=${data?.portal_user_id}`,
    );
  }
  //application form response by id
  static getApplicationResponseDetails(application_response_id: any) {
    return http.get(
      `${consoleBaseUrl}/workspace/get-application-response-details/${application_response_id}`,
    );
  }
  //application form response by id
  static getApplicationResponseById(application_form_id: any) {
    return http.get(
      `${consoleBaseUrl}${Endpoints.GET_APPLICATION_RESPONSE_BY_ID}/${application_form_id}`,
    );
  }
  //Upload Document
  static uploadDocument(data: any) {
    return http.post(`${consoleBaseUrl}${Endpoints.UPLOAD_DOCUMENT}`, data);
  }

  static initiatePayment(data: any) {
    return http.post(`${consoleBaseUrl}/workspace/${data?.paymentGetway}${Endpoints.INITIATE_PAYMENT}`, data);
  }
  static markPaymentOnline(data: any, id: string) {
    return http.put(
      `${consoleBaseUrl}${Endpoints.MARK_PAYMENT_ONLINE}${id}`,
      data,
    );
  }
  //forgot password
  static forgotPassword(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.FORGOT_PASSWORD}`,
      data
    );
  }
  //Mark offline
  static logout(portal_user_id: any) {
    return http.get(
      `${consoleBaseUrl}${Endpoints.MARK_LOGOUT}/${portal_user_id}`,
    );
  }

  // Print PDF
  static printPDFNetwork(id: any) {
    return http.get(`${consoleBaseUrl}${Endpoints.PRINT_PDF}/${id}`);
  }

  // Admit Card
  static admitCardNetwork(id: any) {
    return http.get(`${consoleBaseUrl}${Endpoints.ADMIT_CARD}/${id}`);
  }
  // Report Card
  static reportCardNetwork(id: any) {
    return http.get(`${consoleBaseUrl}${Endpoints.REPORT_CARD}/${id}`);
  }
  // Personal Interview Schedule
  static piScheduleCardNetwork(id: any) {
    return http.get(`${consoleBaseUrl}${Endpoints.GET_PERSONAL_INTERVIEW_SCHEDULE_CARD}/${id}`);
  }
}
class Endpoints {
  static GET_ALL_COUNTRY = "/workspace/contact-properties/field/";
  static GET_ALL_STATE = "/workspace/contact-properties/field/";
  static GET_ALL_OPTIONS = "/workspace/module-properties/";
  // static APPLICATION_LOGIN = "/auth/application-login"
  // static TOKEN_VERIFY = "auth/application-token-verify"
  static APPLICATION_LOGIN = "/workspace/application-login";
  static TOKEN_VERIFY = "workspace/application-token-verify";
  static GET_PORTAL_USER_DETAILS = "/workspace/get-portal-user/";
  static CREATE_FORM_APPLICATION = "/workspace/application-response/create";
  // static GET_APPLICATION_RESPONSE_LIST = "/workspace/get-application-response-list"
  static GET_APPLICATION_RESPONSE_LIST = "/workspace/get-application-joint-list"
  static GET_APPLICATION_RESPONSE_BY_ID = "/workspace/get-application-response-form"
  static GET_APPLICATION_LIST = "/workspace/get-application-form-list"
  static UPLOAD_DOCUMENT = "/workspace/whatsapp-resolve-media-attach"
  static UPDATE_FORM_VALUE: "/workspace/application-response/update/"
  static GET_APPLICATION_RESPONSE_DETAILS: "/workspace/get-application-response-details/"
  static FORGOT_PASSWORD = "/workspace/application-forgot-password"
  static INITIATE_PAYMENT = "/application-payment-initiate";
  static MARK_PAYMENT_ONLINE =
    "/workspace/application-response/mark-payment-online/";
  static MARK_LOGOUT = "/workspace/application-logout"
  // static GET_APPLICATION_JOINT_LIST: "/workspace/get-application-joint-list"
  static PRINT_PDF = "/workspace/application-response/print-pdf"
  static ADMIT_CARD = "/workspace/application-response/print-admit-card"
  static REPORT_CARD = "/workspace/application-response/print-report-card"
  static GET_PERSONAL_INTERVIEW_SCHEDULE_CARD  = "/workspace/application-response/print-pi-card"
}
