import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import NavbarMenu from "./components/NavbarMenu";
import forgotpassword from "./screens/Auth/ForgotPassword";
import lockscreen from "./screens/Auth/Lockscreen";
import page403 from "./screens/Auth/Page403";
import page404 from "./screens/Auth/Page404";
import page500 from "./screens/Auth/Page500";
import page503 from "./screens/Auth/Page503";
import registration from "./screens/Auth/Registration";
import Login from "./screens/Login.tsx";
import maintanance from "./screens/Pages/Maintanance";
// import formvalidation from "./screens/Forms/FormValidation";
import Dashboard from "./form_screens/FormConfig.tsx";
import FormsListing from "./form_screens/FormsListing.tsx";
import Home from "./form_screens/Home.tsx";

import { useSelector } from "react-redux";
import { getPortalUserDetails } from "./Store/FormConfig/form.selectors.ts";
import { useLocation } from "react-router-dom";
import ThankYou from "./components/ThankYou/ThankYou";
import ViewForm from "./form_screens/ViewForm";
import ResetPassword from "./screens/Auth/ResetPassword.tsx";
import SignUp from "./screens/SignUp.tsx";
import AdmitCard from "./components/AdmitCard/AdmitCard";
import ReportCard from "./components/ReportCard/ReportCard";
import PISchedule from "./components/PISchedule/PISchedule";

const { useHistory } = require("react-router-dom");
window.__DEV__ = true;
const App = () => {
  var res = window.location.pathname;
  const location = useLocation();
  const navigate = useHistory();
  var baseUrl = process.env.PUBLIC_URL;
  baseUrl = baseUrl.split("/");
  res = res.split("/");
  res = res.length > 0 ? res[baseUrl.length] : "/";
  res = res ? res : "/";
  const activeKey1 = res;
  const data = useSelector(getPortalUserDetails);
  const loggedInUserData = localStorage.getItem("loggedInUserData");
  //...
  function callback() {
    if (window?.Sentry) {
      window.Sentry.init({
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      });
    }
  }
  useEffect(() => {
    const tag = document.createElement("script");
    tag.async = true;
    tag.src =
      "https://js.sentry-cdn.com/71f46cb6f20a79736dc9dce29912c175.min.js";
    tag.crossorigin = "anonymous";
    tag.onload = callback;
    document.body.appendChild(tag);
  }, []);

  useEffect(() => {
    if (
      !loggedInUserData &&
      ![
        "login",
        "registration",
        "lockscreen",
        "forgotpassword",
        "resetpassword",
        "page404",
        "page403",
        "page500",
        "page503",
        "maintanance",
        "signUp",
      ].includes(activeKey1)
    ) {
      navigate.push("/login", { replace: true });
    }
  }, [loggedInUserData]);
  return (
    <div id="wrapper">
      {[
        "login",
        "registration",
        "lockscreen",
        "forgotpassword",
        "resetpassword",
        "page404",
        "page403",
        "page500",
        "page503",
        "maintanance",
        "signUp",
      ].includes(activeKey1) ? (
        <Switch>
          {/* <Route exact path={`${process.env.PUBLIC_URL}`} component={Login} /> */}
          <Route exact path={`${process.env.PUBLIC_URL}/`} component={Login} />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/login`}
            component={Login}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/forgotpassword`}
            component={forgotpassword}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/signUp`}
            component={Login}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/resetpassword`}
            component={ResetPassword}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/page404`}
            component={page404}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/page403`}
            component={page403}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/page500`}
            component={page500}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/page503`}
            component={page503}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/registration`}
            component={registration}
          />
          <Route exact path={`registration`} component={registration} />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/lockscreen`}
            component={lockscreen}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/maintanance`}
            component={maintanance}
          />
        </Switch>
      ) : (
        <div id="main-content">
          <NavbarMenu history={location} activeKey={activeKey1} data={data} />
          <Switch>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/`}
              component={FormsListing}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/verify`}
              component={Home}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/form/:step?/:id?`}
              component={Dashboard}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/view/form/:step?/:id`}
              component={ViewForm}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/thankyou`}
              component={ThankYou}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/admit-card`}
              component={AdmitCard}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/report-card`}
              component={ReportCard}
            />
             <Route
              exact
              path={`${process.env.PUBLIC_URL}/pi-card`}
              component={PISchedule}
            />
          </Switch>
        </div>
      )}
    </div>
  );
};

export default App;
