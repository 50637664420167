import React from 'react'
import { useAppDispatch } from '../../Store';
import { FormAction } from '../../Store/FormConfig/form.actions';
import styled from 'styled-components';
import { LoaderComponentProps, withLoader } from '../../AppComponents/AppLoader/LoaderComponent';
const { useLocation } = require("react-router-dom");


interface AdmitCardProps extends LoaderComponentProps { }
  const AdmitCard: React.FC<AdmitCardProps> = ({ setLoading}) => {
  const dispatch = useAppDispatch();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const paramID = searchParams.get("id");

    const [admitCardUrl, setAdmitCardUrl] = React.useState("")
    

    React.useEffect(() => {
      if (paramID) {
        setLoading(true)
        dispatch(FormAction.admitCardAction(paramID))
          .then(res => {
            const admitCardUrl = res?.payload?.data?.data?.admit_card_url;
            if (admitCardUrl) {
              
              setLoading(false)
              setAdmitCardUrl(admitCardUrl);
            } else {
              setLoading(false)
              
              console.warn('admit_card_url is empty or null:', res);
            }
          })
          .catch((error: any) => {
            setLoading(false)
            
            console.error(error);
          });
      } else {
        
        setLoading(false)
        console.warn('paramID is invalid:', paramID);
      }
    }, [paramID]);

    const handleDownload = () => {
      if (admitCardUrl) {
        window.open(admitCardUrl, '_blank');
      }
    };

  return (
    <PdfContainer>
      <div className="col-lg-12">
        <div className="card">


        <div className='btn-container'>
        <h6>
            Admit Card
          </h6>
        <button className='btn btn-danger' style={{  }} onClick={handleDownload}>
          
            <i className="fa fa-file-pdf-o" aria-hidden="true" style={{marginRight: "8px"}}></i>
            Download PDF
        </button>
      </div>

{
 admitCardUrl ? <iframe
  title="PDF Viewer"
  src={`https://docs.google.com/viewer?url=${encodeURIComponent(admitCardUrl)}&embedded=true`}
  style={{ width: '100%', height: 'calc(100% - 40px)', border: "0px" }}
>
  <p>Your browser does not support iframes.</p>
</iframe> : <div style={{textAlign: "center"}}>
  <h3>No Admit Card To Display</h3>
</div>
}

      


        </div>
      </div>
      
    </PdfContainer>
  )
}

export default withLoader(AdmitCard);


const PdfContainer = styled.div`
    .btn-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px;
      h6 {
      margin: 0;
      }
    }
      .card {
          height: calc(100vh - 102px);
          padding: 0px 16px;
          overflow: hidden;
          padding-bottom: 16px;
          margin: 0px;
      }
    height: calc(100vh - 86px);
    overflow: hidden;
    margin: 0px;
    margin-top: 21px;
    .pdf-container {
      height: 100%;
    }
`;
