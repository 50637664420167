import React from "react";
import HocBackdropLoader, { HocBackdropLoaderProps } from "../../AppComponents/HocBackdropLoader/HocBackdropLoader";
const {  useLocation } = require("react-router-dom");

// const { useHistory, useSearchParams } = require("react-router-dom");

interface ThankYouProps extends HocBackdropLoaderProps {
  // Define any props your ThankYou component needs here
  setLoading: any,
  isLoading: boolean,
  setLoadingText: any
}
const ThankYouComponent: React.FC<ThankYouProps> = ({
  setLoading,
  isLoading,
  setLoadingText
}) => {
  const location = useLocation();
  const { scri_id, name } = location.state.state || {}; // Default to empty object if state is undefined
  return (
    <div className="">
      <div className="vertical-align-wrap ">
        <div className="vertical-align-middle bg-white" style={{ height: "calc(100vh - 84px)" }}>
          <div className="text-center">
            <div className="thankyou-page-content">
              <p>
                Hello&nbsp;<b>{name}</b>,  <br/> <br/>

                SCRI ID:-&nbsp;<b>{scri_id}</b> <br/> <br/>

                Thank you for submitting your application for the Ph. D Programme of <b>
                  Symbiosis International (Deemed University).
                  </b> 
                 <br/> <br/>

                You will be informed about the eligibility of your application in subsequent communications.
                SIU may seek clarifications/ re-submission of a few documents if required. <br/> <br/>

                You are requested to follow the date schedule available on <a href={"https://scri.siu.edu.in/"}>https://scri.siu.edu.in/</a> and follow
                the same. The university will not be responsible if you miss out any date applicable to the
                admission process. <br/> <br/>

                With best wishes ! <br/> <br/>

                Team, Ph D Admissions <br/>
                Symbiosis International (Deemed University)
              </p>
            </div>
            <article>
              {/* <h1 className="text-dark">Thank You!</h1> */}
            </article>
            <div className="margin-top-30">
              <a
                className="btn btn-info"
                href="/"
              >
                <i className="fa fa-arrow-left text-white"></i>&nbsp;<span className="text-white">Back To Home Page</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
// Wrap ThankYouComponent with HocBackdropLoader
const ThankYou = HocBackdropLoader(ThankYouComponent);
export default ThankYou;
