import {LocalStorage} from '../utils/LocalStorage';

declare var environments: any;
declare var process: any;

export const ADMIN_KEY = 'loggedInPortalAdminData';
export const CONFIG_DATA = 'configData';
export const USER_KEY = 'loggedInUserData';
export const USER_BUSINESSES_KEY = 'availableBusinesses';
const CONFIG_PARAMS : any = {
    // 'API_BASE_URL': environment.API_BASE_PATH,
};

const localStorage = new LocalStorage();

export class AppConfig {
    private static config = CONFIG_PARAMS;
    // public getConfig(param_name:string):any{
    //     if(this.config.hasOwnProperty(param_name))
    //     {
    //         return this.config[param_name]
    //     }
    //     return false;
    // }
    public static getConfig(param_name: string): any {
        if (this.config.hasOwnProperty(param_name)) {
            return this.config[param_name];
        }
        return false;
    }

    public static getCSRFToken(): string {
        return this.getCookie('csrftoken');
    }
    private static getCookie(name: string) {
        const value = '; ' + document.cookie;
        const parts:any = value.split('; ' + name + '=');
        if (parts.length === 2) {
            return parts.pop().split(';').shift();
        }
        return null;
    }

    public static setUserData(key: string, value: any) {
        const stringValue = JSON.stringify(value);
        localStorage.set(key, stringValue);
    }
    
    public static getUserData(key: string) {       
        const stringValue = localStorage.get(key);
        if (stringValue && typeof(stringValue) == 'string') {
            return JSON.parse(stringValue);
        }
        return stringValue;
    }


    public static setLoggedInAdminData(data: any) {
        this.setUserData(ADMIN_KEY, data);
    }
    public static setConfigData(data: any) {
        this.setUserData(CONFIG_DATA, data);
    }
    public static getConfigData(): any {        
        return this.getUserData(CONFIG_DATA);
    }
    // ----------***********************************--------------
    public static getLoggedInData(): any {        
        return this.getUserData(USER_KEY);
    }

    public static setLoggedInData(data: any) {
        this.setUserData(USER_KEY, data);
    }
    // -------------***********************************************------
    
    
    
    public static getLoggedInAdminData(): any {
        return this.getUserData(ADMIN_KEY);
    }

    public static getUserEmail() {
        const user = this.getLoggedInData();
        const admin = this.getLoggedInAdminData();
        if (user) {
            return user.email;
        }
        else if(admin){
            return user.email;
        }
        return null;
    }


    public static getCurrentToken() {
        const user_str = localStorage.get('loggedInUser') || false;
        if (user_str) {
            const user_details = JSON.parse(user_str);
            return user_details.token;
        }
        return null;
    }
}