import { Modal } from "react-bootstrap";
import { AppConfig, USER_BUSINESSES_KEY } from "../Config/app-config";
import {
  setErrorModel,
  setFieldJSONConfig,
  setHideFields,
  setHideOptionIds,
  setModel,
  setOptionsData,
  setReadOnlyFields,
  setRemoveErrorModel,
  showSnackBar,
} from "../Store/FormConfig/form.slice";
import { EncryptionService } from "./EncryptionService";
import { format } from "date-fns";
import moment from "moment";
let fieldConfigData: any;
let hideFields: any;

const jwtDecode = function parseJwt(token: string) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(""),
  );

  return JSON.parse(jsonPayload);
};
export interface IDict<T> {
  [key: string]: T;
}
export type ITheme = IDict<string>;
export const isMobile = window.innerWidth <= 768;
export const setHideFieldData = (data: any) => {
  hideFields = data;
};
export const findObjectByName = (obj: any, name: any) => {
  let result: any = null;

  function search(obj: any) {
    if (Array.isArray(obj)) {
      for (const item of obj) {
        search(item);
      }
    } else if (typeof obj === "object" && obj !== null) {
      if (obj.name === name) {
        result = obj;
        return;
      }
      Object.values(obj).forEach((value) => {
        if (typeof value === "object") {
          search(value);
        }
      });
    }
  }

  search(obj);
  return result?.id;
};
export function isThirteen(birthday: any) {
  // Split the birthday string into day, month, and year
  const parts = birthday.split("/");
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // Months are zero-based in JavaScript
  const year = parseInt(parts[2], 10);

  // Parse the birthday string into a Date object
  const birthDate = new Date(year, month, day);

  // Get the current date
  const currentDate = new Date();

  // Calculate the difference in years
  const ageDifference = currentDate.getFullYear() - birthDate.getFullYear();

  // Check if the age difference is exactly 13 and the birthday has already occurred or is today
  if (
    (ageDifference === 13 &&
      (currentDate.getMonth() > birthDate.getMonth() ||
        (currentDate.getMonth() === birthDate.getMonth() &&
          currentDate.getDate() >= birthDate.getDate()))) ||
    ageDifference > 13
  ) {
    return true;
  } else {
    return false;
  }
}

export const findObjectById = (obj: any, id: any) => {
  let result: any = null;

  function search(obj: any) {
    if (Array.isArray(obj)) {
      for (const item of obj) {
        search(item);
      }
    } else if (typeof obj === "object" && obj !== null) {
      if (obj.id === id) {
        result = obj;
        return;
      }
      Object.values(obj).forEach((value) => {
        if (typeof value === "object") {
          search(value);
        }
      });
    }
  }

  search(obj);
  return result;
};

export const splitPhoneNumber = (
  phoneNumber: string,
  countryCode: string = "91",
): string => {
  if (!phoneNumber) return "";
  let cleanedNumber: any = phoneNumber;
  if (phoneNumber.length > 10) {
    // Constructing the regular expression pattern dynamically
    const pattern = new RegExp(`^${countryCode}`);

    // Removing the country code from the phone number
    cleanedNumber = phoneNumber?.replace(pattern, "");
  }

  return cleanedNumber;
};

export const getLoginDataFromToken = (data: any) => {
  const token = data.token;

  const accessRefreshToken = EncryptionService.decrypt_json(token);

  const [access] = accessRefreshToken.split(":");
  const decodedToken = jwtDecode(access);
  decodedToken.token = token;
  const userBusinesses = AppConfig.getUserData(USER_BUSINESSES_KEY);
  if (userBusinesses) {
    decodedToken.businesses = userBusinesses;
  }
  const enc_user_id = data?.enc_user_id;
  if (enc_user_id) {
    AppConfig.setUserData("ENC_USER_ID", enc_user_id);
  }
  AppConfig.setLoggedInData(decodedToken);
  return decodedToken;
};
// Convert Model Into Flatten Object
export const flatten = (obj: any) => {
  let res: any = {};
  (function recurse(obj, current) {
    for (var key in obj) {
      var value = obj[key];
      var newKey: any = current ? current + "." + key : key; // joined key with dot
      if (value && typeof value === "object") {
        recurse(value, newKey); // it's a nested object, so do it again
      } else {
        res[newKey] = value; // it's not an object, so set the property
      }
    }
  })(obj);
  return res;
};

// Convert Model Into Un-Flatten Object
export const unflatten = (model: any) => {
  let inputJson = { ...model };
  var result: any = {};
  for (var i in inputJson) {
    var keys = i.split(".");
    // eslint-disable-next-line no-loop-func
    keys.reduce(function (r, e, j) {
      return (
        r[e] ||
        (r[e] = isNaN(Number(keys[j + 1]))
          ? keys.length - 1 === j
            ? inputJson[i]
            : {}
          : [])
      );
    }, result);
  }
  return result;
};

export const fieldOnChange = (
  data: any,
  model: any,
  field: any,
  fieldConfig: any,
  sectionId: any,
  errorModelData: any,
  dispatch: any,
  setLoading?: any,
  rule?: any,
) => {
  // Ensure that errorModelData is extensible
  if (!Object.isExtensible(errorModelData)) {
    errorModelData = { ...errorModelData }; // Create a shallow copy to maintain extensibility
  }

  model[field.id] = data;
  dispatch(setModel(model));

  checkValidationRule(
    model,
    field,
    fieldConfig,
    sectionId,
    errorModelData,
    dispatch,
    setLoading,
    rule,
  );
  onErrorHandle(field, errorModelData, model, dispatch, sectionId, hideFields);
};

export const checkRule = (
  model: any,
  field: any,
  fieldConfig: any,
  sectionId: any,
  errorModelData: any,
  dispatch: any,
  setLoading?: any,
  rule?: any,
) => {
  if (!Object.isExtensible(errorModelData)) {
    errorModelData = { ...errorModelData }; // Create a shallow copy to maintain extensibility
  }

  checkValidationRule(
    model,
    field,
    fieldConfig,
    sectionId,
    errorModelData,
    dispatch,
    setLoading,
    rule,
  );
};
export const setDefaultValueInModel = (
  data: any,
  model: any,
  field: any,
  fieldConfig: any,
  sectionId: any,
  errorModelData: any,
  dispatch: any,
) => {
  model[field.id] = data;
  dispatch(setModel(model));
};

// Field On Blur Function
export const onErrorHandle = async (
  field: any,
  error: any,
  model: any,
  dispatch?: any,
  section?: any,
  hideFields?: any,
) => {
  let errorModel = { ...error };
  if (model[field?.id] || model[field?.name]) {
    if (
      typeof model[field?.id] === "object" ||
      typeof model[field?.id] === "string" ||
      typeof model[field?.id] === "number"
    ) {
      if (
        checkValidPattern(
          model[field?.id] || model[field?.name],
          field,
          model,
          error,
          dispatch,
        )
      ) {
        if (error[field?.id]) {
          delete error[field?.id];
        }
        dispatch(setRemoveErrorModel(error));
        return;
      } else {
        // errorModel[field?.id] = "Please Enter Valid " + field?.label;
      }
    } else {
      if (error[field?.id]) {
        delete error[field?.id];
      }
      dispatch(setRemoveErrorModel(error));
    }

    dispatch(setRemoveErrorModel(error));
    return;
  } else {
    if (field?.required && !field?.is_hidden && !error[field?.id]) {
      // if(!hideFields.includes(section)){
      let data = { ...errorModel };
      data[field?.id] = "Required";
      error = data;
      //}
    }
  }
  dispatch(await setErrorModel(error));
};

// Check Regex Pattern
const checkValidPattern = (
  value: any,
  field: any,
  model: any,
  errorModel: any,
  dispatch: any,
) => {
  if (field?.type !== "dropdown" && !field?.condition) {
    var pattern = new RegExp(getPatternType(field?.type, field));
    if (
      pattern.test(
        isNaN(value)
          ? typeof value !== "object"
            ? value.toLowerCase()
            : ""
          : value,
      )
    ) {
      return true;
    } else {
      errorModel[field?.id] = "Please Enter Valid " + field?.label;
      return false;
    }

    return pattern.test(isNaN(value) ? value.toLowerCase() : value);
  } else if (
    field?.condition &&
    field?.action === "lessThen" &&
    model[field?.checkFieldId]
  ) {
    if (
      typeof model[field?.checkFieldId] === "object" &&
      typeof model[field?.id] === "object"
    ) {
      if (model[field?.id].label > model[field?.checkFieldId].label) {
        errorModel[field?.id] = field?.errorMessage;
        return false;
      } else {
        return true;
      }
    } else {
      if (Number(model[field?.checkFieldId]) < Number(model[field?.id])) {
        errorModel[field?.id] = field?.errorMessage;
        return false;
      } else {
        return true;
      }
    }
  } else if (
    field?.condition &&
    field?.action === "greaterThen" &&
    model[field?.checkFieldId]
  ) {
    var pattern = new RegExp(getPatternType(field?.type, field));
    if (
      typeof model[field?.checkFieldId] === "object" &&
      typeof model[field?.id] === "object"
    ) {
      if (model[field?.id].label < model[field?.checkFieldId].label) {
        errorModel[field?.id] = field?.errorMessage;
        return false;
      } else {
        return true;
      }
    } else {
      if (Number(model[field?.id]) < Number(model[field?.checkFieldId])) {
        errorModel[field?.id] = field?.errorMessage;
        return false;
      } else {
        return true;
      }
    }
    // if (pattern.test(isNaN(value) ? typeof value !== "object" ? value.toLowerCase() : "" : value)) {

    // } else {
    //   return false
    // }
  } else if (
    field?.condition &&
    field?.action === "notEqualTo" &&
    model[field?.checkFieldId]
  ) {
    var pattern = new RegExp(getPatternType(field?.type, field));
    if (
      typeof model[field?.checkFieldId] === "object" &&
      typeof model[field?.id] === "object"
    ) {
      if (model[field?.checkFieldId].label === model[field?.id].label) {
        errorModel[field?.id] = field?.errorMessage;
        return false;
      }
    } else {
      if (
        pattern.test(
          isNaN(value)
            ? typeof value !== "object"
              ? value.toLowerCase()
              : ""
            : value,
        )
      ) {
        if (field.type === "phone") {
          if ("+" + model[field?.id] === model[field?.checkFieldId]) {
            errorModel[field?.id] = field?.errorMessage;
            return false;
          }
        } else {
          if (model[field?.id] === model[field?.checkFieldId]) {
            errorModel[field?.id] = field?.errorMessage;
            return false;
          }
        }
      } else {
        errorModel[field?.id] = "Please Enter Valid " + field?.label;
        return false;
      }
    }

    var pattern = new RegExp(getPatternType(field?.type, field));
    return pattern.test(
      isNaN(value)
        ? typeof value !== "object"
          ? value.toLowerCase()
          : ""
        : value,
    );
  } else {
    return true;
  }
};
// Get Regex Pattern As Per Field Type
const getPatternType = (type: string, field: any) => {
  switch (type) {
    case "email":
      return "^[_a-zA-Z0-9]+(\\.[_a-zA-Z0-9]+)*([+]([_a-zA-Z0-9]+))*@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*(\\.[a-zA-Z]{2,4})$";
    // return "^[_a-zA-Z0-9]+(\\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*(\\.[a-zA-Z]{2,4})$"
    default:
      return field?.regex_pattern;
  }
};
const checkRuleAsPerCondition = (model: any, rule: any, field: any) => {
  const fieldValue = rule.isNotInPresntSection
    ? rule?.value
    : model[rule.name]?.id || model[rule.name];
  const comparisonValue = rule.isNotInPresntSection
    ? model[rule.otherSectionName]?.id || model[rule.otherSectionName]
    : rule.value;

  switch (rule.condition) {
    case "is":
      return fieldValue === comparisonValue;
    case "is not":
      return fieldValue !== comparisonValue;
    case "noPresent":
      return !model[rule?.name] ? true : false;
    default:
      return false;
  }
};
const executeCondition = (
  field: any,
  ruleModel: any,
  errorModelData: any,
  dispatch: any,
  model: any,
) => {
  new Promise((resolve) => {
    ruleModel?.executeCondition?.map((condition: any) => {
      if (condition?.name === field?.id) {
        let data: any;
        switch (condition?.condition) {
          case "hide":
            data = [field.id];
            dispatch(setHideFields(data));
            if (field?.is_hidden) {
              field.is_hidden = true;
            }
            delete errorModelData[field.name];
            if (field?.fields) {
              field?.fields?.map((res: any) => {
                if (errorModelData[res?.id]) {
                  delete errorModelData[res?.id];
                }
                model[res?.id] = "";
                dispatch(setHideFields([res?.id]));
              });
            }
            dispatch(setRemoveErrorModel(errorModelData));
            dispatch(setModel(model));
            // resolve();
            break;
          case "show":
            data = {
              key: "show",
              name: field.id,
            };
            dispatch(setHideFields(data));
            if (!model[field?.id] && field?.required) {
              errorModelData[field?.id] = "Required";
            }
            if (field?.fields) {
              field?.fields?.map((res: any) => {
                if (!model[res?.id] && res?.required) {
                  errorModelData[res?.id] = "Required";
                }
                data = {
                  key: "show",
                  name: res.id,
                };
                dispatch(setHideFields(data));
              });
            }

            dispatch(setErrorModel(errorModelData));
            if (field?.is_hidden) {
              field.is_hidden = false;
            }
            // resolve();
            break;
          case "readOnly":
            data = [condition.name];
            dispatch(setReadOnlyFields(data));
            break;
        }
      }
    });
  });
};

const executeConditionForSection = (
  field: any,
  ruleModel: any,
  errorModel: any,
  dispatch: any,
  model: any,
) => {
  let data: any;
  ruleModel?.executeCondition?.map((condition: any) => {
    if (condition?.name === field?.id) {
      switch (condition?.condition) {
        case "hide":
          data = [field.name];
          dispatch(setHideFields(data));
          field.is_hidden = true;
          field?.rows?.childs?.map((res: any) => {
            res?.fields?.map((res: any) => {
              if (errorModel[res?.id]) {
                delete errorModel[res?.id];
              }
            });
          });
          dispatch(setRemoveErrorModel(errorModel));

          return field;
        case "show":
          data = {
            key: "show",
            name: field.name,
          };
          dispatch(setHideFields(data));
          let errorData: any;
          field?.rows?.childs?.map((res: any) => {
            res?.fields?.map((res: any) => {
              errorData = { ...errorModel };
              if (!model[res?.id]) {
                errorData[res?.id] = "Required";
              }
            });
          });
          dispatch(setErrorModel(errorData));

          field.is_hidden = false;
          return field;
      }
    }
  });
};

const updateFieldConfig = (
  fieldConfig: any,
  ruleModel: any,
  sectionId: any,
  errorModelData: any,
  dispatch: any,
  model: any,
  setLoading?: any,
) => {
  let status;
  // setLoading(true)
  fieldConfigData.map((config: any) => {
    config?.child?.map((fieldConfigIterator: any) => {
      fieldConfigIterator?.rows?.childs?.map((fieldSection: any) => {
        fieldSection?.fields?.map((field: any) => {
          // if (field.id === sectionId) {
          ruleModel?.executeCondition?.map((condition: any) => {
            if (field?.id === condition?.name && condition?.type === "field") {
              executeCondition(
                field,
                ruleModel,
                errorModelData,
                dispatch,
                model,
              );

              // setLoading(false)
            }
            if (condition?.type === "table_field" && field?.type === "table") {
              // setLoading(true)
              field?.config?.map((row: any, index: any) => {
                row?.fields?.map((col: any, index: any) => {
                  executeCondition(
                    col,
                    ruleModel,
                    errorModelData,
                    dispatch,
                    model,
                  );
                });
              });
              // setLoading(false)
            }
            if (condition?.type === "table_row" && field?.type === "table") {
              // setLoading(true)

              field?.config?.map((row: any, index: any) => {
                executeCondition(
                  row,
                  ruleModel,
                  errorModelData,
                  dispatch,
                  model,
                );
              });
              // setLoading(false)
              let sectionData: any;
              fieldConfigData.map((res1: any) => {
                res1?.child?.map((res2: any) => {
                  if (condition?.name === res2?.name) {
                    sectionData = res2;
                  }
                });
              });

              executeConditionForSection(
                sectionData,
                ruleModel,
                errorModelData,
                dispatch,
                model,
              );
              // setLoading(false);
            }
            if (condition?.type === "section") {
              executeCondition(
                fieldConfigIterator,
                ruleModel,
                errorModelData,
                dispatch,
                model,
              );

              // setLoading(false)
            }
            if (condition?.condition === "optionHide") {
              let data: any = {};
              data[condition?.name] = condition?.type;
              dispatch(setHideOptionIds(data));
            }
          });
          // }
        });
      });
      status = true;
    });
  });
  dispatch(setFieldJSONConfig(fieldConfigData));
  return status;
};

export function isEmptyObject(obj: any) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
}

// const checkGreaterThen = (model:any,firstValue:any,secoundValue:any) =>{
//   if( model[firstValue] > model[secoundValue]){
//     return true
//   }else{
//     alert("asdasdsad")
//   }
// }
const checkValidationRuleForAny = (
  model: any,
  field: any,
  fieldConfig: any,
  ruleModel: any,
  sectionId: any,
  errorModelData: any,
  dispatch: any,
  setLoading?: any,
) => {
  let status;
  let isBroken = false;
  ruleModel?.condition?.rule?.map((rule: any) => {
    if (isBroken) {
      status = true;
    }
    // if(rule?.type ==="greaterThen"){
    //   if(checkGreaterThen(model,rule?.rule,rule?.value)){

    //   }
    // }
    if (
      field?.id === rule?.name &&
      checkRuleAsPerCondition(model, rule, field)
    ) {
      // if (checkRuleAsPerCondition(model, rule)) {
      isBroken = true;
      if (
        updateFieldConfig(
          fieldConfig,
          ruleModel,
          sectionId,
          errorModelData,
          dispatch,
          model,
          setLoading,
        )
      ) {
        status = true;
      }
      // }
    }
  });
  return status;
};
const checkValidationRuleForAnd = (
  model: any,
  field: any,
  fieldConfig: any,
  ruleModel: any,
  sectionId: any,
  errorModelData: any,
  dispatch: any,
  setLoading?: any,
) => {
  let status = false; // Set initial status to false
  let conditionMapping = true; // Set initial condition mapping to true
  ruleModel?.condition?.rule?.forEach((rule: any) => {
    // Check each rule condition
    if (!checkRuleAsPerCondition(model, rule, field)) {
      // If any condition fails, set condition mapping to false
      conditionMapping = false;
    }
  });

  if (conditionMapping) {
    // If all conditions pass
    if (
      updateFieldConfig(
        fieldConfig,
        ruleModel,
        sectionId,
        errorModelData,
        dispatch,
        model,
        setLoading,
      )
    ) {
      // Update fieldConfig if updateFieldConfig returns true
      status = true; // Set status to true
    }
  }
  return status; // Return the status
};
const checkValidationRuleForAddition = (
  model: any,
  field: any,
  fieldConfig: any,
  ruleModel: any,
  sectionId: any,
  errorModelData: any,
  dispatch: any,
) => {
  let status;
  let isBroken = false;
  let value = 0;
  let hideFieldsModel = { ...hideFields };

  ruleModel.executeCondition.map((condition: any) => {
    if (value && model[condition.name] && !hideFieldsModel[condition.name]) {
      value = value + Number(model[condition.name]);
    } else {
      if (model[condition.name] && !hideFieldsModel[condition.name]) {
        value = Number(model[condition.name]);
      }
    }
  });
  model[ruleModel.condition.field_details[0].name] = value;

  value = 0;

  dispatch(setModel(model));

  return status;
};
const assignValue = (
  model: any,
  field: any,
  fieldConfig: any,
  ruleModel: any,
  sectionId: any,
  errorModelData: any,
  dispatch: any,
) => {
  let error = { ...errorModelData };
  if (
    model[ruleModel?.condition?.rule[0]?.name] ===
    ruleModel?.condition?.rule[0]?.value
  ) {
    ruleModel.executeCondition.forEach((condition: any) => {
      let data = [condition.value];
      // dispatch(setReadOnlyFields(data))

      model[condition.value] = model[condition?.name];
      if (errorModelData[condition?.value]) {
        delete errorModelData[condition?.value];
      }
      dispatch(setHideFields(data));
    });
    dispatch(setRemoveErrorModel(errorModelData));
    dispatch(setModel(model));
  } else {
    let errorData = { ...errorModelData };

    ruleModel.executeCondition.forEach((condition: any) => {
      let data = {
        key: "show",
        name: condition?.value,
      };
      if (model[condition?.id] !== "") {
        errorData[condition?.id] = "Required";
      }
      dispatch(setHideFields(data));
      dispatch(setErrorModel(errorData));
      // dispatch(setReadOnlyFields(data))
    });
    dispatch(setModel(model));
  }
};
export const convertDateToDatePickerFormant = (dateString: any) => {
  // Input date in the format "DD/MM/YYYY"
  // var inputDate = dateString;
  const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;
  if (!dateRegex.test(dateString)) {
    // If the format doesn't match, return a default value or throw an error
    return dateString; // You can change this to whatever default value you prefer
  }
  // // Split the input date into day, month, and year
  // var parts = inputDate.split('/');
  // var day = parseInt(parts[0], 10);
  // var month = parseInt(parts[1], 10) - 1; // Months are zero-based in JavaScript
  // var year = parseInt(parts[2], 10);

  // // Create a new Date object with the provided date
  // var date = new Date(year, month, day);

  // // Format the date according to your requirement
  // var options :any = { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short' };
  // var formattedDate = date.toLocaleString('en-US', options) || null;
  // return formattedDate?.toString()
  // Convert the string date to a Date object
  const parts = dateString.split("/");
  const year = parseInt(parts[2], 10);
  const month = parseInt(parts[1], 10) - 1;
  const day = parseInt(parts[0], 10);
  const convertedDate = new Date(year, month, day);
  return convertedDate || null;
};
export const convertStringToFormattedDate = (dateString: string) => {
  // Check if dateString is provided and matches the expected format
  // const dateRegex = /^\w{3} \w{3} \d{2} \d{4} \d{2}:\d{2}:\d{2} GMT\+\d{4} \(.*\)$/; // Adjust the regex as needed
  // if (!dateString || !dateRegex.test(dateString)) {
  //   console.error('Invalid date string format');
  //   return null;
  // }

  // Create a new Date object from the date string
  const date = new Date(dateString);

  // Get the day, month, and year from the Date object
  const day = date.getDate();
  const month = date.getMonth() + 1; // Month starts from 0, so add 1
  const year = date.getFullYear();

  // Pad day and month with leading zeros if needed
  const paddedDay = day < 10 ? "0" + day : day;
  const paddedMonth = month < 10 ? "0" + month : month;

  // Concatenate day, month, and year with '/' separator
  const formattedDate = `${paddedDay}/${paddedMonth}/${year}`;

  return formattedDate;
};

export const convertDateToString = (dateString: string) => {
  // Check if the dateString matches the expected format
  const match = dateString?.match(/(\d{2})\/(\d{2})\/(\d{4})/);
  if (!match) {
    console.error(
      "Invalid date string format. Please use the format DD/MM/YYYY.",
    );
    return null; // Or handle the error in a way that makes sense for your application
  }
  // Extract day, month, and year from the matched groups
  const [, day, month, year] = match;
  // Create a new Date object
  const parsedDate = new Date(`${year}-${month}-${day}`);
  // Format the parsed date object into the desired format
  const options: any = {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZoneName: "short",
  };
  const formattedDate = parsedDate.toLocaleString("en-US", options);
  return formattedDate;
};
const checkValidationRuleForPercentage = (
  model: any,
  field: any,
  fieldConfig: any,
  ruleModel: any,
  sectionId: any,
  errorModelData: any,
  dispatch: any,
) => {
  let status;
  let isBroken = false;
  let value = 0;
  let percentage = 0;
  let maxMark: any = 0;
  // setTimeout(() => {

  ruleModel.executeCondition.forEach((condition: any) => {
    if (model[condition.name] && !condition?.isMaxMark) {
      value = value + Number(model[condition.name]);
    }
    if (model[condition.name] && condition?.isMaxMark) {
      maxMark = maxMark + Number(model[condition.name]);
    }
  });
  if (maxMark && value) {
    percentage = (Math.min(value) / maxMark) * 100;
  } else {
    percentage = 0;
  }
  // Update model with percentage after 2 milliseconds
  model[ruleModel.condition.field_details[0]?.name] = percentage.toFixed(2);
  dispatch(setModel(model));
  value = 0;
  percentage = 0;
  maxMark = 0;
  // }, 2); // 2 milliseconds delay

  return status;
};
const checkValidationRuleForNotEqual = (
  model: any,
  field: any,
  ruleModel: any,
  errorModelData: any,
  dispatch: any,
) => {
  let status;
  ruleModel.condition.rule.map((res: any) => {
    if (model[res.name] === model[res.value]) {
      let data = { ...errorModelData };
      data[field?.id] = "Required";
      errorModelData = data;
      dispatch(setErrorModel(errorModelData));
    } else {
      if (errorModelData[field?.id]) {
        delete errorModelData[field?.id];
      }
      dispatch(setRemoveErrorModel(errorModelData));
    }
  });
  return status;
};
const getDateInFormant = (dateString: any) => {
  const date = new Date(dateString);

  // Get day, month, and year
  const day = date.getDate();
  const month = date.getMonth() + 1; // Month starts from 0, so add 1
  const year = date.getFullYear();

  // Format day and month with leading zeros if necessary
  const formattedDay = (day < 10 ? "0" : "") + day;
  const formattedMonth = (month < 10 ? "0" : "") + month;

  // Create the formatted date string
  const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;
  return formattedDate;
};
export const getMonthsDifference = (
  fromDate: string,
  toDate: string,
  dispatch: any,
) => {
  // Assuming date format is 'dd/mm/yyyy'
  const getDateParts = (dateString: string) =>
    dateString.split("/").map((part) => parseInt(part));

  const fromDateParts = getDateParts(fromDate);
  const toDateParts = getDateParts(toDate);

  const from = new Date(
    fromDateParts[2],
    fromDateParts[1] - 1,
    fromDateParts[0],
  );
  const to = new Date(toDateParts[2], toDateParts[1] - 1, toDateParts[0]);

  if (from > to) {
    dispatch(
      showSnackBar({
        open: true,
        severity: "error",
        message: "From date should be less than To date",
      }),
    );
    return 0;
  } else {
    const months =
      (to.getFullYear() - from.getFullYear()) * 12 +
      (to.getMonth() - from.getMonth());
    return months;
  }
};
const checkValidationRuleForMonthCalculator = (
  model: any,
  field: any,
  fieldConfig: any,
  ruleModel: any,
  sectionId: any,
  errorModelData: any,
  dispatch: any,
) => {
  let status;
  let value: any = 0;
  let fromDate = ruleModel?.executeCondition[0]?.name;
  let toDate = ruleModel?.executeCondition[1]?.name;
  if (model[fromDate] && model[toDate]) {
    value = getMonthsDifference(
      model[fromDate],
      model[toDate],
      dispatch,
    ).toString();
  }
  model[ruleModel.condition.field_details[0].name] = value;
  value = 0;
  dispatch(setModel(model));
  return status;
};
const checkValidationRuleForYearCalculator = (
  model: any,
  field: any,
  fieldConfig: any,
  ruleModel: any,
  sectionId: any,
  errorModelData: any,
  dispatch: any,
) => {
  let status;
  const monthsInYear = 12;
  let totalMonth = 0;
  ruleModel?.executeCondition?.map((res: any, index: any) => {
    if (Number(model[res?.name])) {
      totalMonth = totalMonth + Number(model[res?.name]);
    }
  });
  const years = Math.floor(totalMonth / monthsInYear);
  const remainingMonths = totalMonth % monthsInYear;
  let data = `${years} year(s) and ${remainingMonths} month(s)`;
  model[ruleModel.condition.field_details[0].name] = data;
  dispatch(setModel(model));
  return status;
};

const checkValidationRule = (
  model: any,
  field: any,
  fieldConfig: any,
  sectionId: any,
  errorModelData: any,
  dispatch: any,
  setLoading?: any,
  rule?: any,
) => {
  fieldConfigData = JSON.parse(JSON.stringify(fieldConfig));

  let isBroken = false;

  rule?.map((ruleModel: any) => {
    if (isBroken) {
      return;
    }
    if (ruleModel?.condition?.action === "any") {
      if (
        checkValidationRuleForAny(
          model,
          field,
          fieldConfig,
          ruleModel,
          sectionId,
          errorModelData,
          dispatch,
          setLoading,
        )
      ) {
        if (ruleModel?.condition?.reTriggerRule) {
          ruleModel?.condition?.reTriggerRule.map((res: any) => {
            if (res?.condition?.action === "any") {
              if (
                checkValidationRuleForAny(
                  model,
                  field,
                  fieldConfig,
                  res,
                  sectionId,
                  errorModelData,
                  dispatch,
                  setLoading,
                )
              ) {
                isBroken = true;
              }
            }
            if (res?.condition?.action === "addition") {
              if (
                checkValidationRuleForAddition(
                  model,
                  field,
                  fieldConfig,
                  res,
                  sectionId,
                  errorModelData,
                  dispatch,
                )
              ) {
                isBroken = true;
              }
            }
            if (ruleModel?.condition?.action === "percentage") {
              if (
                checkValidationRuleForPercentage(
                  model,
                  field,
                  fieldConfig,
                  res,
                  sectionId,
                  errorModelData,
                  dispatch,
                )
              ) {
                isBroken = true;
              }
            }
          });
        } else {
          isBroken = true;
        }
      }
    }
    if (ruleModel?.condition?.action === "addition") {
      if (
        checkValidationRuleForAddition(
          model,
          field,
          fieldConfig,
          ruleModel,
          sectionId,
          errorModelData,
          dispatch,
        )
      ) {
        isBroken = true;
      }
    }
    if (ruleModel?.condition?.action === "percentage") {
      if (
        checkValidationRuleForPercentage(
          model,
          field,
          fieldConfig,
          ruleModel,
          sectionId,
          errorModelData,
          dispatch,
        )
      ) {
        isBroken = true;
      }
    }
    if (ruleModel?.condition?.action === "assignValue") {
      ruleModel?.condition?.rule?.map((rule: any) => {
        if (field?.id === rule?.name) {
          assignValue(
            model,
            field,
            fieldConfig,
            ruleModel,
            sectionId,
            errorModelData,
            dispatch,
          );
        }
      });
    }
    if (ruleModel?.condition?.action === "monthCalculator") {
      if (
        checkValidationRuleForMonthCalculator(
          model,
          field,
          fieldConfig,
          ruleModel,
          sectionId,
          errorModelData,
          dispatch,
        )
      ) {
        isBroken = true;
      }
    }
    if (ruleModel?.condition?.action === "yearCalculator") {
      if (
        checkValidationRuleForYearCalculator(
          model,
          field,
          fieldConfig,
          ruleModel,
          sectionId,
          errorModelData,
          dispatch,
        )
      ) {
        isBroken = true;
      }
    }
    if (ruleModel?.condition?.action === "and") {
      ruleModel?.condition?.rule?.map((rule: any) => {
        if (field?.id === rule?.name) {
          if (
            checkValidationRuleForAnd(
              model,
              field,
              fieldConfig,
              ruleModel,
              sectionId,
              errorModelData,
              dispatch,
              setLoading,
            )
          ) {
            isBroken = true;
          }
        }
      });
    }
  });

  dispatch(setFieldJSONConfig(fieldConfigData));
  dispatch(setRemoveErrorModel(errorModelData));
  fieldConfigData = [];
};

// method to disable all the fields in the form for View Action
export function setReadOnlyToTrue(config: any) {
  // Deep clone the config object to avoid modifying the original data
  let data = JSON.parse(JSON.stringify(config));
  if (!data) return;
  // Iterate through each section in the config
  data.forEach((section: any) => {
    // Iterate through each subsection in the section
    section.child.forEach((subsection: any) => {
      // Iterate through each row in the subsection
      subsection.rows.childs.forEach((row: any) => {
        // Iterate through each field in the row
        row.fields.forEach((field: any) => {
          // Set read_only property to true
          field.read_only = true;
          if (field?.type === "table") {
            field?.config?.forEach((res: any) => {
              res.fields.forEach((res: any) => {
                res.read_only = true;
              });
            });
          }
        });
      });
    });
  });

  return data;
}

// Default Model
export const modelData = {};
export const defaultValue: any = {
  TgyDs56h: 1,
  Gyh45xd5: 2,
  "7ftFasTgs": 3,
  "4ft67sTgs": 4,
  "5ft67sTgs": 5,
  "6ft67sTgs": 6,
  "7ft67sTgs": 7,
  "8ft67sTgs": 8,
  // RFBLQxYn : "Na2daYRM",
  Yg5fG7gT: 1,
  Tvhj4Fst: 2,
  Hg6f6Dt4: 3,
  "4Hg6f6Dt4": 4,

  hTjLHkx2: "8dNsauAk",
  ZsWtbDeY: "CQy64QXd",
};
export let navBar = [
  {
    label: "Basic Details",
    name: "basic_details",
  },
  {
    label: "Academic Details",
    name: "academic_details",
  },
  { label: "Faculty & Publications", name: "faculty_publications" },
  {
    label: "Work Experience",
    name: "work_experience",
  },
  {
    label: "Documents Upload",
    name: "documents_upload",
  },
  {
    label: "List of Enclosures",
    name: "list_of_enclosures",
  },

  {
    label: "Payment",
    name: "payment",
  },
];
// PET 2023 Application Form

export let FieldJSONConfig = [
  {
    Label: "Basic Details",
    id: "basic_details",
    name: "basic_details",
    onComplete: "basicDetailsFunctionForSiu",
    child: [
      {
        label: "Personal Details",
        name: "personal_details",
        type: "slide",
        is_allowed: true,
        is_hidden: false,
        rows: {
          childs: [
            {
              lable: "row1",
              fields: [
                {
                  id: "9cJGmScM",
                  label: "Title",
                  name: "title",
                  original_label: "Title",
                  orginal_name: "title",
                  type: "dropdown",
                  default_value: "",
                  placeholder: "-- Please Select --",
                  help_text: "",
                  required: true,
                  read_only: false,
                  is_hidden: false,
                  regex_pattern: "",
                  choices: [],
                  moduleId: "contact",
                },
                {
                  id: "HWJGd7e4",
                  label: "Name (as per higher qualifying degree)",
                  name: "name",
                  original_label: "First Name",
                  orginal_name: "first_name",
                  type: "text",
                  default_value: "",
                  placeholder: "Enter Name",
                  help_text: "",
                  required: true,
                  read_only: false,
                  is_hidden: false,
                  regex_pattern: "",
                },
                {
                  id: "Am7Czqhs",
                  label: "Email Address",
                  name: "email",
                  original_label: "First Name",
                  orginal_name: "first_name",
                  type: "email",
                  default_value: "",
                  placeholder: "Enter Your Email Id",
                  help_text: "",
                  required: true,
                  read_only: true,
                  is_hidden: false,
                  regex_pattern: "",
                  showInfo:
                    "Please ensure to enter the email ids of Gmail or Yahoo domains, please do not enter institute or any corporate email ids.",
                  infoColor: "#7d34eb",
                },
                {
                  id: "VeUtj9tL",
                  label: "Alternate Email Address",
                  name: "alternate_email_address",
                  original_label: "First Name",
                  orginal_name: "first_name",
                  type: "email",
                  default_value: "",
                  placeholder: "Enter Your Alternate Email Id",
                  help_text: "",
                  required: true,
                  is_hidden: false,
                  regex_pattern: "",
                  action: "notEqualTo",
                  checkFieldId: "Am7Czqhs",
                  condition: true,
                  errorMessage: "Email and Alternate Email is not same",
                },
              ],
            },
            {
              lable: "row2",
              fields: [
                {
                  id: "M35EXSBF",
                  label: "Mobile Number",
                  name: "mobile_phone_number",
                  original_label: "Middle Name",
                  orginal_name: "middle_name",
                  type: "phone",
                  default_value: "",
                  placeholder: "Enter Mobile Phone Number",
                  help_text: "",
                  required: true,
                  read_only: true,
                  is_hidden: false,
                  regex_pattern: "",
                },
                {
                  id: "eQmosweU",
                  label: "Alternate Mobile Number",
                  name: "alternate_mobile_number",
                  original_label: "First Name",
                  orginal_name: "first_name",
                  type: "phone",
                  default_value: "",
                  placeholder: "Enter Alternate Mobile Phone Number",
                  help_text: "",
                  required: false,
                  is_hidden: false,
                  regex_pattern: "",
                  action: "notEqualTo",
                  checkFieldId: "M35EXSBF",
                  condition: true,
                  errorMessage:
                    "Alternate Number and Mobile Number is not same",
                },
                {
                  id: "ToYue2gE",
                  label: "Date of Birth",
                  name: "date_of_birth",
                  original_label: "Middle Name",
                  orginal_name: "middle_name",
                  type: "datetime",
                  default_value: "",
                  placeholder: "Enter Date Of Birth",
                  help_text: "",
                  required: true,
                  is_hidden: false,
                  regex_pattern: "",
                  allowFutureDate: true,
                },
                {
                  id: "H2zKteV8",
                  label: "Gender",
                  name: "gender",
                  original_label: "Title",
                  orginal_name: "title",
                  type: "dropdown",
                  default_value: "",
                  placeholder: "-- Please Select --",
                  help_text: "",
                  required: true,
                  is_hidden: false,
                  regex_pattern: "",
                  choices: [],
                  moduleId: "application",
                },
                {
                  id: "XvcimGmV",
                  label: "Nationality",
                  name: "nationality",
                  original_label: "Title",
                  orginal_name: "title",
                  type: "dropdown",
                  default_value: "",
                  placeholder: "-- Please Select --",
                  help_text: "",
                  required: true,
                  is_hidden: false,
                  regex_pattern: "",
                  choices: [],
                  // resetField: "H2z5teV8",
                  moduleId: "application",
                },
                {
                  id: "AsdfxG8e",
                  label: "Country Represented",
                  name: "country_represented",
                  original_label: "Title",
                  orginal_name: "title",
                  type: "dropdown",
                  default_value: "",
                  placeholder: "-- Please Select --",
                  help_text: "",
                  required: true,
                  is_hidden: false,
                  regex_pattern: "",
                  onInit: "getAllCountry",
                  moduleId: "contact",
                  option_mapping: {
                    id: "aeqDqdkE",
                  },
                  resetField: "H2z5teV8",
                  choices: [],
                },
                {
                  id: "H2z5teV8",
                  label: "Category",
                  name: "category",
                  original_label: "Title",
                  orginal_name: "title",
                  type: "dropdown",
                  default_value: "",
                  placeholder: "-- Please Select --",
                  help_text: "",
                  required: true,
                  is_hidden: false,
                  regex_pattern: "",
                  choices: [],
                  moduleId: "application",
                },
                {
                  id: "VxjkTbTQ",
                  label: "Aadhar Card Number",
                  name: "adhar_card",
                  original_label: "First Name",
                  orginal_name: "first_name",
                  type: "text",
                  default_value: "",
                  placeholder: "Enter Adhar Number",
                  help_text: "",
                  required: false,
                  read_only: false,
                  is_hidden: true,
                  regex_pattern: "^[0-9]{4}[ -]?[0-9]{4}[ -]?[0-9]{4}$",
                },
                {
                  id: "YHpx5JFX",
                  label: "Passport Number",
                  name: "passport_number",
                  original_label: "Passport Number",
                  orginal_name: "passport_number",
                  type: "string",
                  default_value: "",
                  placeholder: "Enter Passport Number",
                  help_text: "",
                  required: false,
                  read_only: false,
                  is_hidden: true,
                  regex_pattern: "^(?!^0+$)[a-zA-Z0-9]{3,20}$",
                },
              ],
            },
          ],
        },
      },
      {
        label: "Permanent Address",
        id: "permanent_address",
        is_hidden: false,
        rows: {
          childs: [
            {
              label: "row8",
              fields: [
                {
                  parent_id: "b6wtQTxZ",
                  position: 9,
                  label: "Address Line 1",
                  type: "string",
                  required: true,
                  custom: true,
                  visible_in_bulk_update: true,
                  unique: false,
                  visible: true,
                  visible_in_form: false,
                  read_only: false,
                  whatsapp_module_allowed_fields: false,
                  email_module_allowed_fields: false,
                  sms_module_allowed_fields: false,
                  id: "PiCnD3xK",
                  name: "permanent_address_line_1",
                  placeholder: "Enter Address Line 1",
                  tooltip: "",
                  updated_by: "a23092bf-5193-46fe-a1c7-9b312b643e34",
                  is_dependant: false,
                  dependant_field_id: null,
                },
                {
                  parent_id: "b6wtQTxZ",
                  position: 11,
                  label: "Address Line 2",
                  type: "string",
                  required: true,
                  custom: true,
                  visible_in_bulk_update: true,
                  unique: false,
                  visible: true,
                  visible_in_form: false,
                  read_only: false,
                  whatsapp_module_allowed_fields: false,
                  email_module_allowed_fields: false,
                  sms_module_allowed_fields: false,
                  id: "JDq8Wz3b",
                  name: "permanent_address_line_2",
                  placeholder: "Enter Address Line 2",
                  tooltip: "",
                  is_dependant: false,
                  dependant_field_id: null,
                },
                {
                  parent_id: "b6wtQTxZ",
                  position: 13,
                  label: "Picode/Zipcode",
                  type: "string",
                  required: true,
                  custom: true,
                  visible_in_bulk_update: true,
                  unique: false,
                  visible: true,
                  visible_in_form: false,
                  read_only: false,
                  whatsapp_module_allowed_fields: false,
                  email_module_allowed_fields: false,
                  sms_module_allowed_fields: false,
                  id: "868pjYVj",
                  name: "permanent_address_pincode",
                  placeholder: "Enter Pincode/ZipCode",
                  tooltip: "",
                  is_dependant: false,
                  dependant_field_id: null,
                  regex_pattern: "^[0-9]{6}$",
                },
                {
                  position: 15,
                  is_deleted: false,
                  label: "Country",
                  type: "dropdown",
                  required: true,
                  is_hidden: false,
                  custom: false,
                  unique: false,
                  visible: true,
                  placeholder: "-- Please Select --",
                  visible_in_form: true,
                  whatsapp_module_allowed_fields: true,
                  email_module_allowed_fields: true,
                  sms_module_allowed_fields: true,
                  id: "XkAYFV8j",
                  name: "permanent_address_country",
                  onInit: "getAllCountry",
                  onChange: "getAllState",
                  moduleId: "contact",
                  option_mapping: {
                    id: "aeqDqdkE",
                    dependant_id: "XkAFTY8j",
                  },
                  choices: [],
                },
                {
                  parent_id: "9b8Jc3GG",
                  position: 8,
                  label: "State",
                  type: "dropdown",
                  required: true,
                  custom: false,
                  unique: false,
                  visible: true,
                  visible_in_form: true,
                  whatsapp_module_allowed_fields: true,
                  email_module_allowed_fields: true,
                  sms_module_allowed_fields: true,
                  id: "XkAFTY8j",
                  name: "permanent_address_state",
                  original_label: "State Region",
                  original_name: "state_region",
                  onChange: "getAllCity",
                  onInit: "getAllState",
                  placeholder: "-- Please Select --",
                  is_dependant: true,
                  moduleId: "contact",
                  dependant_field_id: "XkAYFV8j",
                  option_mapping: {
                    id: "aeqDqdkE",
                    dependant_id: "XkJKLY8j",
                  },
                  choices: [],
                },
                {
                  parent_id: "9b8Jc3GG",
                  position: 8,
                  label: "City",
                  type: "dropdown",
                  required: true,
                  custom: false,
                  unique: false,
                  visible: true,
                  is_hidden: false,
                  read_only: false,
                  visible_in_form: true,
                  whatsapp_module_allowed_fields: true,
                  email_module_allowed_fields: true,
                  sms_module_allowed_fields: true,
                  id: "XkJKLY8j",
                  name: "permanent_address_city",
                  placeholder: "-- Please Select --",
                  original_label: "city Region",
                  original_name: "city_region",
                  onInit: "getAllState",
                  is_dependant: true,
                  moduleId: "contact",
                  dependant_field_id: "XkAFTY8j",
                  option_mapping: {
                    id: "Pk5jz4BY",
                  },
                  choices: [],
                },
              ],
            },
          ],
        },
      },
      {
        label: "Communication Address",
        id: "communication_address",
        is_hidden: false,
        rows: {
          childs: [
            {
              label: "row8",
              fields: [
                {
                  id: "VxjNaCtTQ",
                  label: "Same As Permanent Address",
                  name: "same_as_permanent_address",
                  original_label: "First Name",
                  orginal_name: "first_name",
                  type: "checkbox",
                  default_value: "",
                  placeholder: "Same As Permanent Address",
                  help_text: "",
                  required: false,
                  read_only: false,
                  is_hidden: false,
                  lable_hide: true,
                },
              ],
            },
            {
              label: "row8",
              fields: [
                {
                  parent_id: "bXhxvSDE",
                  position: 10,
                  label: "Address Line 1",
                  type: "string",
                  required: true,
                  custom: true,
                  visible_in_bulk_update: true,
                  unique: false,
                  visible: true,
                  visible_in_form: false,
                  read_only: false,
                  whatsapp_module_allowed_fields: false,
                  email_module_allowed_fields: false,
                  sms_module_allowed_fields: false,
                  id: "EHEfqzZ6",
                  name: "communication_address_line_1",
                  placeholder: "Enter Address Line 1",
                  tooltip: "",
                  updated_by: "a23092bf-5193-46fe-a1c7-9b312b643e34",
                  is_dependant: false,
                  dependant_field_id: null,
                },
                {
                  parent_id: "b6wtQTxZ",
                  position: 12,
                  label: "Address Line 2",
                  type: "string",
                  required: true,
                  custom: true,
                  visible_in_bulk_update: true,
                  unique: false,
                  visible: true,
                  visible_in_form: false,
                  read_only: false,
                  whatsapp_module_allowed_fields: false,
                  email_module_allowed_fields: false,
                  sms_module_allowed_fields: false,
                  id: "8eosKDRN",
                  name: "communication_address_line_2",
                  placeholder: "Enter Address Line 2",
                  tooltip: "",
                  is_dependant: false,
                  dependant_field_id: null,
                },
                {
                  parent_id: "b6wtQTxZ",
                  position: 14,
                  label: "Picode/Zipcode",
                  type: "string",
                  required: true,
                  custom: true,
                  visible_in_bulk_update: true,
                  unique: false,
                  visible: true,
                  visible_in_form: false,
                  read_only: false,
                  whatsapp_module_allowed_fields: false,
                  email_module_allowed_fields: false,
                  sms_module_allowed_fields: false,
                  id: "ToUP4vjt",
                  name: "communication_address_pincode",
                  placeholder: "Enter Pincode/Zipcode",
                  tooltip: "",
                  is_dependant: false,
                  dependant_field_id: null,
                },
                {
                  position: 15,
                  is_deleted: false,
                  label: "Country",
                  type: "dropdown",
                  required: true,
                  is_hidden: false,
                  custom: false,
                  unique: false,
                  visible: true,
                  visible_in_form: true,
                  whatsapp_module_allowed_fields: true,
                  placeholder: "-- Please Select --",
                  email_module_allowed_fields: true,
                  sms_module_allowed_fields: true,
                  id: "gcUhDZTS",
                  name: "communication_address_country",
                  option_key: "permanent_address_country",
                  onInit: "getAllCountry",
                  onChange: "getAllState",
                  moduleId: "contact",
                  option_mapping: {
                    id: "aeqDqdkE",
                    dependant_id: "XkABJH8j",
                  },
                },
                {
                  parent_id: "9b8Jc3GG",
                  position: 8,
                  label: "State",
                  type: "dropdown",
                  required: true,
                  custom: false,
                  unique: false,
                  visible: true,
                  visible_in_form: true,
                  whatsapp_module_allowed_fields: true,
                  email_module_allowed_fields: true,
                  sms_module_allowed_fields: true,
                  id: "XkABJH8j",
                  placeholder: "-- Please Select --",
                  name: "communication_address_state",
                  option_key: "permanent_address_state",
                  original_label: "State Region",
                  original_name: "state_region",
                  onInit: "getAllState",
                  is_dependant: true,
                  moduleId: "contact",
                  dependant_field_id: "gcUhDZTS",
                  onChange: "getAllCity",
                  option_mapping: {
                    id: "aeqDqdkE",
                    dependant_id: "XkCKWY8j",
                  },
                  condition: {
                    key: "VxjNaCtTQ",
                    value: true,
                  },
                  choices: [],
                },
                {
                  parent_id: "9b8Jc3GG",
                  position: 8,
                  label: "City",
                  type: "dropdown",
                  required: true,
                  custom: false,
                  unique: false,
                  visible: true,
                  visible_in_form: true,
                  whatsapp_module_allowed_fields: true,
                  email_module_allowed_fields: true,
                  sms_module_allowed_fields: true,
                  id: "XkCKWY8j",
                  name: "communication_address_city",
                  placeholder: "-- Please Select --",
                  option_key: "permanent_address_city",
                  original_label: "city Region",
                  original_name: "city_region",
                  onInit: "getAllState",
                  is_dependant: true,
                  moduleId: "contact",
                  dependant_field_id: "XkABJH8j",
                  option_mapping: {
                    id: "Pk5jz4BY",
                  },
                  condition: {
                    key: "VxjNaCtTQ",
                    value: true,
                  },
                  choices: [],
                },
              ],
            },
          ],
        },
      },
      {
        label: "Enrollment Type",
        id: "enrollment_type",
        is_hidden: false,
        rows: {
          childs: [
            {
              label: "row8",
              fields: [
                {
                  id: "qiKRi3FQ",
                  label: "Enrollment Type",
                  name: "enrollment_type",
                  original_label: "faculty",
                  orginal_name: "faculty",
                  type: "dropdown",
                  default_value: "",
                  placeholder: "-- Please Select --",
                  help_text: "",
                  required: true,
                  is_hidden: false,
                  regex_pattern: "",
                  choices: [],
                  moduleId: "application",
                },
                {
                  id: "mmFm8rGG",
                  label:
                    "Would you like to consider your application for SIU - JRF ",
                  name: "would_you_like_to_consider_your_application_for_siu_jrf",
                  original_label: "faculty",
                  orginal_name: "faculty",
                  type: "dropdown",
                  default_value: "",
                  placeholder: "-- Please Select --",
                  help_text: "",
                  required: true,
                  is_hidden: true,
                  regex_pattern: "",
                  choices: [],
                  moduleId: "application",
                  showInfo: "Junior Research Fellowship- Full Time Scholars",
                  infoColor: "#7d34eb",
                },
                {
                  id: "CHiyhJUv",
                  label: "Name of the Institution",
                  name: "name_of_the_institution",
                  original_label: "faculty",
                  orginal_name: "faculty",
                  type: "dropdown",
                  placeholder: "-- Please Select --",
                  default_value: "",
                  help_text: "",
                  required: true,
                  is_hidden: true,
                  regex_pattern: "",
                  choices: [],
                  moduleId: "application",
                },
                {
                  id: "Y3uXuf5w",
                  label: "If other Constituent of Symbiosis, please specify",
                  name: "if_other_constituent_of_symbiosis_please_specify",
                  original_label: "faculty",
                  orginal_name: "faculty",
                  type: "string",
                  default_value: "",
                  placeholder:
                    "Enter If other Constituent of Symbiosis, please specify",
                  help_text: "",
                  required: true,
                  is_hidden: true,
                  regex_pattern: "",
                  choices: [],
                  moduleId: "application",
                },
                {
                  id: "Ge7muEBV",
                  label: "Name of Centre for Research Collaboration",
                  name: "name_of_centre_for_research_collaboration",
                  original_label: "faculty",
                  orginal_name: "faculty",
                  type: "dropdown",
                  default_value: "",
                  placeholder: "-- Please Select --",
                  help_text: "",
                  required: true,
                  is_hidden: true,
                  regex_pattern: "",
                  choices: [],
                  moduleId: "application",
                },
                {
                  id: "gVhWG8Gn",
                  label: "Name of the Current Employer",
                  name: "name_of_the_current_employer",
                  original_label: "faculty",
                  orginal_name: "faculty",
                  type: "string",
                  default_value: "",
                  placeholder: "Enter Name of the Current Employer",
                  help_text: "",
                  required: true,
                  is_hidden: true,
                  regex_pattern: "",
                  choices: [],
                  moduleId: "application",
                },
                {
                  id: "TEeeywnH",
                  label: "Place of Working",
                  name: "place_of_working",
                  original_label: "faculty",
                  orginal_name: "faculty",
                  type: "string",
                  default_value: "",
                  placeholder: "Enter Place of Working",
                  help_text: "",
                  required: true,
                  is_hidden: true,
                  regex_pattern: "",
                  choices: [],
                  moduleId: "application",
                },
              ],
            },
          ],
        },
      },
    ],
  },
  {
    Label: "Academic Details",
    id: "academic_details",
    name: "academic_details",
    type: "slide",
    onComplete: "academicDetailsFunctionForSiu",
    is_allowed: true,
    child: [
      {
        label: "Academic Details",
        id: "Academic Details",
        name: "academics_details",
        is_allowed: true,
        rows: {
          childs: [
            {
              lable: "row1",
              fields: [
                {
                  id: "mqp3DyoA",
                  label: "Faculty",
                  name: "faculty",
                  original_label: "faculty",
                  orginal_name: "faculty",
                  type: "dropdown",
                  default_value: "",
                  placeholder: "-- Please Select --",
                  help_text: "",
                  required: true,
                  is_hidden: false,
                  regex_pattern: "",
                  choices: [],
                  onInit: "getAllCountry",
                  onChange: "getAllState",
                  option_mapping: {
                    id: "mqp3DyoA",
                    dependant_id: "9ybpTdKH",
                    depednat_option_id: "faculty_sub_stream",
                  },
                  moduleId: "application",
                },
                {
                  id: "9ybpTdKH",
                  label: "Faculty Sub Stream",
                  name: "faculty_sub_stream",
                  original_label: "Title",
                  orginal_name: "faculty_sub_stream",
                  type: "dropdown",
                  default_value: "",
                  placeholder: "-- Please Select --",
                  help_text: "",
                  required: true,
                  is_hidden: false,
                  regex_pattern: "",
                  choices: [],
                  onChange: "getAllState",
                  onInit: "getAllState",
                  dependant_field_id: "mqp3DyoA",
                  option_mapping: {
                    id: "mqp3DyoA",
                    dependant_id: "Vx9LTbTQ",
                    depednat_option_id: "masters_degrees",
                  },
                  moduleId: "application",
                  resetField: "QrZkktjr",
                },
                {
                  id: "QrZkktjr",
                  label: "Specialization",
                  name: "specialization",
                  original_label: "Specialization",
                  orginal_name: "specialization",
                  type: "dropdown",
                  default_value: "",
                  placeholder: "Enter Specialization",
                  help_text: "",
                  required: true,
                  is_hidden: false,
                  read_only: false,
                  regex_pattern: "",
                  choices: [],
                  moduleId: "application",
                  row: "6",
                },
                {
                  id: "Vx9LTbTQ",
                  label: "Master Degree Acquired",
                  name: "masters_degrees",
                  original_label: "Title",
                  orginal_name: "faculty_sub_stream",
                  type: "dropdown",
                  default_value: "",
                  placeholder: "-- Please Select --",
                  help_text: "",
                  required: true,
                  is_hidden: false,
                  regex_pattern: "",
                  choices: [],
                  onInit: "getAllState",
                  dependant_field_id: "9ybpTdKH",
                  option_mapping: {
                    id: "9ybpTdKH",
                  },
                  moduleId: "application",
                  showInfo:
                    "Higher degrees/Diplomas awarded by WILP of BITS, SCDL, masters diplomas, PG diplomas other than IIMs, XLRI need to provide equivalence from Statutory bodies like UGC/AICTE/ BCI/MCI etc.",
                  infoColor: "#7d34eb",
                },
                {
                  id: "VxCF39TQ",
                  label: "Masters Degrees if others, please specify",
                  name: "masters_degrees_if_others_please_specify",
                  original_label: "Specialization",
                  orginal_name: "specialization",
                  type: "string",
                  default_value: "",
                  placeholder: "Enter Others Masters Degrees",
                  help_text: "",
                  required: true,
                  is_hidden: true,
                  read_only: false,
                  regex_pattern: "",
                  choices: [],
                  moduleId: "application",
                  row: "6",
                },
              ],
            },
          ],
        },
      },
      {
        label: "Post Graduate Details",
        id: "Post Graduate Details",
        note: "Proof of equivalence from AICTE/UGC required if holding PG Diploma.",
        name: "post_graduate_details",
        is_allowed: true,
        rows: {
          childs: [
            {
              lable: "row1",
              fields: [
                {
                  parent_id: "b6wtQTxZ",
                  position: 15,
                  label: "Degree",
                  type: "dropdown",
                  required: true,
                  custom: true,
                  visible_in_bulk_update: true,
                  unique: false,
                  visible: true,
                  visible_in_form: false,
                  read_only: false,
                  whatsapp_module_allowed_fields: false,
                  email_module_allowed_fields: false,
                  sms_module_allowed_fields: false,
                  id: "XEZSRXLs",
                  name: "post_graduate_degree",
                  placeholder: "-- Please Select --",
                  tooltip: "",
                  is_dependant: false,
                  dependant_field_id: null,
                  moduleId: "application",
                },
                {
                  parent_id: "b6wtQTxZ",
                  position: 15,
                  label: "Name of Degree",
                  type: "string",
                  required: true,
                  custom: true,
                  visible_in_bulk_update: true,
                  unique: false,
                  visible: false,
                  is_hidden: true,
                  visible_in_form: false,
                  read_only: false,
                  whatsapp_module_allowed_fields: false,
                  email_module_allowed_fields: false,
                  sms_module_allowed_fields: false,
                  id: "BVGHYFZr",
                  name: "name_of_degree",
                  placeholder: "Enter Name of Degree",
                  tooltip: "",
                  is_dependant: false,
                  dependant_field_id: null,
                  moduleId: "application",
                },
                {
                  id: "ACttH7tW",
                  label: "University",
                  name: "post_graduate_university",
                  original_label: "University",
                  orginal_name: "post_graduate_university",
                  type: "dropdown",
                  default_value: "",
                  placeholder: "-- Please Select --",
                  help_text: "",
                  required: true,
                  is_hidden: false,
                  regex_pattern: "",
                  moduleId: "application",
                },
                {
                  parent_id: "b6wtQTxZ",
                  position: 15,
                  label: "Name of University",
                  type: "string",
                  required: true,
                  custom: true,
                  visible_in_bulk_update: true,
                  unique: false,
                  visible: false,
                  is_hidden: true,
                  visible_in_form: false,
                  read_only: false,
                  whatsapp_module_allowed_fields: false,
                  email_module_allowed_fields: false,
                  sms_module_allowed_fields: false,
                  id: "Z2RscHeu",
                  name: "name_of_pg1_university",
                  placeholder: "Enter Name of University",
                  tooltip: "",
                  is_dependant: false,
                  dependant_field_id: null,
                  moduleId: "application",
                },
                {
                  parent_id: "b6wtQTxZ",
                  position: 17,
                  label: "Year of Completion",
                  type: "dropdown",
                  required: true,
                  custom: true,
                  visible_in_bulk_update: false,
                  unique: false,
                  visible: true,
                  visible_in_form: false,
                  read_only: false,
                  whatsapp_module_allowed_fields: false,
                  email_module_allowed_fields: false,
                  sms_module_allowed_fields: false,
                  id: "5yneqBm9",
                  name: "post_graduate_month_and_year_of_completion",
                  placeholder: "-- Please Select --",
                  tooltip: "",
                  is_dependant: false,
                  dependant_field_id: null,
                  moduleId: "application",
                  condition: true,
                  checkFieldId: "LU69fmp2",
                  action: "greaterThen",
                  errorMessage:
                    "Post Graduate Year of Completion should be greater then Under Graduate Year of Completion",
                },
                {
                  parent_id: "b6wtQTxZ",
                  position: 16,
                  label: "Marking Scheme",
                  type: "dropdown",
                  required: true,
                  custom: true,
                  visible_in_bulk_update: true,
                  unique: false,
                  visible: true,
                  visible_in_form: false,
                  read_only: false,
                  whatsapp_module_allowed_fields: false,
                  email_module_allowed_fields: false,
                  sms_module_allowed_fields: false,
                  id: "tBACempX",
                  name: "post_graduate_marking_scheme",
                  placeholder: "-- Please Select --",
                  tooltip: "",
                  is_dependant: false,
                  dependant_field_id: null,
                  moduleId: "application",
                },
                {
                  parent_id: "b6wtQTxZ",
                  position: 15,
                  label: "Total Marks Obtained/CGPA",
                  type: "string",
                  required: true,
                  custom: true,
                  visible_in_bulk_update: true,
                  unique: false,
                  visible: true,
                  visible_in_form: false,
                  read_only: false,
                  whatsapp_module_allowed_fields: false,
                  email_module_allowed_fields: false,
                  sms_module_allowed_fields: false,
                  id: "9kWyBrES",
                  name: "post_graduate_total_marks_obtained",
                  placeholder: "Enter Total Marks Obtained/CGPA",
                  tooltip: "",
                  is_dependant: false,
                  dependant_field_id: null,
                  condition: true,
                  checkFieldId: "heMFkSsx",
                  action: "lessThen",
                  errorMessage:
                    "Marks obtained is less then or equal to Maximum marks",
                },
                {
                  parent_id: "b6wtQTxZ",
                  position: 16,
                  label: "Total Maximum Marks/Scale",
                  type: "string",
                  required: true,
                  custom: true,
                  visible_in_bulk_update: true,
                  unique: false,
                  visible: true,
                  visible_in_form: false,
                  read_only: false,
                  whatsapp_module_allowed_fields: false,
                  email_module_allowed_fields: false,
                  sms_module_allowed_fields: false,
                  id: "heMFkSsx",
                  name: "post_graduate_country",
                  placeholder: "Enter Total Maximum Marks/Scale",
                  tooltip: "",
                  is_dependant: false,
                  dependant_field_id: null,
                  moduleId: "application",
                  condition: true,
                  checkFieldId: "9kWyBrES",
                  action: "greaterThen",
                  errorMessage: "Maximum Marks is greater then Marks obtained",
                },
                {
                  parent_id: "b6wtQTxZ",
                  position: 17,
                  label: "Max percentage/ Grade Point (e.g. 100.00 or 10.00)",
                  type: "string",
                  required: false,
                  custom: true,
                  visible_in_bulk_update: false,
                  unique: false,
                  visible: true,
                  visible_in_form: false,
                  read_only: true,
                  whatsapp_module_allowed_fields: false,
                  email_module_allowed_fields: false,
                  sms_module_allowed_fields: false,
                  id: "KuQGAPEW",
                  name: "post_graduate_total_percentage",
                  placeholder:
                    "Enter Max percentage/ Grade Point (e.g. 100.00 or 10.00)",
                  tooltip: "",
                  is_dependant: false,
                  dependant_field_id: null,
                  moduleId: "application",
                },
                {
                  position: 15,
                  is_deleted: false,
                  label: "Post Graduation Country",
                  type: "dropdown",
                  required: true,
                  is_hidden: false,
                  custom: false,
                  unique: false,
                  visible: true,
                  visible_in_form: true,
                  whatsapp_module_allowed_fields: true,
                  email_module_allowed_fields: true,
                  placeholder: "-- Please Select --",
                  sms_module_allowed_fields: true,
                  id: "MS9uRzm5",
                  name: "post_graduate_country",
                  onInit: "getAllCountry",
                  onChange: "getAllState",
                  moduleId: "contact",
                  option_mapping: {
                    id: "aeqDqdkE",
                    dependant_id: "b5xN8WMp",
                  },
                },
                {
                  parent_id: "9b8Jc3GG",
                  position: 8,
                  label: "Post Graduate State",
                  type: "dropdown",
                  required: true,
                  custom: false,
                  unique: false,
                  visible: true,
                  visible_in_form: true,
                  whatsapp_module_allowed_fields: true,
                  email_module_allowed_fields: true,
                  sms_module_allowed_fields: true,
                  placeholder: "-- Please Select --",
                  id: "b5xN8WMp",
                  name: "post_graduate_state",
                  original_label: "State Region",
                  original_name: "state_region",
                  onInit: "getAllState",
                  is_dependant: true,
                  moduleId: "contact",
                  dependant_field_id: "MS9uRzm5",
                  onChange: "getAllCity",
                  option_mapping: {
                    id: "aeqDqdkE",
                  },
                  choices: [],
                },
              ],
            },
            {
              lable: "row1",
              fields: [
                {
                  parent_id: "b6wtQTxZ",
                  position: 15,
                  label: "Do you have any other PG qualifications?",
                  type: "dropdown",
                  required: true,
                  custom: true,
                  visible_in_bulk_update: true,
                  unique: false,
                  visible: true,
                  visible_in_form: false,
                  read_only: false,
                  whatsapp_module_allowed_fields: false,
                  email_module_allowed_fields: false,
                  placeholder: "-- Please Select --",
                  sms_module_allowed_fields: false,
                  id: "UVGQF7rU",
                  name: "do_you_have_any_other_PG_qualifications",
                  tooltip: "",
                  is_dependant: false,
                  dependant_field_id: null,
                  moduleId: "application",
                },
              ],
            },
          ],
        },
      },
      {
        label: "Post Graduate Details ( Second )",
        id: "post_graduate_details_2",
        name: "post_graduate_details_2",
        is_allowed: true,
        rows: {
          childs: [
            {
              lable: "row1",
              fields: [
                {
                  parent_id: "b6wtQTxZ",
                  position: 15,
                  label: "Degree",
                  type: "dropdown",
                  required: true,
                  custom: true,
                  visible_in_bulk_update: true,
                  unique: false,
                  visible: true,
                  visible_in_form: false,
                  read_only: false,
                  whatsapp_module_allowed_fields: false,
                  email_module_allowed_fields: false,
                  placeholder: "-- Please Select --",
                  sms_module_allowed_fields: false,
                  id: "nDseBBtC",
                  name: "post_graduate_degree_2",
                  tooltip: "",
                  is_dependant: false,
                  dependant_field_id: null,
                  moduleId: "application",
                },
                {
                  parent_id: "b6wtQTxZ",
                  position: 15,
                  label: "Name of Degree",
                  type: "string",
                  required: true,
                  custom: true,
                  visible_in_bulk_update: true,
                  unique: false,
                  visible: false,
                  is_hidden: true,
                  visible_in_form: false,
                  read_only: false,
                  whatsapp_module_allowed_fields: false,
                  email_module_allowed_fields: false,
                  sms_module_allowed_fields: false,
                  id: "siavE8ra",
                  name: "name_of_degree_2",
                  placeholder: "Enter Name of Degree",
                  tooltip: "",
                  is_dependant: false,
                  dependant_field_id: null,
                  moduleId: "application",
                },
                {
                  id: "hNtwR3g3",
                  label: "University",
                  name: "post_graduate_university_2",
                  original_label: "University",
                  orginal_name: "post_graduate_university",
                  type: "dropdown",
                  default_value: "",
                  placeholder: "-- Please Select --",
                  help_text: "",
                  required: true,
                  is_hidden: false,
                  regex_pattern: "",
                  moduleId: "application",
                },
                {
                  parent_id: "b6wtQTxZ",
                  position: 15,
                  label: "Name of University",
                  type: "string",
                  required: true,
                  custom: true,
                  visible_in_bulk_update: true,
                  unique: false,
                  visible: false,
                  is_hidden: true,
                  visible_in_form: false,
                  read_only: false,
                  whatsapp_module_allowed_fields: false,
                  email_module_allowed_fields: false,
                  sms_module_allowed_fields: false,
                  id: "T3sgPVhq",
                  name: "name_of_pg2_university",
                  placeholder: "Enter Name of University",
                  tooltip: "",
                  is_dependant: false,
                  dependant_field_id: null,
                  moduleId: "application",
                },
                {
                  parent_id: "b6wtQTxZ",
                  position: 17,
                  label: "Year of Completion",
                  type: "dropdown",
                  required: true,
                  custom: true,
                  visible_in_bulk_update: false,
                  unique: false,
                  visible: true,
                  visible_in_form: false,
                  read_only: false,
                  whatsapp_module_allowed_fields: false,
                  email_module_allowed_fields: false,
                  sms_module_allowed_fields: false,
                  id: "NhH9Kwmq",
                  name: "post_graduate_month_and_year_of_completion_2",
                  placeholder: "-- Please Select --",
                  tooltip: "",
                  is_dependant: false,
                  dependant_field_id: null,
                  moduleId: "application",
                  checkFieldId: "5yneqBm9",
                  action: "notEqualTo",
                  condition: true,
                  errorMessage:
                    "Second PG Year of Completion should not be same as First PG Year of Completion",
                },
                {
                  parent_id: "b6wtQTxZ",
                  position: 16,
                  label: "Marking Scheme",
                  type: "dropdown",
                  required: true,
                  custom: true,
                  visible_in_bulk_update: true,
                  unique: false,
                  visible: true,
                  visible_in_form: false,
                  read_only: false,
                  whatsapp_module_allowed_fields: false,
                  email_module_allowed_fields: false,
                  sms_module_allowed_fields: false,
                  id: "PUQvtaoe",
                  name: "post_graduate_marking_scheme_2",
                  placeholder: "-- Please Select --",
                  tooltip: "",
                  is_dependant: false,
                  dependant_field_id: null,
                  moduleId: "application",
                },
                {
                  parent_id: "b6wtQTxZ",
                  position: 15,
                  label: "Total Marks Obtained/CGPA",
                  type: "string",
                  required: true,
                  custom: true,
                  visible_in_bulk_update: true,
                  unique: false,
                  visible: true,
                  visible_in_form: false,
                  read_only: false,
                  whatsapp_module_allowed_fields: false,
                  email_module_allowed_fields: false,
                  sms_module_allowed_fields: false,
                  id: "JxNidsnG",
                  name: "post_graduate_total_marks_obtained_2",
                  placeholder: "Enter Total Marks Obtained/CGPA",
                  tooltip: "",
                  is_dependant: false,
                  dependant_field_id: null,
                  moduleId: "application",
                  condition: true,
                  checkFieldId: "jiLDPAsG",
                  action: "lessThen",
                  errorMessage:
                    "Marks obtained is less then or equal to Maximum marks",
                },
                {
                  parent_id: "b6wtQTxZ",
                  position: 16,
                  label: "Total Maximum Marks/Scale",
                  type: "string",
                  required: true,
                  custom: true,
                  visible_in_bulk_update: true,
                  unique: false,
                  visible: true,
                  visible_in_form: false,
                  read_only: false,
                  whatsapp_module_allowed_fields: false,
                  email_module_allowed_fields: false,
                  sms_module_allowed_fields: false,
                  id: "jiLDPAsG",
                  name: "post_graduate_country_2",
                  placeholder: "Enter Total Maximum Marks/Scale",
                  tooltip: "",
                  is_dependant: false,
                  dependant_field_id: null,
                  moduleId: "application",
                  condition: true,
                  checkFieldId: "JxNidsnG",
                  action: "greaterThen",
                  errorMessage: "Maximum Marks is greater then Marks obtained",
                },
                {
                  parent_id: "b6wtQTxZ",
                  position: 17,
                  label: "Max percentage/ Grade Point (e.g. 100.00 or 10.00)",
                  type: "string",
                  required: false,
                  custom: true,
                  visible_in_bulk_update: false,
                  unique: false,
                  visible: true,
                  visible_in_form: false,
                  read_only: true,
                  whatsapp_module_allowed_fields: false,
                  email_module_allowed_fields: false,
                  sms_module_allowed_fields: false,
                  id: "JU5u4ZEZ",
                  name: "post_graduate_total_percentage_2",
                  placeholder:
                    "Enter Max percentage/ Grade Point (e.g. 100.00 or 10.00)",
                  tooltip: "",
                  is_dependant: false,
                  dependant_field_id: null,
                  moduleId: "application",
                },
                {
                  position: 15,
                  is_deleted: false,
                  label: "Post Graduation Country",
                  type: "dropdown",
                  required: true,
                  is_hidden: false,
                  custom: false,
                  unique: false,
                  visible: true,
                  visible_in_form: true,
                  whatsapp_module_allowed_fields: true,
                  email_module_allowed_fields: true,
                  sms_module_allowed_fields: true,
                  id: "aVwLUSvd",
                  name: "post_graduate_country_2",
                  placeholder: "-- Please Select --",
                  onInit: "getAllCountry",
                  onChange: "getAllState",
                  moduleId: "contact",
                  option_mapping: {
                    id: "aeqDqdkE",
                    dependant_id: "4w39drrY",
                  },
                },
                {
                  parent_id: "9b8Jc3GG",
                  position: 8,
                  label: "Post Graduate State",
                  type: "dropdown",
                  required: true,
                  custom: false,
                  unique: false,
                  visible: true,
                  visible_in_form: true,
                  whatsapp_module_allowed_fields: true,
                  email_module_allowed_fields: true,
                  sms_module_allowed_fields: true,
                  id: "4w39drrY",
                  name: "post_graduate_state_2",
                  original_label: "State Region",
                  original_name: "state_region",
                  placeholder: "-- Please Select --",
                  onInit: "getAllState",
                  is_dependant: true,
                  moduleId: "contact",
                  dependant_field_id: "aVwLUSvd",
                  onChange: "getAllCity",
                  option_mapping: {
                    id: "aeqDqdkE",
                  },
                  choices: [],
                },
              ],
            },
          ],
        },
      },
      {
        label: "Graduate Details",
        id: "Under Graduate Details",
        name: "under_graduate_details",
        is_allowed: true,
        rows: {
          childs: [
            {
              lable: "row1",
              fields: [
                {
                  parent_id: "b6wtQTxZ",
                  position: 15,
                  label: "Degree",
                  type: "dropdown",
                  required: true,
                  custom: true,
                  visible_in_bulk_update: true,
                  unique: false,
                  visible: true,
                  visible_in_form: false,
                  read_only: false,
                  whatsapp_module_allowed_fields: false,
                  email_module_allowed_fields: false,
                  sms_module_allowed_fields: false,
                  placeholder: "-- Please Select --",
                  id: "3uo78j4j",
                  name: "under_graduate_degree",
                  tooltip: "",
                  is_dependant: false,
                  dependant_field_id: null,
                  moduleId: "application",
                },
                {
                  parent_id: "b6wtQTxZ",
                  position: 15,
                  label: "Name of Degree",
                  type: "string",
                  required: true,
                  custom: true,
                  visible_in_bulk_update: true,
                  unique: false,
                  visible: true,
                  visible_in_form: false,
                  read_only: false,
                  is_hidden: true,
                  whatsapp_module_allowed_fields: false,
                  email_module_allowed_fields: false,
                  sms_module_allowed_fields: false,
                  id: "mByFCoJa",
                  name: "name_of_degree",
                  placeholder: "Enter Name of Degree",
                  tooltip: "",
                  is_dependant: false,
                  dependant_field_id: null,
                  moduleId: "application",
                },
                {
                  id: "VZoumipn",
                  label: "University",
                  name: "under_graduate_university",
                  original_label: "University",
                  orginal_name: "under_graduate_university",
                  type: "dropdown",
                  default_value: "",
                  placeholder: "-- Please Select --",
                  help_text: "",
                  required: true,
                  is_hidden: false,
                  regex_pattern: "",
                  moduleId: "application",
                },
                {
                  parent_id: "b6wtQTxZ",
                  position: 15,
                  label: "Name of University",
                  type: "string",
                  required: true,
                  custom: true,
                  visible_in_bulk_update: true,
                  unique: false,
                  visible: false,
                  is_hidden: true,
                  visible_in_form: false,
                  read_only: false,
                  whatsapp_module_allowed_fields: false,
                  email_module_allowed_fields: false,
                  sms_module_allowed_fields: false,
                  id: "fqTokGwD",
                  name: "name_of_ug_university",
                  placeholder: "Enter Name of University",
                  tooltip: "",
                  is_dependant: false,
                  dependant_field_id: null,
                  moduleId: "application",
                },
                {
                  parent_id: "b6wtQTxZ",
                  position: 17,
                  label: "Year of Completion",
                  type: "dropdown",
                  required: true,
                  custom: true,
                  visible_in_bulk_update: false,
                  unique: false,
                  visible: true,
                  visible_in_form: false,
                  read_only: false,
                  whatsapp_module_allowed_fields: false,
                  email_module_allowed_fields: false,
                  sms_module_allowed_fields: false,
                  id: "LU69fmp2",
                  name: "under_graduate_month_and_year_of_completion",
                  placeholder: "-- Please Select --",
                  tooltip: "",
                  is_dependant: false,
                  dependant_field_id: null,
                  moduleId: "application",
                  action: "lessThen",
                  condition: true,
                  checkFieldId: "5yneqBm9",
                  errorMessage:
                    "Under Graduate Year of Completion should be less then Post Graduate Year of Completion",
                },
                {
                  parent_id: "b6wtQTxZ",
                  position: 16,
                  label: "Marking Scheme",
                  type: "dropdown",
                  required: true,
                  custom: true,
                  visible_in_bulk_update: true,
                  unique: false,
                  visible: true,
                  visible_in_form: false,
                  read_only: false,
                  whatsapp_module_allowed_fields: false,
                  email_module_allowed_fields: false,
                  sms_module_allowed_fields: false,
                  id: "5eaUKFoH",
                  name: "under_graduate_marking_scheme",
                  placeholder: "-- Please Select --",
                  tooltip: "",
                  is_dependant: false,
                  dependant_field_id: null,
                  moduleId: "application",
                },
                {
                  parent_id: "b6wtQTxZ",
                  position: 15,
                  label: "Total Marks Obtained/CGPA",
                  type: "string",
                  required: true,
                  custom: true,
                  visible_in_bulk_update: true,
                  unique: false,
                  visible: true,
                  visible_in_form: false,
                  read_only: false,
                  whatsapp_module_allowed_fields: false,
                  email_module_allowed_fields: false,
                  sms_module_allowed_fields: false,
                  id: "fe9UtMUh",
                  name: "under_graduate_total_marks_obtained",
                  placeholder: "Enter Total Marks Obtained/CGPA",
                  tooltip: "",
                  is_dependant: false,
                  dependant_field_id: null,
                  moduleId: "application",
                  condition: true,
                  checkFieldId: "JAiA4cym",
                  action: "lessThen",
                  errorMessage:
                    "Marks obtained is less then or equal to Maximum marks",
                },
                {
                  parent_id: "b6wtQTxZ",
                  position: 16,
                  label: "Total Maximum Marks/Scale",
                  type: "string",
                  required: true,
                  custom: true,
                  visible_in_bulk_update: true,
                  unique: false,
                  visible: true,
                  visible_in_form: false,
                  read_only: false,
                  whatsapp_module_allowed_fields: false,
                  email_module_allowed_fields: false,
                  sms_module_allowed_fields: false,
                  id: "JAiA4cym",
                  name: "under_graduate_total_maximum_marks",
                  placeholder: "Enter Total Maximum Marks/Scale",
                  tooltip: "",
                  is_dependant: false,
                  dependant_field_id: null,
                  moduleId: "application",
                  condition: true,
                  checkFieldId: "fe9UtMUh",
                  action: "greaterThen",
                  errorMessage: "Maximum Marks is greater then Marks obtained",
                },
                {
                  parent_id: "b6wtQTxZ",
                  position: 17,
                  label: "Max percentage/ Grade Point (e.g. 100.00 or 10.00)",
                  type: "string",
                  required: false,
                  custom: true,
                  visible_in_bulk_update: false,
                  unique: false,
                  visible: true,
                  visible_in_form: false,
                  read_only: true,
                  whatsapp_module_allowed_fields: false,
                  email_module_allowed_fields: false,
                  sms_module_allowed_fields: false,
                  id: "YGvhtygJ",
                  name: "under_graduate_total_percentage",
                  placeholder:
                    "Enter Max percentage/ Grade Point (e.g. 100.00 or 10.00)",
                  tooltip: "",
                  is_dependant: false,
                  dependant_field_id: null,
                  moduleId: "application",
                },
                {
                  position: 15,
                  is_deleted: false,
                  label: "Country",
                  type: "dropdown",
                  required: true,
                  is_hidden: false,
                  custom: false,
                  unique: false,
                  placeholder: "-- Please Select --",
                  visible: true,
                  visible_in_form: true,
                  whatsapp_module_allowed_fields: true,
                  email_module_allowed_fields: true,
                  sms_module_allowed_fields: true,
                  id: "HfQGtpWU",
                  name: "post_graduate_country",
                  onInit: "getAllCountry",
                  onChange: "getAllState",
                  moduleId: "contact",
                  option_mapping: {
                    id: "aeqDqdkE",
                    dependant_id: "K8M5RrNi",
                  },
                },
                {
                  parent_id: "9b8Jc3GG",
                  position: 8,
                  label: "Under Graduate State",
                  type: "dropdown",
                  required: true,
                  custom: false,
                  unique: false,
                  visible: true,
                  visible_in_form: true,
                  whatsapp_module_allowed_fields: true,
                  email_module_allowed_fields: true,
                  sms_module_allowed_fields: true,
                  id: "K8M5RrNi",
                  name: "under_graduate_state",
                  original_label: "State Region",
                  placeholder: "-- Please Select --",
                  original_name: "state_region",
                  onInit: "getAllState",
                  is_dependant: true,
                  moduleId: "contact",
                  dependant_field_id: "HfQGtpWU",
                  onChange: "getAllCity",
                  option_mapping: {
                    id: "aeqDqdkE",
                  },
                  choices: [],
                },
              ],
            },
          ],
        },
      },
      {
        label:
          "Entrance Exam (Have you cleared any national examinations Like NET SET GATE & Any Fellowship Examination)",
        id: "entrance_exam",
        name: "entrance_exam",
        type: "slide",
        is_allowed: true,
        is_hidden: false,
        rows: {
          childs: [
            {
              lable: "row1",
              fields: [
                {
                  parent_id: "b6wtQTxZ",
                  position: 19,
                  label:
                    "Have You Cleared Any National Eligibility Examinations?",
                  type: "dropdown",
                  required: true,
                  custom: true,
                  visible_in_bulk_update: true,
                  unique: false,
                  visible: true,
                  placeholder: "-- Please Select --",
                  visible_in_form: false,
                  read_only: false,
                  whatsapp_module_allowed_fields: false,
                  email_module_allowed_fields: false,
                  sms_module_allowed_fields: false,
                  id: "RFBLQxYn",
                  name: "have_you_cleared_any_national_examinations",
                  tooltip: "",
                  is_dependant: false,
                  dependant_field_id: null,
                  moduleId: "application",
                },
              ],
            },
            {
              lable: "row1",
              fields: [
                {
                  parent_id: "b6wtQTxZ",
                  position: 19,
                  label: "Entrance Exam",
                  type: "dropdown",
                  required: true,
                  custom: true,
                  visible_in_bulk_update: true,
                  unique: false,
                  visible: true,
                  visible_in_form: false,
                  read_only: false,
                  whatsapp_module_allowed_fields: false,
                  email_module_allowed_fields: false,
                  placeholder: "-- Please Select --",
                  sms_module_allowed_fields: false,
                  id: "ncdNK2FW",
                  name: "entrance_exam_1_name",
                  tooltip: "",
                  is_dependant: false,
                  dependant_field_id: null,
                  is_hidden: true,
                  moduleId: "application",
                },
                {
                  parent_id: "b6wtQTxZ",
                  position: 19,
                  label: "Other Entrance Exam",
                  type: "string",
                  required: true,
                  custom: true,
                  visible_in_bulk_update: true,
                  unique: false,
                  visible: true,
                  visible_in_form: false,
                  read_only: false,
                  whatsapp_module_allowed_fields: false,
                  email_module_allowed_fields: false,
                  placeholder: "Enter Entrance Exam",
                  sms_module_allowed_fields: false,
                  id: "VxVXPQ7TQ",
                  name: "other_entrance_exam",
                  tooltip: "",
                  is_dependant: false,
                  dependant_field_id: null,
                  is_hidden: true,
                  moduleId: "application",
                },
                {
                  id: "XoKv4pbA",
                  label: "Register No",
                  name: "entrance_exam_1_register_no",
                  original_label: "First Name",
                  orginal_name: "first_name",
                  type: "text",
                  default_value: "",
                  placeholder: "Enter Register No",
                  help_text: "",
                  required: true,
                  is_hidden: true,
                  read_only: false,
                  regex_pattern: "",
                  moduleId: "application",
                },
                {
                  id: "E7Yf7YyX",
                  label: "Marks Obtained / Percentage",
                  name: "entrance_exam_1_marks_obtained_percentage",
                  original_label: "Middle Name",
                  orginal_name: "middle_name",
                  type: "text",
                  default_value: "",
                  placeholder: "Enter Marks Obtained / Percentage",
                  help_text: "",
                  required: false,
                  read_only: false,
                  is_hidden: true,
                  regex_pattern: "",
                  moduleId: "application",
                },
                {
                  id: "8e9BGo53",
                  label: "Maximum Marks / Percentage",
                  name: "entrance_exam_1_maximum_marks_percentage",
                  original_label: "Last Name",
                  orginal_name: "last_name",
                  type: "text",
                  default_value: "",
                  placeholder: "Enter Maximum Marks / Percentage",
                  help_text: "",
                  required: false,
                  is_hidden: true,
                  read_only: false,
                  regex_pattern: "",
                  moduleId: "application",
                },
              ],
            },
            {
              lable: "row2",
              fields: [
                {
                  id: "SCjhxahP",
                  label: "Month & Year of Passing / Appearing ",
                  name: "entrance_exam_1_month_&_year_of_passing_appearing",
                  original_label: "First Name",
                  orginal_name: "first_name",
                  type: "datetime",
                  default_value: "",
                  placeholder: "Enter Month & Year of Passing / Appearing",
                  help_text: "",
                  required: true,
                  is_hidden: true,
                  read_only: false,
                  regex_pattern: "",
                  moduleId: "application",
                },
                {
                  id: "KZ5upzZu",
                  label: "Overall Rank / Grade",
                  name: "entrance_exam_1_overall_rank_grade",
                  original_label: "First Name",
                  orginal_name: "first_name",
                  type: "string",
                  default_value: "",
                  placeholder: "Enter Overall Rank / Grade",
                  help_text: "",
                  required: false,
                  is_hidden: true,
                  read_only: false,
                  regex_pattern: "",
                  moduleId: "application",
                },
                {
                  id: "A6rRdDfTK",
                  label: "Percentage",
                  name: "entrance_exam_1_percentile",
                  original_label: "Middle Name",
                  orginal_name: "middle_name",
                  type: "text",
                  default_value: "",
                  placeholder: "Enter Percentile",
                  help_text: "",
                  required: false,
                  is_hidden: true,
                  read_only: false,
                  regex_pattern: "",
                  moduleId: "application",
                },
              ],
            },
          ],
        },
      },
      {
        label: "Fellowship Details",
        id: "Fellowship Details",
        name: "fellowship_details",
        is_allowed: true,
        rows: {
          childs: [
            {
              lable: "row1",
              fields: [
                {
                  id: "HXCpecem",
                  label: "Are you holding any fellowship?",
                  name: "are_you_holding_any_fellowship",
                  original_label: "faculty",
                  orginal_name: "faculty",
                  type: "dropdown",
                  default_value: "",
                  placeholder: "-- Please Select --",
                  help_text: "",
                  required: true,
                  is_hidden: false,
                  regex_pattern: "",
                  moduleId: "application",
                  choices: [],
                },
                {
                  id: "5yhkiBm9",
                  label: "Name of national fellowship",
                  name: "Name_of_the_fellowship",
                  original_label: "faculty",
                  orginal_name: "faculty",
                  type: "string",
                  default_value: "",
                  placeholder: "Enter Name of the fellowship",
                  help_text: "",
                  required: true,
                  is_hidden: true,
                  regex_pattern: "",
                  moduleId: "application",
                },
                {
                  parent_id: "b6wtQTxZ",
                  position: 17,
                  label: "Validity upto",
                  type: "datetime",
                  required: true,
                  custom: true,
                  visible_in_bulk_update: false,
                  unique: false,
                  visible: true,
                  visible_in_form: false,
                  read_only: false,
                  is_hidden: true,
                  whatsapp_module_allowed_fields: false,
                  email_module_allowed_fields: false,
                  sms_module_allowed_fields: false,
                  id: "5yhuqBm9",
                  name: "validity_upto",
                  placeholder: "Enter Validity upto",
                  tooltip: "",
                  is_dependant: false,
                  dependant_field_id: null,
                  moduleId: "application",
                  allowPastDate: false,
                  allowedFutureYears: 5,
                },
              ],
            },
          ],
        },
      },
      {
        label: "Proposed Research Idea",
        id: "proposed_research_idea",
        name: "fellowship_details",
        is_allowed: true,
        rows: {
          childs: [
            {
              lable: "row1",
              fields: [
                {
                  id: "CJAseg9m",
                  label: "Proposed Topic of The Research ",
                  name: "proposed_research_idea",
                  original_label: "proposed_research_idea",
                  orginal_name: "proposed_research_idea",
                  type: "string",
                  default_value: "",
                  placeholder: "Enter Proposed Research Idea",
                  help_text: "",
                  required: true,
                  is_hidden: false,
                  regex_pattern: "",
                  choices: [],
                  moduleId: "application",
                },
              ],
            },
          ],
        },
      },
    ],
  },
  {
    Label: "Faculty & Publications",
    id: "Faculty & Publications",
    name: "faculty_publications",
    type: "slide",
    onComplete: "facultypublicationsFunctionForSiu",
    is_allowed: true,
    child: [
      {
        label:
          "Particulars of publications 1 (Please attach additional sheet, if required)",
        id: "Under Graduate Details",
        name: "under_graduate_details",
        is_allowed: true,
        rows: {
          childs: [
            {
              lable: "row1",
              fields: [
                {
                  id: "",
                  label: "table",
                  name: "",
                  original_label: "Title",
                  orginal_name: "course",
                  type: "table",
                  config: [
                    {
                      value: "data",
                      id: "ug_semeter_1_details",
                      name: "details_of_international_publication_1",
                      is_hidden: false,
                      fields: [
                        {
                          label: "1",
                          name: "details_of_international_publication_1",
                          type: "string",
                          default_value: "1",
                          read_only: false,
                          required: false,
                          header_value:
                            "details_of_international_publication_1",
                          placeholder:
                            "Enter Details of International Publications",
                          id: "nnEvHVwy",
                          moduleId: "application",
                        },
                        {
                          label: "2",
                          name: "list_of_title_of_the_paper_book_1",
                          type: "string",
                          read_only: false,
                          required: false,
                          header_value: "list_of_title_of_the_paper_book_1",
                          id: "DRJCUpBx",
                          placeholder: "Enter List of Title of the Paper/Book",
                          moduleId: "application",
                        },
                        {
                          label: "3",
                          name: "list_of_name_of_the_journal__publisher_1",
                          type: "string",
                          read_only: false,
                          required: false,
                          header_value:
                            "list_of_name_of_the_journal__publisher_1",
                          id: "VC9UkKEq",
                          placeholder:
                            "Enter List of Name of the Journal / Publisher",
                          moduleId: "application",
                        },
                        {
                          label: "4",
                          name: "place_of_publication_1",
                          type: "string",
                          read_only: false,
                          required: false,
                          header_value: "place_of_publication_1",
                          id: "MWCFhvPm",
                          placeholder: "Enter Place of Publication",
                          moduleId: "application",
                        },
                        {
                          label: "5",
                          name: "year_of_publication_1",
                          type: "string",
                          read_only: false,
                          required: false,
                          header_value: "year_of_publication_1",
                          id: "SapTaqMU",
                          placeholder: "Enter Year of Publication",
                          moduleId: "application",
                        },
                        {
                          label: "6",
                          name: "issn_isbn_1",
                          type: "string",
                          read_only: false,
                          required: false,
                          header_value: "issn_isbn_1",
                          id: "9Kze2n2F",
                          placeholder: "Enter ISSN/ISBN",
                          moduleId: "application",
                        },
                      ],
                    },
                  ],
                  header_value: [
                    {
                      label: "Details of International Publications",
                      value: "details_of_international_publication_1",
                    },
                    {
                      label: "List of Title of the Paper/Book",
                      value: "list_of_title_of_the_paper_book_1",
                    },
                    {
                      label: "List of Name of the Journal / Publisher",
                      value: "list_of_name_of_the_journal__publisher_1",
                    },
                    {
                      label: "Place of Publication",
                      value: "place_of_publication_1",
                    },
                    {
                      label: "Year of Publication",
                      value: "year_of_publication_1",
                    },
                    {
                      label: "ISSN/ISBN",
                      value: "issn_isbn_1",
                    },
                  ],
                  default_value: "",
                  placeholder: "Institution Name",
                  help_text: "",
                  required: true,
                  is_hidden: false,
                  regex_pattern: "",
                },
              ],
            },
          ],
        },
      },
      {
        label:
          "Particulars of publications 2 (Please attach additional sheet, if required)",
        id: "Under Graduate Details",
        name: "under_graduate_details",
        is_allowed: true,
        rows: {
          childs: [
            {
              lable: "row1",
              fields: [
                {
                  id: "",
                  label: "table",
                  name: "",
                  original_label: "Title",
                  orginal_name: "course",
                  type: "table",
                  config: [
                    {
                      value: "data",
                      id: "ug_semeter_1_details",
                      name: "ug_semeter_1_details",
                      is_hidden: false,
                      fields: [
                        {
                          label: "1",
                          name: "details_of_international_publication_2",
                          type: "string",
                          default_value: "1",
                          read_only: false,
                          required: false,
                          header_value:
                            "details_of_international_publication_2",
                          id: "RXD2zxCV",
                          placeholder:
                            "Enter Details of International Publications",
                          moduleId: "application",
                        },
                        {
                          label: "2",
                          name: "list_of_title_of_the_paper_book_2",
                          type: "string",
                          read_only: false,
                          required: false,
                          header_value: "list_of_title_of_the_paper_book_2",
                          id: "VBGAEtun",
                          placeholder: "Enter List of Title of the Paper/Book",
                          moduleId: "application",
                        },
                        {
                          label: "3",
                          name: "list_of_name_of_the_journal__publisher_2",
                          type: "string",
                          read_only: false,
                          required: false,
                          header_value:
                            "list_of_name_of_the_journal__publisher_2",
                          placeholder:
                            "Enter List of Name of the Journal / Publisher",
                          id: "Fski8Zyg",
                          moduleId: "application",
                        },
                        {
                          label: "2",
                          name: "place_of_publication_2",
                          type: "string",
                          read_only: false,
                          required: false,
                          header_value: "place_of_publication_2",
                          id: "kxdBeFwY",
                          placeholder: "Enter Place of Publication",
                          moduleId: "application",
                        },
                        {
                          label: "2",
                          name: "year_of_publication_2",
                          type: "string",
                          read_only: false,
                          required: false,
                          header_value: "year_of_publication_2",
                          id: "FUE472nF",
                          placeholder: "Enter Year of Publication",
                          moduleId: "application",
                        },
                        {
                          label: "2",
                          name: "issn_isbn_2",
                          type: "string",
                          read_only: false,
                          required: false,
                          header_value: "issn_isbn_2",
                          id: "QZLBf7EA",
                          placeholder: "Enter ISSN/ISBN",
                          moduleId: "application",
                        },
                      ],
                    },
                  ],
                  header_value: [
                    {
                      label: "Details of International Publications",
                      value: "details_of_international_publication_2",
                    },
                    {
                      label: "List of Title of the Paper/Book",
                      value: "list_of_title_of_the_paper_book_2",
                    },
                    {
                      label: "List of Name of the Journal / Publisher",
                      value: "list_of_name_of_the_journal__publisher_2",
                    },
                    {
                      label: "Place of Publication",
                      value: "place_of_publication_2",
                    },
                    {
                      label: "Year of Publication",
                      value: "year_of_publication_2",
                    },
                    {
                      label: "ISSN/ISBN",
                      value: "issn_isbn_2",
                    },
                  ],
                  default_value: "",
                  placeholder: "Institution Name",
                  help_text: "",
                  required: true,
                  is_hidden: false,
                  regex_pattern: "",
                },
              ],
            },
          ],
        },
      },
      {
        label:
          "Particulars of publications 3 (Please attach additional sheet, if required)",
        id: "Under Graduate Details",
        name: "under_graduate_details",
        is_allowed: true,
        rows: {
          childs: [
            {
              lable: "row1",
              fields: [
                {
                  id: "",
                  label: "table",
                  name: "",
                  original_label: "Title",
                  orginal_name: "course",
                  type: "table",
                  config: [
                    {
                      value: "data",
                      id: "ug_semeter_1_details",
                      name: "ug_semeter_1_details",
                      is_hidden: false,
                      fields: [
                        {
                          label: "1",
                          name: "details_of_international_publication_3",
                          type: "string",
                          default_value: "1",
                          read_only: false,
                          required: false,
                          header_value:
                            "details_of_international_publication_3",
                          id: "sVtXuKki",
                          placeholder:
                            "Enter Details of International Publications",
                          moduleId: "application",
                        },
                        {
                          label: "2",
                          name: "list_of_title_of_the_paper_book_3",
                          type: "string",
                          read_only: false,
                          required: false,
                          header_value: "list_of_title_of_the_paper_book_3",
                          id: "ejMajFcn",
                          placeholder: "Enter List of Title of the Paper/Book",
                          moduleId: "application",
                        },
                        {
                          label: "3",
                          name: "list_of_name_of_the_journal__publisher_3",
                          type: "string",
                          read_only: false,
                          required: false,
                          header_value:
                            "list_of_name_of_the_journal__publisher_3",
                          id: "96zZE4G3",
                          placeholder:
                            "Enter List of Name of the Journal / Publisher",
                          moduleId: "application",
                        },
                        {
                          label: "4",
                          name: "place_of_publication_3",
                          type: "string",
                          read_only: false,
                          required: false,
                          header_value: "place_of_publication_3",
                          id: "56fi9kxx",
                          placeholder: "Enter Place of Publication",
                          moduleId: "application",
                        },
                        {
                          label: "5",
                          name: "year_of_publication_3",
                          type: "string",
                          read_only: false,
                          required: false,
                          header_value: "year_of_publication_3",
                          id: "DmhBAvvH",
                          placeholder: "Enter Year of Publication",
                          moduleId: "application",
                        },
                        {
                          label: "6",
                          name: "issn_isbn_3",
                          type: "string",
                          read_only: false,
                          required: false,
                          header_value: "issn_isbn_3",
                          id: "9zxNkgZC",
                          placeholder: "Enter ISSN/ISBN",
                          moduleId: "application",
                        },
                      ],
                    },
                  ],
                  header_value: [
                    {
                      label: "Details of International Publications",
                      value: "details_of_international_publication_3",
                    },
                    {
                      label: "List of Title of the Paper/Book",
                      value: "list_of_title_of_the_paper_book_3",
                    },
                    {
                      label: "List of Name of the Journal / Publisher",
                      value: "list_of_name_of_the_journal__publisher_3",
                    },
                    {
                      label: "Place of Publication",
                      value: "place_of_publication_3",
                    },
                    {
                      label: "Year of Publication",
                      value: "year_of_publication_3",
                    },
                    {
                      label: "ISSN/ISBN",
                      value: "issn_isbn_3",
                    },
                  ],
                  default_value: "",
                  placeholder: "Institution Name",
                  help_text: "",
                  required: true,
                  is_hidden: false,
                  regex_pattern: "",
                },
              ],
            },
          ],
        },
      },
      {
        label:
          "Particulars of publications 4 (Please attach additional sheet, if required)",
        id: "Under Graduate Details",
        name: "under_graduate_details",
        is_allowed: true,
        rows: {
          childs: [
            {
              lable: "row1",
              fields: [
                {
                  id: "",
                  label: "table",
                  name: "",
                  original_label: "Title",
                  orginal_name: "course",
                  type: "table",
                  config: [
                    {
                      value: "data",
                      id: "ug_semeter_1_details",
                      name: "ug_semeter_1_details",
                      is_hidden: false,
                      fields: [
                        {
                          label: "1",
                          name: "details_of_international_publication_4",
                          type: "string",
                          default_value: "1",
                          read_only: false,
                          required: false,
                          header_value:
                            "details_of_international_publication_4",
                          id: "6mfgGM8J",
                          placeholder:
                            "Enter Details of International Publications",
                          moduleId: "application",
                        },
                        {
                          label: "2",
                          name: "list_of_title_of_the_paper_book_4",
                          type: "string",
                          read_only: false,
                          required: false,
                          header_value: "list_of_title_of_the_paper_book_4",
                          id: "L7GTcAFE",
                          placeholder: "Enter List of Title of the Paper/Book",
                          moduleId: "application",
                        },
                        {
                          label: "3",
                          name: "list_of_name_of_the_journal__publisher_4",
                          type: "string",
                          read_only: false,
                          required: false,
                          header_value:
                            "list_of_name_of_the_journal__publisher_4",
                          id: "sycowjHh",
                          placeholder:
                            "Enter List of Name of the Journal / Publisher",
                          moduleId: "application",
                        },
                        {
                          label: "2",
                          name: "place_of_publication_4",
                          type: "string",
                          read_only: false,
                          required: false,
                          header_value: "place_of_publication_4",
                          id: "ZzDQgPps",
                          placeholder: "Enter Place of Publication",
                          moduleId: "application",
                        },
                        {
                          label: "2",
                          name: "year_of_publication_4",
                          type: "string",
                          read_only: false,
                          required: false,
                          header_value: "year_of_publication_4",
                          id: "h96A23Rc",
                          placeholder: "Enter Year of Publication",
                          moduleId: "application",
                        },
                        {
                          label: "2",
                          name: "issn_isbn_4",
                          type: "string",
                          read_only: false,
                          required: false,
                          header_value: "issn_isbn_4",
                          id: "tfXHMFwN",
                          placeholder: "Enter ISSN/ISBN",
                          moduleId: "application",
                        },
                      ],
                    },
                  ],
                  header_value: [
                    {
                      label: "Details of International Publications",
                      value: "details_of_international_publication_4",
                    },
                    {
                      label: "List of Title of the Paper/Book",
                      value: "list_of_title_of_the_paper_book_4",
                    },
                    {
                      label: "List of Name of the Journal / Publisher",
                      value: "list_of_name_of_the_journal__publisher_4",
                    },
                    {
                      label: "Place of Publication",
                      value: "place_of_publication_4",
                    },
                    {
                      label: "Year of Publication",
                      value: "year_of_publication_4",
                    },
                    {
                      label: "ISSN/ISBN",
                      value: "issn_isbn_4",
                    },
                  ],
                  default_value: "",
                  placeholder: "Institution Name",
                  help_text: "",
                  required: true,
                  is_hidden: false,
                  regex_pattern: "",
                },
              ],
            },
          ],
        },
      },
      {
        label:
          "Particulars of publications 5 (Please attach additional sheet, if required)",
        id: "Under Graduate Details",
        name: "under_graduate_details",
        is_allowed: true,
        rows: {
          childs: [
            {
              lable: "row1",
              fields: [
                {
                  id: "",
                  label: "table",
                  name: "",
                  original_label: "Title",
                  orginal_name: "course",
                  type: "table",
                  config: [
                    {
                      value: "data",
                      id: "ug_semeter_1_details",
                      name: "ug_semeter_1_details",
                      is_hidden: false,
                      fields: [
                        {
                          label: "1",
                          name: "details_of_international_publication_5",
                          type: "string",
                          default_value: "1",
                          read_only: false,
                          required: false,
                          header_value:
                            "details_of_international_publication_5",
                          id: "Siqi7LJU",
                          placeholder:
                            "Enter Details of International Publications",
                          moduleId: "application",
                        },
                        {
                          label: "2",
                          name: "list_of_title_of_the_paper_book_5",
                          type: "string",
                          read_only: false,
                          required: false,
                          header_value: "list_of_title_of_the_paper_book_5",
                          id: "j6y8Bumj",
                          placeholder: "Enter List of Title of the Paper/Book",
                          moduleId: "application",
                        },
                        {
                          label: "3",
                          name: "list_of_name_of_the_journal__publisher_5",
                          type: "string",
                          read_only: false,
                          required: false,
                          header_value:
                            "list_of_name_of_the_journal__publisher_5",
                          id: "UimSmusf",
                          placeholder:
                            "Enter List of Name of the Journal / Publisher",
                          moduleId: "application",
                        },
                        {
                          label: "4",
                          name: "place_of_publication_5",
                          type: "string",
                          read_only: false,
                          required: false,
                          header_value: "place_of_publication_5",
                          id: "WJhkDrLj",
                          placeholder: "Enter Place of Publication",
                          moduleId: "application",
                        },
                        {
                          label: "5",
                          name: "year_of_publication_5",
                          type: "string",
                          read_only: false,
                          required: false,
                          header_value: "year_of_publication_5",
                          id: "8efWRWum",
                          placeholder: "Enter Year of Publication",
                          moduleId: "application",
                        },
                        {
                          label: "6",
                          name: "issn_isbn_5",
                          type: "string",
                          read_only: false,
                          required: false,
                          header_value: "issn_isbn_5",
                          id: "JfLjxFc7",
                          placeholder: "Enter ISSN/ISBN",
                          moduleId: "application",
                        },
                      ],
                    },
                  ],
                  header_value: [
                    {
                      label: "Details of International Publications",
                      value: "details_of_international_publication_5",
                    },
                    {
                      label: "List of Title of the Paper/Book",
                      value: "list_of_title_of_the_paper_book_5",
                    },
                    {
                      label: "List of Name of the Journal / Publisher",
                      value: "list_of_name_of_the_journal__publisher_5",
                    },
                    {
                      label: "Place of Publication",
                      value: "place_of_publication_5",
                    },
                    {
                      label: "Year of Publication",
                      value: "year_of_publication_5",
                    },
                    {
                      label: "ISSN/ISBN",
                      value: "issn_isbn_5",
                    },
                  ],
                  default_value: "",
                  placeholder: "Institution Name",
                  help_text: "",
                  required: true,
                  is_hidden: false,
                  regex_pattern: "",
                },
              ],
            },
          ],
        },
      },
    ],
  },
  {
    Label: "Work Experience",
    id: "work_experience",
    name: "work_experience",
    type: "slide",
    is_allowed: true,
    child: [
      {
        label:
          "Details of executive work experience experience certificate needed",
        id: "Under Graduate Details",
        name: "under_graduate_details",
        is_allowed: true,
        rows: {
          childs: [
            {
              lable: "row1",
              fields: [
                {
                  id: "",
                  label: "table",
                  name: "",
                  original_label: "Title",
                  orginal_name: "course",
                  type: "table",
                  config: [
                    {
                      value: "data",
                      id: "work_experience",
                      name: "work_experience",
                      is_hidden: false,
                      fields: [
                        {
                          label: "1",
                          name: "",
                          type: "info",
                          default_value: "1",
                          read_only: false,
                          required: false,
                          header_value: "sr_no",
                          id: "",
                          placeholder: "Enter Sr No",
                          moduleId: "application",
                        },
                        {
                          label: "2",
                          name: "name_of_the_organization_1",
                          type: "string",
                          read_only: false,
                          required: false,
                          header_value: "name_of_the_organization",
                          id: "5tRhwPeb",
                          placeholder: "Enter Name of the Organization",
                          moduleId: "application",
                        },
                        {
                          label: "3",
                          name: "designation_1",
                          type: "string",
                          read_only: false,
                          required: false,
                          header_value: "designation",
                          id: "dd69XVw7",
                          placeholder: "Enter Designation",
                          moduleId: "application",
                        },
                        {
                          label: "4",
                          name: "from_date_1",
                          type: "datetime",
                          read_only: false,
                          required: false,
                          header_value: "from_date",
                          id: "xXCvKUJE",
                          placeholder: "Enter From Date",
                          moduleId: "application",
                        },
                        {
                          label: "5",
                          name: "to_date_1",
                          type: "datetime",
                          read_only: false,
                          required: false,
                          header_value: "to_date",
                          id: "Qk5Q9V2a",
                          placeholder: "Enter To Date",
                          moduleId: "application",
                        },
                        {
                          label: "6",
                          name: "total_experience_in_months_1",
                          type: "string",
                          read_only: true,
                          required: false,
                          header_value: "total_experience_in_months",
                          id: "WvMEJMHf",
                          placeholder: "",
                          moduleId: "application",
                        },
                      ],
                    },
                    {
                      value: "data",
                      id: "work_experience",
                      name: "work_experience",
                      is_hidden: false,
                      fields: [
                        {
                          label: "2",
                          name: "",
                          type: "info",
                          default_value: "2",
                          read_only: false,
                          required: false,
                          header_value: "sr_no",
                          id: "",
                          placeholder: "Enter Sr No",
                          moduleId: "application",
                        },
                        {
                          label: "2",
                          name: "name_of_the_organization_2",
                          type: "string",
                          read_only: false,
                          required: false,
                          header_value: "name_of_the_organization",
                          id: "JPUiRBCi",
                          placeholder: "Enter Name of the Organization",
                          moduleId: "application",
                        },
                        {
                          label: "3",
                          name: "designation_2",
                          type: "string",
                          read_only: false,
                          required: false,
                          header_value: "designation",
                          id: "rRqSuzCD",
                          placeholder: "Enter Designation",
                          moduleId: "application",
                        },
                        {
                          label: "4",
                          name: "from_date_2",
                          type: "datetime",
                          read_only: false,
                          required: false,
                          header_value: "from_date",
                          id: "6uXRuHJk",
                          placeholder: "Enter From Date",
                          moduleId: "application",
                        },
                        {
                          label: "5",
                          name: "to_date_2",
                          type: "datetime",
                          read_only: false,
                          required: false,
                          header_value: "to_date",
                          id: "YXx824fk",
                          placeholder: "Enter To Date",
                          moduleId: "application",
                        },
                        {
                          label: "6",
                          name: "total_experience_in_months_2",
                          type: "string",
                          read_only: true,
                          required: false,
                          header_value: "total_experience_in_months",
                          id: "YBVA8ZBD",
                          placeholder: "",
                          moduleId: "application",
                        },
                      ],
                    },
                    {
                      value: "data",
                      id: "work_experience",
                      name: "work_experience",
                      is_hidden: false,
                      fields: [
                        {
                          label: "3",
                          name: "",
                          type: "info",
                          default_value: "3",
                          read_only: false,
                          required: false,
                          header_value: "sr_no",
                          id: "",
                          placeholder: "Enter Sr No",
                          moduleId: "application",
                        },
                        {
                          label: "2",
                          name: "name_of_the_organization_3",
                          type: "string",
                          read_only: false,
                          required: false,
                          header_value: "name_of_the_organization",
                          id: "CXWMVpmH",
                          placeholder: "Enter Name of the Organization",
                          moduleId: "application",
                        },
                        {
                          label: "3",
                          name: "designation_3",
                          type: "string",
                          read_only: false,
                          required: false,
                          header_value: "designation",
                          id: "nkwUego5",
                          placeholder: "Enter Designation",
                          moduleId: "application",
                        },
                        {
                          label: "4",
                          name: "from_date_3",
                          type: "datetime",
                          read_only: false,
                          required: false,
                          header_value: "from_date",
                          id: "PVQniLRN",
                          placeholder: "Enter From Date",
                          moduleId: "application",
                        },
                        {
                          label: "5",
                          name: "to_date_3",
                          type: "datetime",
                          read_only: false,
                          required: false,
                          header_value: "to_date",
                          id: "DLLTXrwW",
                          placeholder: "Enter To Date",
                          moduleId: "application",
                        },
                        {
                          label: "6",
                          name: "total_experience_in_months_3",
                          type: "number",
                          read_only: true,
                          required: false,
                          header_value: "total_experience_in_months",
                          id: "Lf27AYVD",
                          placeholder: "",
                          moduleId: "application",
                        },
                      ],
                    },
                    {
                      value: "data",
                      id: "work_experience",
                      name: "work_experience",
                      is_hidden: false,
                      fields: [
                        {
                          label: "4",
                          name: "",
                          type: "info",
                          default_value: "4",
                          read_only: false,
                          required: false,
                          header_value: "sr_no",
                          id: "",
                          placeholder: "Enter Sr No",
                          moduleId: "application",
                        },
                        {
                          label: "2",
                          name: "name_of_the_organization_4",
                          type: "string",
                          read_only: false,
                          required: false,
                          header_value: "name_of_the_organization",
                          id: "oehiFLXX",
                          placeholder: "Enter Name of the Organization",
                          moduleId: "application",
                        },
                        {
                          label: "3",
                          name: "designation_4",
                          type: "string",
                          read_only: false,
                          required: false,
                          header_value: "designation",
                          id: "jfcDmpkD",
                          placeholder: "Enter Designation",
                          moduleId: "application",
                        },
                        {
                          label: "4",
                          name: "from_date_4",
                          type: "datetime",
                          read_only: false,
                          required: false,
                          header_value: "from_date",
                          id: "Z8m2QeSi",
                          placeholder: "Enter From Date",
                          moduleId: "application",
                        },
                        {
                          label: "5",
                          name: "to_date_4",
                          type: "datetime",
                          read_only: false,
                          required: false,
                          header_value: "to_date",
                          id: "RD6QGf66",
                          placeholder: "Enter To Date",
                          moduleId: "application",
                        },
                        {
                          label: "6",
                          name: "total_experience_in_months_4",
                          type: "string",
                          read_only: true,
                          required: false,
                          header_value: "total_experience_in_months",
                          id: "t7WX4m3C",
                          placeholder: "",
                          moduleId: "application",
                        },
                      ],
                    },
                    {
                      value: "data",
                      id: "work_experience",
                      name: "work_experience",
                      is_hidden: false,
                      fields: [
                        {
                          label: "5",
                          name: "",
                          type: "info",
                          default_value: "5",
                          read_only: false,
                          required: false,
                          header_value: "sr_no",
                          id: "",
                          placeholder: "Enter Sr No",
                          moduleId: "application",
                        },
                        {
                          label: "2",
                          name: "name_of_the_organization_5",
                          type: "string",
                          read_only: false,
                          required: false,
                          header_value: "name_of_the_organization",
                          id: "oW5nWfjo",
                          placeholder: "Enter Name of the Organization",
                          moduleId: "application",
                        },
                        {
                          label: "3",
                          name: "designation_5",
                          type: "string",
                          read_only: false,
                          required: false,
                          header_value: "designation",
                          id: "3MPXaNKQ",
                          placeholder: "Enter Designation",
                          moduleId: "application",
                        },
                        {
                          label: "4",
                          name: "from_date_5",
                          type: "datetime",
                          read_only: false,
                          required: false,
                          header_value: "from_date",
                          id: "GhzuZ8Rp",
                          placeholder: "Enter From Date",
                          moduleId: "application",
                        },
                        {
                          label: "5",
                          name: "to_date_5",
                          type: "datetime",
                          read_only: false,
                          required: false,
                          header_value: "to_date",
                          id: "mQMHrmaV",
                          placeholder: "Enter To Date",
                          moduleId: "application",
                        },
                        {
                          label: "6",
                          name: "total_experience_in_months_5",
                          type: "string",
                          read_only: true,
                          required: false,
                          header_value: "total_experience_in_months",
                          id: "dSCRbNkZ",
                          placeholder: "",
                          moduleId: "application",
                        },
                      ],
                    },
                  ],
                  header_value: [
                    {
                      label: "Sr.No",
                      value: "sr_no",
                    },
                    {
                      label: "Name of the Organization",
                      value: "name_of_the_organization",
                    },
                    {
                      label: "Designation",
                      value: "designation",
                    },
                    {
                      label: "From Date",
                      value: "from_date",
                    },
                    {
                      label: "To Date",
                      value: "to_date",
                    },
                    {
                      label: "Total Experience in Months",
                      value: "total_experience_in_months",
                    },
                  ],
                  default_value: "",
                  placeholder: "Institution Name",
                  help_text: "",
                  required: true,
                  is_hidden: false,
                  regex_pattern: "",
                },
              ],
            },
            {
              lable: "row2",
              fields: [
                {
                  id: "FnQOP76DN",
                  label: "Total Number of Experience in year",
                  name: "total_number_of_experience_in_year",
                  original_label: "Title",
                  orginal_name: "title",
                  type: "string",
                  default_value: "",
                  placeholder: "Total Number of Experience in year",
                  help_text: "",
                  required: false,
                  read_only: true,
                  is_hidden: false,
                  regex_pattern: "",
                },
              ],
            },
          ],
        },
      },
    ],
  },
  {
    Label: "Documents Upload",
    id: "documents_upload",
    name: "documents_upload",
    initFucntion: "symbiosisDocumentFn",
    child: [
      {
        label: "Documents Upload",
        name: "documents_upload",
        type: "slide",
        is_allowed: true,
        is_hidden: false,
        rows: {
          childs: [
            {
              lable: "row1",
              fields: [
                {
                  id: "FnWWPfDN",
                  label: "UG Academic Qualifications Certificate",
                  name: "ug_academic_qualifications_certificate",
                  original_label: "Title",
                  orginal_name: "title",
                  type: "file",
                  default_value: "",
                  placeholder: "Title",
                  help_text: "",
                  required: true,
                  read_only: false,
                  is_hidden: false,
                  regex_pattern: "",
                  choices: [],
                  allowedMediaTypes: ["application/pdf"],
                  displayText: "(ONLY .PDF FORMATS, UPTO 1.5 MB)",
                  allowedMediaSize: 1500000,
                },
                {
                  id: "FnWWP6drN",
                  label: "UG Academic Qualifications Mark Sheet",
                  name: "ug_academic_qualifications_mark_list",
                  original_label: "Title",
                  orginal_name: "title",
                  type: "file",
                  default_value: "",
                  placeholder: "Title",
                  help_text: "",
                  required: true,
                  read_only: false,
                  is_hidden: false,
                  regex_pattern: "",
                  choices: [],
                  allowedMediaTypes: ["application/pdf"],
                  displayText: "(ONLY .PDF FORMATS, UPTO 1.5 MB)",
                  allowedMediaSize: 1500000,
                },
                {
                  id: "bfxqNX7u",
                  label: "PG Academic Qualifications Certificate",
                  name: "pg_academic_qualifications_certificate",
                  original_label: "Title",
                  orginal_name: "title",
                  type: "file",
                  default_value: "",
                  placeholder: "Title",
                  help_text: "",
                  required: true,
                  read_only: false,
                  is_hidden: false,
                  regex_pattern: "",
                  choices: [],
                  allowedMediaTypes: ["application/pdf"],
                  displayText: "(ONLY .PDF FORMATS, UPTO 1.5 MB)",
                  allowedMediaSize: 1500000,
                },
                {
                  id: "FnVT5drN",
                  label: "PG Academic Qualifications Mark Sheet",
                  name: "pg_academic_qualifications_mark_list",
                  original_label: "Title",
                  orginal_name: "title",
                  type: "file",
                  default_value: "",
                  placeholder: "Title",
                  help_text: "",
                  required: true,
                  read_only: false,
                  is_hidden: false,
                  regex_pattern: "",
                  choices: [],
                  allowedMediaTypes: ["application/pdf"],
                  displayText: "(ONLY .PDF FORMATS, UPTO 1.5 MB)",
                  allowedMediaSize: 1500000,
                },
                {
                  id: "dgtqGT7u",
                  label: "Aadhar Card/ Passport Identity",
                  name: "adhar_card_passport_identity",
                  original_label: "Title",
                  orginal_name: "title",
                  type: "file",
                  default_value: "",
                  placeholder: "Title",
                  help_text: "",
                  required: false,
                  read_only: false,
                  is_hidden: false,
                  regex_pattern: "",
                  choices: [],
                  allowedMediaTypes: ["application/pdf"],
                  displayText: "(ONLY .PDF FORMATS, UPTO 1.5 MB)",
                  allowedMediaSize: 1500000,
                },
                {
                  id: "bzKdgMMo",
                  label: "Upload Work Experience Documents",
                  name: "upload_work_experience_documents",
                  original_label: "Title",
                  orginal_name: "title",
                  type: "file",
                  default_value: "",
                  placeholder: "Title",
                  help_text: "",
                  required: false,
                  read_only: false,
                  is_hidden: false,
                  regex_pattern: "",
                  choices: [],
                  allowedMediaTypes: ["application/pdf"],
                  displayText: "(ONLY .PDF FORMATS, UPTO 1.5 MB)",
                  allowedMediaSize: 1500000,
                },
                {
                  id: "dGtyj677u",
                  label: "NET/SET/GATE/Fellowship letters Certificate",
                  name: "NET_SET_GATE_Fellowship_letters_phd_Certificate",
                  original_label: "Title",
                  orginal_name: "title",
                  type: "file",
                  default_value: "",
                  placeholder: "Title",
                  help_text: "",
                  required: true,
                  read_only: false,
                  is_hidden: false,
                  regex_pattern: "",
                  choices: [],
                  allowedMediaTypes: ["application/pdf"],
                  displayText: "(ONLY .PDF FORMATS, UPTO 1.5 MB)",
                  allowedMediaSize: 1500000,
                },
                {
                  id: "7QuMXL9k",
                  label: "Proposed Research Area",
                  name: "proposed_research_idea",
                  original_label: "Title",
                  orginal_name: "title",
                  type: "file",
                  default_value: "",
                  placeholder: "Title",
                  help_text: "",
                  required: true,
                  read_only: false,
                  is_hidden: false,
                  regex_pattern: "",
                  choices: [],
                  allowedMediaTypes: ["application/pdf"],
                  displayText: "(ONLY .PDF FORMATS, UPTO 1.5 MB)",
                  allowedMediaSize: 1500000,
                },
                {
                  id: "TYqUyiHR",
                  label: "Applicant Photo Upload",
                  name: "applicant_photo_upload",
                  original_label: "Title",
                  orginal_name: "title",
                  type: "file",
                  default_value: "",
                  placeholder: "Title",
                  help_text: "",
                  required: true,
                  read_only: false,
                  is_hidden: false,
                  regex_pattern: "",
                  choices: [],
                  allowedMediaTypes: ["image/jpeg", "image/png"],
                  allowedMediaSize: 400000,
                  displayText:
                    "(ONLY .JPG OR .JPEG OR .PNG FORMATS, UPTO 400 KB)",
                },
              ],
            },
            {
              lable: "row1",
              fields: [
                {
                  id: "4eAPYZ5H",
                  label:
                    "Category Certificate (If International then upload passport)",
                  name: "category_certificate",
                  original_label: "Category Certificate",
                  orginal_name: "category_certificate",
                  type: "file",
                  default_value: "",
                  placeholder: "Please Upload Category",
                  help_text: "",
                  required: true,
                  read_only: false,
                  is_hidden: false,
                  regex_pattern: "",
                  choices: [],
                  allowedMediaTypes: ["application/pdf"],
                  allowedMediaSize: 1500000,
                  displayText: "(ONLY .PDF FORMATS, UPTO 1.5 MB)",
                  showConidtion: {
                    id: "H2z5teV8",
                    values: [
                      "mtDK9jig",
                      "mtDK6jig",
                      "mt3DK2jig",
                      "mt38K2jig",
                      "mcDK9jig",
                    ],
                  },
                },
                {
                  id: "4eFCT5Z5H",
                  label: "Fellowship Offer Letter",
                  name: "fellowship_offer_letter",
                  original_label: "Category Certificate",
                  orginal_name: "category_certificate",
                  type: "file",
                  default_value: "",
                  placeholder: "Please Upload Category",
                  help_text: "",
                  required: true,
                  read_only: false,
                  is_hidden: false,
                  regex_pattern: "",
                  choices: [],
                  allowedMediaTypes: ["application/pdf"],
                  displayText: "(ONLY .PDF FORMATS, UPTO 1.5 MB)",
                  allowedMediaSize: 1500000,
                },
              ],
            },
          ],
        },
      },
    ],
  },
  {
    Label: "List of Enclosures",
    id: "list_of_enclosures",
    name: "list_of_enclosures",
    child: [
      {
        label: "List of Enclosures",
        name: "list_of_enclosures",
        type: "slide",
        is_allowed: true,
        is_hidden: false,
        rows: {
          childs: [
            {
              lable: "row2",
              fields: [
                {
                  id: "QNm4C23h",
                  label: "Passport size (3.5 x 4.5 cms) Photographs",
                  name: "passport_size_photo",
                  original_label: "faculty",
                  orginal_name: "faculty",
                  type: "dropdown",
                  default_value: "",
                  placeholder: "-- Please Select --",
                  help_text: "",
                  required: true,
                  is_hidden: false,
                  regex_pattern: "",
                  moduleId: "application",
                  choices: [],
                },
                {
                  id: "KCCYWV6a",
                  label: "Copy of Category Certificate",
                  name: "copy_of_category_certificate",
                  original_label: "faculty",
                  orginal_name: "faculty",
                  type: "dropdown",
                  default_value: "",
                  placeholder: "-- Please Select --",
                  help_text: "",
                  required: true,
                  is_hidden: false,
                  regex_pattern: "",
                  moduleId: "application",
                  choices: [],
                },
                {
                  id: "gczLdRNW",
                  label: "Copy of Aadhar Card/Photo Identity",
                  name: "copy_of_aadhar_card/Photo_Identity",
                  original_label: "faculty",
                  orginal_name: "faculty",
                  type: "dropdown",
                  default_value: "",
                  placeholder: "-- Please Select --",
                  help_text: "",
                  required: false,
                  is_hidden: false,
                  regex_pattern: "",
                  moduleId: "application",
                  choices: [],
                },
                {
                  id: "NVstnWVF",
                  label: "Copy of Academic Qualifications Certificate",
                  name: "copy_of_academic_qualifications_certificate",
                  original_label: "faculty",
                  orginal_name: "faculty",
                  type: "dropdown",
                  default_value: "",
                  placeholder: "-- Please Select --",
                  help_text: "",
                  required: true,
                  is_hidden: false,
                  regex_pattern: "",
                  moduleId: "application",
                  choices: [],
                },
                {
                  id: "tmmctTT9",
                  label: "Teaching Experience Certificate",
                  name: "teaching_experience_certificate",
                  original_label: "faculty",
                  orginal_name: "faculty",
                  type: "dropdown",
                  default_value: "",
                  placeholder: "-- Please Select --",
                  help_text: "",
                  required: true,
                  is_hidden: false,
                  regex_pattern: "",
                  moduleId: "application",
                  choices: [],
                },
                {
                  id: "efLW3tVo",
                  label: "Work Experience Certificate",
                  name: "work_experience_certificate",
                  original_label: "faculty",
                  orginal_name: "faculty",
                  type: "dropdown",
                  default_value: "",
                  placeholder: "-- Please Select --",
                  help_text: "",
                  required: true,
                  is_hidden: false,
                  regex_pattern: "",
                  moduleId: "application",
                  choices: [],
                },
                {
                  id: "HnDT8UNX",
                  label: "Proposed Research Idea",
                  name: "proposed_research_idea",
                  original_label: "faculty",
                  orginal_name: "faculty",
                  type: "dropdown",
                  default_value: "",
                  placeholder: "-- Please Select --",
                  help_text: "",
                  required: true,
                  is_hidden: false,
                  regex_pattern: "",
                  moduleId: "application",
                  choices: [],
                },
              ],
            },
          ],
        },
      },
    ],
  },
  {
    Label: "Payment",
    id: "payment",
    name: "payment",
    child: [
      {
        label: "Payment",
        name: "payment",
        type: "slide",
        is_allowed: true,
        is_hidden: false,
        rows: {
          childs: [
            {
              lable: "row2",
              fields: [
                {
                  id: "",
                  label: "payment",
                  payment_section: true,
                  name: "",
                  original_label: "Title",
                  orginal_name: "course",
                  type: "table",
                  config: [
                    {
                      value: "data",
                      id: "pg_semeter_1_details",
                      name: "pg_semeter_1_details",
                      is_hidden: false,
                      fields: [
                        {
                          label: "1",
                          name: "Ph.D",
                          type: "info",
                          value: "Ph.D",
                          default_value: "Ph.D",
                          read_only: true,
                          required: false,
                          header_value: "course",
                          id: "Yg5fG7gT",
                          moduleId: "application",
                        },
                        {
                          label: "2",
                          name: "application_fee",
                          type: "info",
                          read_only: false,
                          required: true,
                          header_value: "application_fees",
                          id: "TkkJgNxq",
                          moduleId: "application",
                        },
                        {
                          label: "1",
                          name: "payment_status",
                          type: "info",
                          value: "Ph.D",
                          read_only: true,
                          required: false,
                          header_value: "payment_status",
                          id: "Yg5fG7gT",
                          moduleId: "application",
                        },
                      ],
                    },
                  ],
                  header_value: [
                    {
                      label: "Course",
                      value: "course",
                    },
                    {
                      label: "Application Fees",
                      value: "application_fees",
                    },
                    {
                      label: "Payment Status",
                      value: "payment_status",
                    },
                  ],
                  default_value: "",
                  placeholder: "Institution Name",
                  help_text: "",
                  required: true,
                  is_hidden: false,
                  regex_pattern: "",
                },
              ],
            },
          ],
        },
      },
    ],
  },
];

export let rule = [
  {
    condition: {
      action: "any",
      rule: [
        {
          name: "XvcimGmV",
          otherSectionName: "allow_resubmission",
          condition: "is",
          isNotInPresntSection: true,
          value: true,
        },
      ],
    },
    executeCondition: [
      {
        condition: "readOnly",
        name: "XvcimGmV",
        type: "field",
      },
      {
        condition: "readOnly",
        name: "AsdfxG8e",
        type: "field",
      },
      {
        condition: "readOnly",
        name: "H2z5teV8",
        type: "field",
      },
    ],
  },

  // {
  //   condition: {
  //     action: "any",
  //     rule: [
  //       {
  //         name: "QrZkktjr",
  //         otherSectionName: "mqp3DyoA",
  //         condition: "is not",
  //         isNotInPresntSection: true,
  //         value: "L2HHzD2p",
  //       }
  //     ],
  //   },
  //   executeCondition: [
  //     {
  //       condition: "hide",
  //       name: "QrZkktjr",
  //       type: "field",
  //     },
  //   ],
  // },

  // {
  //   condition: {
  //     action: "any",
  //     rule: [
  //       {
  //         name: "QrZkktjr",
  //         otherSectionName: "mqp3DyoA",
  //         condition: "is not",
  //         isNotInPresntSection: true,
  //         value: "hinhv58W",
  //       },
  //     ],
  //   },
  //   executeCondition: [
  //     {
  //       condition: "hide",
  //       name: "QrZkktjr",
  //       type: "field",
  //     },
  //   ],
  // },
  // {
  //   condition: {
  //     action: "any",
  //     rule: [
  //       {
  //         name: "KuQGAPEW",
  //         otherSectionName: "allow_resubmission",
  //         condition: "is",
  //         isNotInPresntSection: true,
  //         value: true,
  //       },
  //     ],
  //   },
  //   executeCondition: [
  //     {
  //       condition: "readOnly",
  //       name: "RFBLQxYn",
  //       type: "field",
  //     },
  //   ],
  // },
  {
    condition: {
      action: "any",
      rule: [
        {
          name: "HXCpecem",
          condition: "is",
          value: "sDrLVecJ",
        },
      ],
    },
    executeCondition: [
      {
        condition: "show",
        name: "5yhkiBm9",
        type: "field",
      },
      {
        condition: "show",
        name: "5yhuqBm9",
        type: "field",
      },
    ],
  },
  {
    condition: {
      action: "any",
      rule: [
        {
          name: "HXCpecem",
          condition: "is not",
          value: "sDrLVecJ",
        },
      ],
    },
    executeCondition: [
      {
        condition: "hide",
        name: "5yhkiBm9",
        type: "field",
      },
      {
        condition: "hide",
        name: "5yhuqBm9",
        type: "field",
      },
    ],
  },

  // pre fill
  {
    condition: {
      action: "assignValue",
      rule: [
        {
          name: "VxjNaCtTQ",
          value: true,
        },
      ],
    },
    executeCondition: [
      // {
      //   name: "permanent_address_country",
      //   value: "communication_address_country",
      //   type: "option_data"
      // },
      // {
      //   name: "permanent_address_state",
      //   value: "communication_address_state",
      //   type: "option_data"
      // },
      // {
      //   name: "permanent_address_city",
      //   value: "communication_address_city",
      //   type: "option_data"
      // },
      {
        name: "PiCnD3xK",
        value: "EHEfqzZ6",
      },
      {
        name: "JDq8Wz3b",
        value: "8eosKDRN",
      },
      {
        name: "868pjYVj",
        value: "ToUP4vjt",
      },
      {
        name: "XkAYFV8j",
        value: "gcUhDZTS",
      },
      {
        name: "XkAFTY8j",
        value: "XkABJH8j",
      },
      {
        name: "XkJKLY8j",
        value: "XkCKWY8j",
      },
    ],
  },

  // new structure field
  {
    condition: {
      action: "percentage",
      field_details: [
        {
          name: "KuQGAPEW",
        },
      ],
    },
    executeCondition: [
      {
        name: "9kWyBrES",
        isMaxMark: false,
      },
      {
        name: "heMFkSsx",
        isMaxMark: true,
      },
    ],
  },

  {
    condition: {
      action: "percentage",
      field_details: [
        {
          name: "JU5u4ZEZ",
        },
      ],
    },
    executeCondition: [
      {
        name: "JxNidsnG",
        isMaxMark: false,
      },
      {
        name: "jiLDPAsG",
        isMaxMark: true,
      },
    ],
  },

  {
    condition: {
      action: "percentage",
      field_details: [
        {
          name: "YGvhtygJ",
        },
      ],
    },
    executeCondition: [
      {
        name: "fe9UtMUh",
        isMaxMark: false,
      },
      {
        name: "JAiA4cym",
        isMaxMark: true,
      },
    ],
  },

  {
    condition: {
      action: "any",
      rule: [
        {
          name: "5eaUKFoH",
          condition: "is",
          value: "nSbFENRB",
        },
      ],
    },
    executeCondition: [
      {
        condition: "hide",
        name: "YGvhtygJ",
        type: "field",
      },
    ],
  },

  {
    condition: {
      action: "any",
      rule: [
        {
          name: "5eaUKFoH",
          condition: "is",
          value: "oBfkxTnC",
        },
      ],
    },
    executeCondition: [
      {
        condition: "show",
        name: "YGvhtygJ",
        type: "field",
      },
    ],
  },

  ///

  {
    condition: {
      action: "any",
      rule: [
        {
          name: "PUQvtaoe",
          condition: "is",
          value: "qGjAuEnW",
        },
      ],
    },
    executeCondition: [
      {
        condition: "hide",
        name: "JU5u4ZEZ",
        type: "field",
      },
    ],
  },

  {
    condition: {
      action: "any",
      rule: [
        {
          name: "PUQvtaoe",
          condition: "is",
          value: "j9eVQXgD",
        },
      ],
    },
    executeCondition: [
      {
        condition: "show",
        name: "JU5u4ZEZ",
        type: "field",
      },
    ],
  },

  //

  {
    condition: {
      action: "any",
      rule: [
        {
          name: "tBACempX",
          condition: "is",
          value: "t6quWq4Q",
        },
      ],
    },
    executeCondition: [
      {
        condition: "hide",
        name: "KuQGAPEW",
        type: "field",
      },
    ],
  },

  {
    condition: {
      action: "any",
      rule: [
        {
          name: "tBACempX",
          condition: "is",
          value: "8HWLUeht",
        },
      ],
    },
    executeCondition: [
      {
        condition: "show",
        name: "KuQGAPEW",
        type: "field",
      },
    ],
  },

  {
    condition: {
      action: "any",
      rule: [
        {
          name: "mqp3DyoA",
          condition: "is",
          value: "L2HHzD2p",
        },
      ],
    },
    executeCondition: [
      {
        condition: "show",
        name: "QrZkktjr",
        type: "field",
      },
    ],
  },
  {
    condition: {
      action: "any",
      rule: [
        {
          name: "mqp3DyoA",
          condition: "is",
          value: "hinhv58W",
        },
      ],
    },
    executeCondition: [
      {
        condition: "show",
        name: "QrZkktjr",
        type: "field",
      },
    ],
  },
  {
    condition: {
      action: "any",
      rule: [
        {
          name: "mqp3DyoA",
          condition: "is",
          value: "hinhv58W",
        },
      ],
    },
    executeCondition: [
      {
        condition: "show",
        name: "QrZkktjr",
        type: "field",
      },
    ],
  },
  // {
  //   condition: {
  //     action: "any",
  //     rule: [
  //       {
  //         name: "mqp3DyoA",
  //         condition: "is",
  //         value: "mDhhxcfk",
  //       },
  //     ],
  //   },
  //   executeCondition: [
  //     {
  //       condition: "hide",
  //       name: "9ybpTdKH",
  //       type: "field",
  //     },
  //     {
  //       condition: "hide",
  //       name: "QrZkktjr",
  //       type: "field",
  //     }
  //   ],
  // },

  {
    condition: {
      action: "any",
      rule: [
        {
          name: "mqp3DyoA",
          condition: "is",
          value: "mDhhxcfk",
        },
      ],
    },
    executeCondition: [
      {
        condition: "hide",
        name: "QrZkktjr",
        type: "field",
      },
    ],
  },
  {
    condition: {
      action: "any",
      rule: [
        {
          name: "mqp3DyoA",
          condition: "is",
          value: "69J6gmDs",
        },
      ],
    },
    executeCondition: [
      {
        condition: "hide",
        name: "QrZkktjr",
        type: "field",
      },
    ],
  },
  {
    condition: {
      action: "any",
      rule: [
        {
          name: "mqp3DyoA",
          condition: "is",
          value: "A5b3QLMP",
        },
      ],
    },
    executeCondition: [
      {
        condition: "hide",
        name: "QrZkktjr",
        type: "field",
      },
    ],
  },
  {
    condition: {
      action: "any",
      rule: [
        {
          name: "mqp3DyoA",
          condition: "is",
          value: "gfjcdaUy",
        },
      ],
    },
    executeCondition: [
      {
        condition: "hide",
        name: "QrZkktjr",
        type: "field",
      },
    ],
  },
  {
    condition: {
      action: "any",
      rule: [
        {
          name: "mqp3DyoA",
          condition: "is",
          value: "pRinfa9P",
        },
      ],
    },
    executeCondition: [
      {
        condition: "hide",
        name: "QrZkktjr",
        type: "field",
      },
    ],
  },
  {
    condition: {
      action: "monthCalculator",
      field_details: [
        {
          name: "WvMEJMHf",
        },
      ],
    },
    executeCondition: [
      {
        name: "xXCvKUJE",
        type: "table_field",
      },
      {
        name: "Qk5Q9V2a",
        type: "table_field",
      },
    ],
  },
  {
    condition: {
      action: "monthCalculator",
      field_details: [
        {
          name: "YBVA8ZBD",
        },
      ],
    },
    executeCondition: [
      {
        name: "6uXRuHJk",
        type: "table_field",
      },
      {
        name: "YXx824fk",
        type: "table_field",
      },
    ],
  },
  {
    condition: {
      action: "monthCalculator",
      field_details: [
        {
          name: "Lf27AYVD",
        },
      ],
    },
    executeCondition: [
      {
        name: "PVQniLRN",
        type: "table_field",
      },
      {
        name: "DLLTXrwW",
        type: "table_field",
      },
    ],
  },
  {
    condition: {
      action: "monthCalculator",
      field_details: [
        {
          name: "t7WX4m3C",
        },
      ],
    },
    executeCondition: [
      {
        name: "Z8m2QeSi",
        type: "table_field",
      },
      {
        name: "RD6QGf66",
        type: "table_field",
      },
    ],
  },
  {
    condition: {
      action: "monthCalculator",
      field_details: [
        {
          name: "dSCRbNkZ",
        },
      ],
    },
    executeCondition: [
      {
        name: "GhzuZ8Rp",
        type: "table_field",
      },
      {
        name: "mQMHrmaV",
        type: "table_field",
      },
    ],
  },

  {
    condition: {
      action: "any",
      rule: [
        {
          name: "XEZSRXLs",
          condition: "is",
          value: "arAzW89b",
        },
      ],
    },
    executeCondition: [
      {
        condition: "show",
        name: "BVGHYFZr",
        type: "field",
      },
    ],
  },

  {
    condition: {
      action: "any",
      rule: [
        {
          name: "XEZSRXLs",
          condition: "is not",
          value: "arAzW89b",
        },
      ],
    },
    executeCondition: [
      {
        condition: "hide",
        name: "BVGHYFZr",
        type: "field",
      },
    ],
  },
  // Other Degree Rule PG-1
  {
    condition: {
      action: "any",
      rule: [
        {
          name: "ACttH7tW",
          condition: "is",
          value: "5vhyPQGH",
        },
      ],
    },
    executeCondition: [
      {
        condition: "show",
        name: "Z2RscHeu",
        type: "field",
      },
    ],
  },
  {
    condition: {
      action: "any",
      rule: [
        {
          name: "ACttH7tW",
          condition: "is not",
          value: "5vhyPQGH",
        },
      ],
    },
    executeCondition: [
      {
        condition: "hide",
        name: "Z2RscHeu",
        type: "field",
      },
    ],
  },

  // Rule for Other Degree PG-2
  {
    condition: {
      action: "any",
      rule: [
        {
          name: "hNtwR3g3",
          condition: "is",
          value: "Pujkdfe9",
        },
      ],
    },
    executeCondition: [
      {
        condition: "show",
        name: "T3sgPVhq",
        type: "field",
      },
    ],
  },
  {
    condition: {
      action: "any",
      rule: [
        {
          name: "hNtwR3g3",
          condition: "is not",
          value: "Pujkdfe9",
        },
      ],
    },
    executeCondition: [
      {
        condition: "hide",
        name: "T3sgPVhq",
        type: "field",
      },
    ],
  },

  // Rule for Graduation Degree UG
  {
    condition: {
      action: "any",
      rule: [
        {
          name: "VZoumipn",
          condition: "is",
          value: "AB22JkrC",
        },
      ],
    },
    executeCondition: [
      {
        condition: "show",
        name: "fqTokGwD",
        type: "field",
      },
    ],
  },
  {
    condition: {
      action: "any",
      rule: [
        {
          name: "VZoumipn",
          condition: "is not",
          value: "AB22JkrC",
        },
      ],
    },
    executeCondition: [
      {
        condition: "hide",
        name: "fqTokGwD",
        type: "field",
      },
    ],
  },
  {
    condition: {
      action: "any",
      rule: [
        {
          name: "XEZSRXLs",
          condition: "is not",
          value: "arAzW89b",
        },
      ],
    },
    executeCondition: [
      {
        condition: "hide",
        name: "BVGHYFZr",
        type: "field",
      },
    ],
  },

  {
    condition: {
      action: "any",
      rule: [
        {
          name: "3uo78j4j",
          condition: "is",
          value: "73xVg1p3",
        },
      ],
    },
    executeCondition: [
      {
        condition: "show",
        name: "mByFCoJa",
        type: "field",
      },
    ],
  },

  {
    condition: {
      action: "any",
      rule: [
        {
          name: "3uo78j4j",
          condition: "is not",
          value: "73xVg1p3",
        },
      ],
    },
    executeCondition: [
      {
        condition: "hide",
        name: "mByFCoJa",
        type: "field",
      },
    ],
  },
  //rule for adhar field
  {
    condition: {
      action: "any",
      rule: [
        {
          name: "AsdfxG8e",
          condition: "is",
          value: "VGZwFRNY",
        },
      ],
    },
    executeCondition: [
      {
        condition: "show",
        name: "VxjkTbTQ",
        type: "field",
      },
      {
        condition: "hide",
        name: "YHpx5JFX",
        type: "field",
      },
      {
        condition: "optionHide",
        name: "category", // If other Constituent of Symbiosis, please specify
        type: ["mt38K2jig"],
      },
    ],
  },
  {
    condition: {
      action: "any",
      rule: [
        {
          name: "AsdfxG8e",
          condition: "is not",
          value: "VGZwFRNY",
        },
      ],
    },
    executeCondition: [
      {
        condition: "hide",
        name: "VxjkTbTQ",
        type: "field",
      },
      {
        condition: "show",
        name: "YHpx5JFX",
        type: "field",
      },
      {
        condition: "optionHide",
        name: "category", // If other Constituent of Symbiosis, please specify
        type: ["mtDK4jig", "mtDK9jig", "mtDK6jig", "mcDK9jig", "mt3DK2jig"],
      },
      // {
      //   condition: "optionHide",
      //   name: "category", // If other Constituent of Symbiosis, please specify
      //   type: [],
      // },
    ],
  },

  //rule for adhar field
  {
    condition: {
      action: "any",
      rule: [
        {
          name: "AsdfxG8e",
          condition: "is",
          value: "VGZwFRNY",
        },
      ],
    },
    executeCondition: [
      {
        condition: "show",
        name: "VxjkTbTQ",
        type: "field",
      },
      {
        condition: "hide",
        name: "YHpx5JFX",
        type: "field",
      },
    ],
  },
  {
    condition: {
      action: "any",
      rule: [
        {
          name: "AsdfxG8e",
          condition: "is not",
          value: "VGZwFRNY",
        },
      ],
    },
    executeCondition: [
      {
        condition: "hide",
        name: "VxjkTbTQ",
        type: "field",
      },
      {
        condition: "show",
        name: "YHpx5JFX",
        type: "field",
      },
    ],
  },

  // Other Entrance Exam
  {
    condition: {
      action: "any",
      rule: [
        {
          name: "ncdNK2FW",
          condition: "is",
          value: "WcUZ9JAH", // Other
        },
      ],
    },
    executeCondition: [
      {
        condition: "show",
        name: "VxVXPQ7TQ", // If other Constituent of Symbiosis, please specify
        type: "field",
      },
    ],
  },

  {
    condition: {
      action: "any",
      rule: [
        {
          name: "ncdNK2FW",
          condition: "is not",
          value: "WcUZ9JAH", // Other
        },
      ],
    },
    executeCondition: [
      {
        condition: "hide",
        name: "VxVXPQ7TQ", // If other Constituent of Symbiosis, please specify
        type: "field",
      },
    ],
  },
  //Rule for National Examinations YES
  {
    condition: {
      action: "any",
      rule: [
        {
          name: "RFBLQxYn",
          condition: "is",
          value: "cx6EABqF",
        },
      ],
    },
    executeCondition: [
      {
        condition: "show",
        name: "ncdNK2FW",
        type: "field",
      },
      {
        condition: "show",
        name: "VxVXPQ7TQ",
        type: "field",
      },
      {
        condition: "show",
        name: "XoKv4pbA",
        type: "field",
      },
      {
        condition: "show",
        name: "E7Yf7YyX",
        type: "field",
      },
      {
        condition: "show",
        name: "8e9BGo53",
        type: "field",
      },
      {
        condition: "show",
        name: "KZ5upzZu",
        type: "field",
      },
      {
        condition: "show",
        name: "A6rRdDfTK",
        type: "field",
      },
      {
        condition: "show",
        name: "SCjhxahP",
        type: "field",
      },
    ],
  },

  //Rule for National Examinations NO
  {
    condition: {
      action: "any",
      rule: [
        {
          name: "RFBLQxYn",
          condition: "is not",
          value: "cx6EABqF",
        },
      ],
    },
    executeCondition: [
      {
        condition: "hide",
        name: "ncdNK2FW",
        type: "field",
      },
      {
        condition: "hide",
        name: "VxVXPQ7TQ",
        type: "field",
      },
      {
        condition: "hide",
        name: "XoKv4pbA",
        type: "field",
      },
      {
        condition: "hide",
        name: "E7Yf7YyX",
        type: "field",
      },
      {
        condition: "hide",
        name: "8e9BGo53",
        type: "field",
      },
      {
        condition: "hide",
        name: "KZ5upzZu",
        type: "field",
      },
      {
        condition: "hide",
        name: "A6rRdDfTK",
        type: "field",
      },
      {
        condition: "hide",
        name: "SCjhxahP",
        type: "field",
      },
    ],
  },

  // Rule for any other pg qualification
  {
    condition: {
      action: "any",
      rule: [
        {
          name: "UVGQF7rU",
          condition: "is not",
          value: "Q5AnXSYn",
        },
      ],
    },
    executeCondition: [
      {
        condition: "hide",
        name: "nDseBBtC",
        type: "field",
      },
      {
        condition: "hide",
        name: "siavE8ra",
        type: "field",
      },
      {
        condition: "hide",
        name: "hNtwR3g3",
        type: "field",
      },
      {
        condition: "hide",
        name: "NhH9Kwmq",
        type: "field",
      },
      {
        condition: "hide",
        name: "PUQvtaoe",
        type: "field",
      },
      {
        condition: "hide",
        name: "JxNidsnG",
        type: "field",
      },
      {
        condition: "hide",
        name: "jiLDPAsG",
        type: "field",
      },
      {
        condition: "hide",
        name: "JU5u4ZEZ",
        type: "field",
      },
      {
        condition: "hide",
        name: "aVwLUSvd",
        type: "field",
      },
      {
        condition: "hide",
        name: "4w39drrY",
        type: "field",
      },
      {
        condition: "hide",
        name: "post_graduate_details_2",
        type: "section",
      },
    ],
  },

  // Rule for any other pg qualification
  {
    condition: {
      action: "any",
      rule: [
        {
          name: "UVGQF7rU",
          condition: "is",
          value: "Q5AnXSYn",
        },
      ],
    },
    executeCondition: [
      {
        condition: "show",
        name: "nDseBBtC",
        type: "field",
      },
      {
        condition: "show",
        name: "siavE8ra",
        type: "field",
      },
      {
        condition: "show",
        name: "hNtwR3g3",
        type: "field",
      },
      {
        condition: "show",
        name: "NhH9Kwmq",
        type: "field",
      },
      {
        condition: "show",
        name: "PUQvtaoe",
        type: "field",
      },
      {
        condition: "show",
        name: "JxNidsnG",
        type: "field",
      },
      {
        condition: "show",
        name: "jiLDPAsG",
        type: "field",
      },
      {
        condition: "show",
        name: "JU5u4ZEZ",
        type: "field",
      },
      {
        condition: "show",
        name: "aVwLUSvd",
        type: "field",
      },
      {
        condition: "show",
        name: "4w39drrY",
        type: "field",
      },
      {
        condition: "show",
        name: "post_graduate_details_2",
        type: "section",
      },
    ],
  },

  {
    condition: {
      action: "any",
      rule: [
        {
          name: "nDseBBtC",
          condition: "is",
          value: "arAzW89b",
        },
      ],
    },
    executeCondition: [
      {
        condition: "show",
        name: "siavE8ra",
        type: "field",
      },
    ],
  },

  {
    condition: {
      action: "any",
      rule: [
        {
          name: "nDseBBtC",
          condition: "is not",
          value: "arAzW89b",
        },
      ],
    },
    executeCondition: [
      {
        condition: "hide",
        name: "siavE8ra",
        type: "field",
      },
    ],
  },
  // Year Calcuator
  {
    condition: {
      action: "yearCalculator",
      field_details: [
        {
          name: "FnQOP76DN",
        },
      ],
    },
    executeCondition: [
      {
        name: "WvMEJMHf",
        type: "table_field",
      },
      {
        name: "YBVA8ZBD",
        type: "table_field",
      },
      {
        name: "Lf27AYVD",
        type: "table_field",
      },
      {
        name: "t7WX4m3C",
        type: "table_field",
      },
      {
        name: "dSCRbNkZ",
        type: "table_field",
      },
    ],
  },

  // Enrollment Full Time
  {
    condition: {
      action: "any",
      rule: [
        {
          name: "qiKRi3FQ",
          condition: "is",
          value: "ASZGSLwu", // FUll TIME
        },
      ],
    },
    executeCondition: [
      {
        condition: "show",
        name: "mmFm8rGG", // Scholars field
        type: "field",
      },
      {
        condition: "hide",
        name: "CHiyhJUv", // Scholars field
        type: "field",
      },
      {
        condition: "hide",
        name: "Ge7muEBV", // Name of Centre for Research Collaboration
        type: "field",
      },
      {
        condition: "hide",
        name: "gVhWG8Gn", // Name of the Current Employer
        type: "field",
      },
      {
        condition: "hide",
        name: "TEeeywnH", // Place of Working
        type: "field",
      },
      {
        condition: "hide",
        name: "Y3uXuf5w", // Place of Working
        type: "field",
      },
    ],
  },

  {
    condition: {
      action: "any",
      rule: [
        {
          name: "qiKRi3FQ",
          condition: "noPresent",
          value: "", // FUll TIME
        },
      ],
    },
    executeCondition: [
      {
        condition: "hide",
        name: "mmFm8rGG", // Scholars field
        type: "field",
      },
      {
        condition: "hide",
        name: "CHiyhJUv", // Scholars field
        type: "field",
      },
      {
        condition: "hide",
        name: "Ge7muEBV", // Name of Centre for Research Collaboration
        type: "field",
      },
      {
        condition: "hide",
        name: "gVhWG8Gn", // Name of the Current Employer
        type: "field",
      },
      {
        condition: "hide",
        name: "TEeeywnH", // Place of Working
        type: "field",
      },
      {
        condition: "hide",
        name: "Y3uXuf5w", // Place of Working
        type: "field",
      },
    ],
  },

  // Enrollment Part Time Internal Candidates
  {
    condition: {
      action: "any",
      rule: [
        {
          name: "qiKRi3FQ",
          condition: "is",
          value: "sZ7VL9He", // Part Time Internal Candidates
        },
      ],
    },
    executeCondition: [
      {
        condition: "show",
        name: "CHiyhJUv", // Name of the Institution
        type: "field",
      },
      {
        condition: "hide",
        name: "mmFm8rGG", // Scholars field
        type: "field",
      },
      {
        condition: "hide",
        name: "Ge7muEBV", // Name of Centre for Research Collaboration
        type: "field",
      },
      {
        condition: "hide",
        name: "gVhWG8Gn", // Name of the Current Employer
        type: "field",
      },
      {
        condition: "hide",
        name: "TEeeywnH", // Place of Working
        type: "field",
      },
      {
        condition: "hide",
        name: "Y3uXuf5w", // Place of Working
        type: "field",
      },
    ],
  },

  // Name of the Institution
  {
    condition: {
      action: "any",
      rule: [
        {
          name: "CHiyhJUv",
          condition: "is",
          value: "S3NReBiX", // Other
        },
      ],
    },
    executeCondition: [
      {
        condition: "show",
        name: "Y3uXuf5w", // If other Constituent of Symbiosis, please specify
        type: "field",
      },
    ],
  },

  // Name of the Institution
  {
    condition: {
      action: "any",
      rule: [
        {
          name: "CHiyhJUv",
          condition: "is not",
          value: "S3NReBiX", // Other
        },
      ],
    },
    executeCondition: [
      {
        condition: "hide",
        name: "Y3uXuf5w", // If other Constituent of Symbiosis, please specify
        type: "field",
      },
    ],
  },

  // Enrollment Part Time External Candidates working with SIU`s Centre for Research Collaboration(Employment Certificate Necessary)
  {
    condition: {
      action: "any",
      rule: [
        {
          name: "qiKRi3FQ",
          condition: "is",
          value: "8CoegAiG", // Part Time External Candidates working with SIU`s Centre for Research Collaboration(Employment Certificate Necessary)
        },
      ],
    },
    executeCondition: [
      {
        condition: "show",
        name: "Ge7muEBV", // Name of Centre for Research Collaboration
        type: "field",
      },
      {
        condition: "hide",
        name: "mmFm8rGG", // Scholars field
        type: "field",
      },
      {
        condition: "hide",
        name: "CHiyhJUv", // Name of the Institution
        type: "field",
      },
      {
        condition: "hide",
        name: "gVhWG8Gn", // Name of the Current Employer
        type: "field",
      },
      {
        condition: "hide",
        name: "TEeeywnH", // Place of Working
        type: "field",
      },
      {
        condition: "hide",
        name: "Y3uXuf5w", // Place of Working
        type: "field",
      },
    ],
  },

  // Enrollment Part Time External Candidates Working With Other Organization(Employment Certificate Necessary)
  {
    condition: {
      action: "any",
      rule: [
        {
          name: "qiKRi3FQ",
          condition: "is",
          value: "Vi8cgaUy", // Part Time External Candidates Working With Other Organization(Employment Certificate Necessary)
        },
      ],
    },
    executeCondition: [
      {
        condition: "show",
        name: "gVhWG8Gn", // Name of the Current Employer
        type: "field",
      },
      {
        condition: "show",
        name: "TEeeywnH", // Place of Working
        type: "field",
      },
      {
        condition: "hide",
        name: "Ge7muEBV", // Name of Centre for Research Collaboration
        type: "field",
      },
      {
        condition: "hide",
        name: "mmFm8rGG", // Scholars field
        type: "field",
      },
      {
        condition: "hide",
        name: "CHiyhJUv", // Name of the Institution
        type: "field",
      },
      {
        condition: "hide",
        name: "Y3uXuf5w", // Place of Working
        type: "field",
      },
    ],
  },
  // doucment Category Handle
  {
    condition: {
      action: "any",
      rule: [
        {
          name: "bfxqNX7u",
          otherSectionName: "H2z5teV8",
          condition: "is",
          isNotInPresntSection: true,
          value: "mtDK6jig",
        },
        {
          name: "bfxqNX7u",
          otherSectionName: "H2z5teV8",
          condition: "is",
          isNotInPresntSection: true,
          value: "mtDK9jig",
        },
        {
          name: "bfxqNX7u",
          otherSectionName: "H2z5teV8",
          condition: "is",
          isNotInPresntSection: true,
          value: "mt38K2jig",
        },
        {
          name: "bfxqNX7u",
          otherSectionName: "H2z5teV8",
          condition: "is",
          isNotInPresntSection: true,
          value: "mt3DK2jig",
        },
        {
          name: "bfxqNX7u",
          otherSectionName: "H2z5teV8",
          condition: "is",
          isNotInPresntSection: true,
          value: "mcDK9jig",
        },
      ],
    },
    executeCondition: [
      {
        condition: "show",
        name: "4eAPYZ5H",
        type: "field",
      },
    ],
  },
  {
    condition: {
      action: "any",
      rule: [
        {
          name: "bfxqNX7u",
          otherSectionName: "H2z5teV8",
          condition: "is not",
          isNotInPresntSection: true,
          value: "mtDK6jig",
        },
        {
          name: "bfxqNX7u",
          otherSectionName: "H2z5teV8",
          condition: "is not",
          isNotInPresntSection: true,
          value: "mtDK9jig",
        },
      ],
    },
    executeCondition: [
      {
        condition: "hide",
        name: "4eAPYZ5H",
        type: "field",
      },
    ],
  },
  // doucment Fellowship Handle
  {
    condition: {
      action: "any",
      rule: [
        {
          name: "FnWWPfDN",
          otherSectionName: "HXCpecem",
          condition: "is",
          isNotInPresntSection: true,
          value: "sDrLVecJ",
        },
      ],
    },
    executeCondition: [
      {
        condition: "show",
        name: "4eFCT5Z5H",
        type: "field",
      },
    ],
  },
  {
    condition: {
      action: "any",
      rule: [
        {
          name: "FnWWPfDN",
          otherSectionName: "HXCpecem",
          condition: "is",
          isNotInPresntSection: true,
          value: "ZALq524u",
        },
      ],
    },
    executeCondition: [
      {
        condition: "hide",
        name: "4eFCT5Z5H",
        type: "field",
      },
    ],
  },

  // doucment NET/SET Handle
  {
    condition: {
      action: "any",
      rule: [
        {
          name: "FnWWP6drN",
          otherSectionName: "RFBLQxYn",
          condition: "is",
          isNotInPresntSection: true,
          value: "cx6EABqF",
        },
      ],
    },
    executeCondition: [
      {
        condition: "show",
        name: "dGtyj677u",
        type: "field",
      },
    ],
  },
  {
    condition: {
      action: "any",
      rule: [
        {
          name: "FnWWP6drN",
          otherSectionName: "RFBLQxYn",
          condition: "is",
          isNotInPresntSection: true,
          value: "Na2daYRM",
        },
      ],
    },
    executeCondition: [
      {
        condition: "hide",
        name: "dGtyj677u",
        type: "field",
      },
    ],
  },

  // Masters Degrees
  {
    condition: {
      action: "any",
      rule: [
        {
          name: "Vx9LTbTQ",
          condition: "is",
          value: "c9t3bNWJ", // Other
        },
      ],
    },
    executeCondition: [
      {
        condition: "show",
        name: "VxCF39TQ", // If other Constituent of Symbiosis, please specify
        type: "field",
      },
    ],
  },

  // Masters Degrees
  {
    condition: {
      action: "any",
      rule: [
        {
          name: "Vx9LTbTQ",
          condition: "is not",
          value: "c9t3bNWJ", // Other
        },
      ],
    },
    executeCondition: [
      {
        condition: "hide",
        name: "VxCF39TQ", // If other Constituent of Symbiosis, please specify
        type: "field",
      },
    ],
  },

  // Nationality Country Represented Category

  // Faculty Sub Stream
  {
    condition: {
      action: "and",
      rule: [
        {
          name: "9ybpTdKH",
          condition: "is",
          value: "LYvH7Z9x", // Other
        },
      ],
    },
    executeCondition: [
      {
        condition: "optionHide",
        name: "specialization", // If other Constituent of Symbiosis, please specify
        type: [
          "H8Ch6X6S",
          "ZfCCUqJw",
          "bePoxu2n",
          "LZefHib4",
          "fPJC5YHM",
          "LutE7aS7",
          "ehxhU5vH",
          "4PvybTJQ",
          "d7ZexgCX",
          "RM7aGJWS",
        ],
      },
    ],
  },

  {
    condition: {
      action: "and",
      rule: [
        {
          name: "9ybpTdKH",
          condition: "is",
          value: "3ZwPbEzs", // Other
        },
      ],
    },
    executeCondition: [
      {
        condition: "optionHide",
        name: "specialization", // If other Constituent of Symbiosis, please specify
        type: [
          "LZefHib4",
          "bePoxu2n",
          // "ZfCCUqJw",
          "H8Ch6X6S",
          "i5gjxxYY",
          "iqRXT6aN",
          "inykDHFN",
          "rGA7CBE7",
          "hLhBtRGa",
          "3ckLaK32",
        ],
      },
    ],
  },

  {
    condition: {
      action: "and",
      rule: [
        {
          name: "9ybpTdKH",
          condition: "is",
          value: "AJdECKog", // Other
        },
      ],
    },
    executeCondition: [
      {
        condition: "optionHide",
        name: "specialization", // If other Constituent of Symbiosis, please specify
        type: [
          "RM7aGJWS",
          "ZfCCUqJw",
          "d7ZexgCX",
          "4PvybTJQ",
          "ehxhU5vH",
          "LutE7aS7",
          "fPJC5YHM",
          "i5gjxxYY",
          "iqRXT6aN",
          "inykDHFN",
          "rGA7CBE7",
          "hLhBtRGa",
          "3ckLaK32",
        ],
      },
    ],
  },

  {
    condition: {
      action: "and",
      rule: [
        // {
        //   name: "XvcimGmV",
        //   condition: "is",
        //   value: "6r7KVjiy", // Other
        // },
        {
          name: "AsdfxG8e",
          condition: "is",
          value: "VGZwFRNY", // Other
        },
      ],
    },
    executeCondition: [
      {
        condition: "optionHide",
        name: "category", // If other Constituent of Symbiosis, please specify
        type: ["mt38K2jig"],
      },
    ],
  },

  // Nationality Country Represented Category
  {
    condition: {
      action: "and",
      rule: [
        {
          name: "AsdfxG8e",
          condition: "is not",
          value: "VGZwFRNY", // Other
        },
      ],
    },
    executeCondition: [
      {
        condition: "optionHide",
        name: "category", // If other Constituent of Symbiosis, please specify
        type: ["mtDK4jig", "mtDK9jig", "mtDK6jig", "mcDK9jig", "mt3DK2jig"],
      },
    ],
  },

  // {
  //   condition: {
  //     action: "percentage",
  //     field_details: [
  //       {
  //         name: "6wzGX99M",
  //       },
  //     ],
  //   },
  //   executeCondition: [
  //     {
  //       name: "ZjE7omFD",
  //       type: "table_field",
  //       isMaxMark: false,
  //     },
  //     {
  //       name: "ceG2g3cr",
  //       type: "table_field",
  //       isMaxMark: true,
  //     },
  //   ],
  // }

  // {
  //   "condition": {
  //     "action": "any",
  //     "rule": [{
  //       "name": "4FoCm8MU",
  //       "condition": "greaterThen",
  //       "value": "VSiDVkaA"
  //     }]
  //   }
  // }

  //     {
  //   "condition": {
  //     "action": "notEqual",
  //     "rule": [{
  //       "name": "Am7Czqhs",
  //       "value" : "VeUtj9tL"
  //     }]
  //   }
  // }
];
