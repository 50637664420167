import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../Store";
// import { fieldOnChange, onErrorHandle } from "../../Utils/commonService";;
import {
  getErrorModel,
  getHideFields,
  getReadOnlyFields,
  onSumbitStatus,
} from "../../Store/FormConfig/form.selectors";
import { useSelector } from "react-redux";
import {
  checkRule,
  defaultValue,
  fieldOnChange,
  onErrorHandle,
  setDefaultValueInModel,
  rule
} from "../../utils/commonService";
import styled from "styled-components";
type InputSizeType = "large" | "small" | "default";
export const AppInput = (props: any) => {
	const { field, model, errorModel, type, fieldConfig,iconTitle,sectionId,showError,setLoading } = props
	const dispatch = useAppDispatch()
	const submitStatus = useSelector(onSumbitStatus);
	const errorModelData = useSelector(getErrorModel);
	const readOnlyField = useSelector(getReadOnlyFields)
	const hideFields = useSelector(getHideFields)
	const [textInput, setTextInput] = useState("")
	const changeFn = (event: any) => {
		setTextInput(event?.target?.value)
		fieldOnChange(event?.target?.value, model, field, fieldConfig,sectionId,errorModelData, dispatch,setLoading,rule);
	}
	const onBlur = (value: any) => {
		onErrorHandle(field, errorModel, model, dispatch,sectionId,hideFields)	
	}
	useEffect(() => {
		if(model[field?.id] || model[field?.name]){
            fieldOnChange(model[field?.id] || model[field?.name], model, field, fieldConfig, sectionId, errorModelData, dispatch,setLoading,rule);
        }
		checkRule( model, field, fieldConfig, sectionId, errorModelData, dispatch,setLoading,rule)
		// if(field?.default_value !== "" && field?.read_only){
		// 	setDefaultValueInModel(field?.default_value, model, field, fieldConfig,sectionId,errorModelData, dispatch);
		// }
		setTextInput("")
		onErrorHandle(field, errorModel, model, dispatch,sectionId,hideFields)

		// setTimeout(function() {
			
		// 	checkRule("", model, field, fieldConfig,sectionId,errorModelData, dispatch)
		//   }, 900);		
	}, [])
	function getYearsDateRange(year: any, includeCurrentDateMax: boolean = false) {
		const currentDate = new Date();
		const currentYear = currentDate.getFullYear();
		const minYear = currentYear - year;
	
		const minDate = new Date(minYear, currentDate.getMonth(), currentDate.getDate());
		let maxDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
	
		if (includeCurrentDateMax) {
			maxDate = currentDate;
		}
	
		const minDateString = minDate.toISOString().split('T')[0];
		const maxDateString = maxDate.toISOString().split('T')[0];
	
		return {
			minDate: minDateString,
			maxDate: maxDateString
		};
	}
	
	
	return <FormGroupWrapper>
		<div className="form-group">
			<div className="input-group mb-3">
				 {iconTitle ? <div className="input-group-prepend">
					<button className="btn" type="button">
						{iconTitle}
					</button>
				</div> :null}
				<input
					className={`form-control ${submitStatus && errorModelData[field.id] && !readOnlyField.includes(props?.field?.id) && "parsley-error scrollTOError"
						}`}
					value={model[field?.id] || model[field?.name]  ? model[field?.id] || model[field?.name] : defaultValue[field?.id] || "" }
					type={type}
					placeholder={field?.placeholder}
					onChange={(value: any) => changeFn(value)}
					onBlur={(value: any) => onBlur(value)}
					disabled={readOnlyField.includes(field.id) ? true : field?.read_only ? true :false}
					min={field?.type === "datetime" && field?.acceptCriteria ? getYearsDateRange(field?.acceptCriteria)?.minDate : ""}
					max={field?.type === "datetime" && field?.acceptCriteria ? getYearsDateRange(field?.acceptCriteria)?.maxDate : getYearsDateRange(0,true)?.maxDate}
				/>
			
			</div>
			{showError && submitStatus && errorModelData[props?.field.id] ? <ul className="parsley-errors-list filled" id="parsley-id-29">
					<li className="parsley-required">
                    {errorModelData[props?.field.id]}
					</li> 
				</ul>: null}
		</div>
	</FormGroupWrapper>
}

const FormGroupWrapper = styled.div`
  && {
    button {
      z-index: 0 !important;
      border: 1px solid #ced4da !important;
      outline: none !important;
      border-right: 0px solid transparent !important;
    }
    button:focus {
      outline: none !important;
      box-shadow: none !important;
    }
	input:focus {
  		border: 1px solid #2d2d2d !important;
		// box-shadow: 0 0 0 0.25rem #f8d7da !important;
	}
  }
`;
