import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getSnackBarData } from "../../Store/FormConfig/form.selectors";
import { useToasts } from "react-toast-notifications";

const Toaster = () => {
  const { addToast } = useToasts();
  const data = useSelector(getSnackBarData);

  const notify = () => {
    addToast(data?.message, {
      appearance: data?.severity,
      autoDismiss: true,
    })
  }
  useEffect(() => {
    if (data?.open) notify();
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps
  return <></>;
};
export default Toaster;
