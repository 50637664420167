import { RootState } from "../index";

export const onSumbitStatus = (state: RootState) => state.formData.onSubmit
export const getFieldJSONConfig = (state: RootState) => state.formData.FieldJSONConfig
export const getModel = (state: RootState) => ({ ...state.formData.model });
export const getErrorModel = (state: RootState) => ({ ...state.formData.errorModel });
export const getAllCounty = (state: RootState) => state.formData.allCounty
export const getAllState = (state: RootState) => state.formData.allState
export const getCurrentStage = (state: RootState) => state.formData.currentStage
export const getPortalUserDetails = (state: RootState) => state.formData.portalUserDetails
export const getCreateApplicationFormResponse = (state: RootState) => state.formData.createApplicationFormResponse
export const getPortalUserId = (state: RootState) => state.formData.portalUserId
export const getAllApplicationFormResponse = (state: RootState) => state.formData.allApplicationResponseList;
export const getApplicationsFormsList = (state: RootState) => state.formData.allApplicationsFormList;
export const getSnackBarData = (state: RootState) => state.formData.snackBarData;
export const getUploadDocumentData = (state: RootState) => state.formData.uploadDocumentData;
export const getOptionMappingData = (state: RootState) => state.formData.optionMappingData;
export const getHideFields = (state: RootState) => state.formData.hideFields;
export const getSelectApplicationDetails = (state: RootState) => state.formData.selectApplicationDetails;
export const getContactId = (state: RootState) => state.formData.contactId;
export const isPaymentSection = (state: RootState) => state.formData.isPaymentSection;
export const getPopupData = (state: RootState) => state.formData.popupData;
export const getReadOnlyFields = (state: RootState) => state.formData.readOnlyFields;
export const getHideOptionIds = (state: RootState) => state.formData.hideOptionIds;
