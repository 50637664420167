import React from "react";
import styled from "styled-components";

const AppModel = ({ isOpen, onClose, children, width }:any) => {
  if (!isOpen) return null;

  return (
    <>
      <Overlay />
      <PopupContainer style={{ width }}>
        <PopupHeader>
          <h4>Payment Date Expired!</h4>
        </PopupHeader>
        <Divider />
        {children}
      </PopupContainer>
    </>
  );
};

export default AppModel;

const PopupContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  max-height: 100vh;
  overflow: auto;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 999999999 !important;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999998 !important;
`;

const PopupHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

const Divider = styled.hr`
  && {
    margin-bottom: 40px;
  }
`;
