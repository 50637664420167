import React from 'react'
import { useAppDispatch } from '../../Store';
import { FormAction } from '../../Store/FormConfig/form.actions';
import styled from 'styled-components';
import { LoaderComponentProps, withLoader } from '../../AppComponents/AppLoader/LoaderComponent';
const { useLocation } = require("react-router-dom");


interface PIScheduleProps extends LoaderComponentProps { }
const PISchedule: React.FC<PIScheduleProps> = ({ setLoading }) => {
    const dispatch = useAppDispatch();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const applicationFormId = searchParams.get("id");
    const [piCardUrl, setPICardUrl] = React.useState("")
    const [isPiCardLoading, setIsPiCardLoading] = React.useState(false);
    React.useEffect(() => {
        if (applicationFormId) {
            setIsPiCardLoading(true)
            setLoading(true)
            dispatch(FormAction.personalInterviewScheduleAction(applicationFormId))
                .then((res: any) => {
                    const reportCardUrl = res?.payload?.data?.data?.pi_card_url;
                    setLoading(false)
                    setIsPiCardLoading(false)
                    setPICardUrl(reportCardUrl);
                })
                .catch((error: any) => {
                    setLoading(false)
                    setIsPiCardLoading(false)
                    console.error(error);
                });
        } else {
            setLoading(false)
            setIsPiCardLoading(false)
            console.warn('paramID is invalid:', applicationFormId);
        }
        return () => {
            setPICardUrl("");
            setIsPiCardLoading(false);
        }
    }, [applicationFormId]); // eslint-disable-line react-hooks/exhaustive-deps


    const handleDownload = () => {
        if (piCardUrl) {
            window.open(piCardUrl, '_blank');
        }
    };

    return (
        <PdfContainer>
            {isPiCardLoading ? "Loading..." : (
                <div className="col-lg-12">
                    <div className="card">
                        <div className='btn-container'>
                            <h6>
                                Personal Interview Schedule
                            </h6>
                            {piCardUrl && (
                                <button className='btn btn-danger' style={{}} onClick={handleDownload}>
                                    <i className="fa fa-file-pdf-o" aria-hidden="true" style={{ marginRight: "8px" }}></i>
                                    Download PDF
                                </button>
                            )}
                        </div>

                        {piCardUrl ? <iframe
                            title="PDF Viewer"
                            src={`https://docs.google.com/viewer?url=${encodeURIComponent(piCardUrl)}&embedded=true`}
                            style={{ width: '100%', height: 'calc(100% - 40px)', border: "0px" }}
                        >
                            <p>Your browser does not support iframes.</p>
                        </iframe> : <div style={{ height: 'calc(100% - 180px)', display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <h3>No data available.</h3>
                            <h4>kindly refresh the page incase you are eligible and not able to view the PI Schedule card.</h4>
                        </div>
                        }
                    </div>
                </div>
            )}
        </PdfContainer>
    )
}

export default withLoader(PISchedule);


const PdfContainer = styled.div`
    .btn-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px;
      h6 {
      margin: 0;
      }
    }
      .card {
          height: calc(100vh - 102px);
          padding: 0px 16px;
          overflow: hidden;
          padding-bottom: 16px;
          margin: 0px;
      }
    height: calc(100vh - 86px);
    overflow: hidden;
    margin: 0px;
    margin-top: 21px;
    .pdf-container {
      height: 100%;
    }
`;
