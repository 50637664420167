import { createAsyncThunk } from "@reduxjs/toolkit";
import { FormNetworkService } from "./form.networkService";
export class FormAction {
    static getAllCountry = createAsyncThunk(
        "broadcastSlice/getAllCountry",
        (data: any, { dispatch, rejectWithValue }) => {
            return FormNetworkService.getAllCountry(data)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );

    static getAllState = createAsyncThunk(
        "broadcastSlice/getAllState",
        (data: any, { dispatch, rejectWithValue }) => {
            return FormNetworkService.getAllState(data)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );

    static loginApplication = createAsyncThunk(
        "broadcastSlice/loginApplication",
        (data: any, { dispatch, rejectWithValue }) => {
            return FormNetworkService.loginApplication(data)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );

    static verifyLogin = createAsyncThunk(
        "broadcastSlice/verifyLogin",
        (data: any, { dispatch, rejectWithValue }) => {
            return FormNetworkService.verifyLogin(data)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );

    static getPortalUserDetails = createAsyncThunk(
        "broadcastSlice/getPortalUserDetails",
        (portal_user_id: string, { dispatch, rejectWithValue }) => {
            return FormNetworkService.getPortalUserDetails(portal_user_id)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );

    static createFormApplication = createAsyncThunk(
        "broadcastSlice/createFormApplication",
        (data: any, { dispatch, rejectWithValue }) => {
            return FormNetworkService.createFormApplication(data)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );

    static getApplicationFormsList = createAsyncThunk(
        "broadcastSlice/getApplicationFormsList",
        (data: any, { dispatch, rejectWithValue }) => {
            return FormNetworkService.getApplicationFormsList(data)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );

    static getApplicationFormsResponseList = createAsyncThunk(
        "broadcastSlice/getApplicationFormsResponseList",
        (data: any, { dispatch, rejectWithValue }) => {
            return FormNetworkService.getApplicationFormsResponseList(data)
                .then(async (response: any) => {      
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );
    static getApplicationResponseById = createAsyncThunk(
        "broadcastSlice/getApplicationResponseById",
        (application_form_id: string, { dispatch, rejectWithValue }) => {
            return FormNetworkService.getApplicationResponseById(application_form_id)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );

    static uploadDocument = createAsyncThunk(
        "broadcastSlice/uploadDocument",
        (data: any, { dispatch, rejectWithValue }) => {
            return FormNetworkService.uploadDocument(data)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );

    static getApplicationResponseDetails = createAsyncThunk(
        "broadcastSlice/getApplicationResponseDetails",
        (data: any, { dispatch, rejectWithValue }) => {
            return FormNetworkService.getApplicationResponseDetails(data)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );

    static updateValue = createAsyncThunk(
        "broadcastSlice/updateValue",
        (data: any, { dispatch, rejectWithValue }) => {
            return FormNetworkService.updateValue(data)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );
    //forgot password
    static forgotPassword = createAsyncThunk(
        "broadcastSlice/forgotPassword",
        (data: any, { dispatch, rejectWithValue }) => {
            return FormNetworkService.forgotPassword(data)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );
    //Mark  password
    static logout = createAsyncThunk(
        "broadcastSlice/logout",
        (data: any, { dispatch, rejectWithValue }) => {
            return FormNetworkService.logout(data)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );
        //Mark  password
        static initiatePayment = createAsyncThunk(
            "broadcastSlice/initiatePayment",
            (data: any, { dispatch, rejectWithValue }) => {
                return FormNetworkService.initiatePayment(data)
                    .then(async (response: any) => {
                        return response;
                    })
                    .catch((error: { response: unknown }) => {
                        return rejectWithValue(error?.response);
                    })
                    .finally(() => { });
            }
        );

        // Print PDF Action
    static printPDFAction = createAsyncThunk(
        "broadcastSlice/printPDFAction",
        (id: any, { dispatch, rejectWithValue }) => {
            return FormNetworkService.printPDFNetwork(id)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );

    // Admit Card
    static admitCardAction = createAsyncThunk(
        "broadcastSlice/admitCardAction",
        (id: any, { dispatch, rejectWithValue }) => {
            return FormNetworkService.admitCardNetwork(id)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );
      // Report Card
      static reportCardAction = createAsyncThunk(
        "broadcastSlice/admitCardAction",
        (id: any, { dispatch, rejectWithValue }) => {
            return FormNetworkService.reportCardNetwork(id)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );
    //Personal Interview Schedule   
    static personalInterviewScheduleAction = createAsyncThunk(
        "broadcastSlice/personalInterviewScheduleAction",
        (id: any, { dispatch, rejectWithValue }) => {
            return FormNetworkService.piScheduleCardNetwork(id)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );
}
