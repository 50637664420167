import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { getPopupData } from "../../Store/FormConfig/form.selectors";
import { setPopupData, showSnackBar } from "../../Store/FormConfig/form.slice";

const PopupContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  max-height: 60vh;
  overflow: auto;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 999999999 !important;
  padding: 20px;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999999 !important;
`;

const PopupHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }
`;

const Divider = styled.hr`
  && {
    margin-bottom: 40px;
  }
`;

const PopupContent = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <React.Fragment>
      <Overlay onClick={onClose} />
      <PopupContainer>
        <PopupHeader>
          <h4>Eligibility Error!</h4>
          <button onClick={onClose}>Close</button>
        </PopupHeader>
        <Divider />
        {children}
      </PopupContainer>
    </React.Fragment>
  );
};

const Popup = () => {
  const dispatch = useDispatch();
  const data = useSelector(getPopupData);
  const togglePopup = (e) => {
    e.preventDefault();
    dispatch(
      showSnackBar({
        open: false,
        message: "",
      })
    );
    let data1 = {...data}
    data1['open'] = false
    dispatch(setPopupData(data1))
  };

  return (
    <div>
      <PopupContent isOpen={data?.open} onClose={(e) => togglePopup(e)}>
        <h4 style={{marginBottom: "20px;"}}>{data?.message}</h4>
        <div>
    <h6>A candidate seeking admission to the Ph.D. programme must satisfy any one of the following eligibility requirements:</h6>
    <p>
      A 1-year/2-semester master's degree programme after a 4-year/8-semester bachelor's degree programme <br />
      or <br />
      A 2-year/4-semester master's degree programme after a 3-year bachelor's degree programme or qualifications declared equivalent to the master's degree by the corresponding statutory regulatory body, with at least 55% marks in aggregate or its equivalent grade in a point scale wherever grading system is followed or equivalent qualification from a foreign educational institution accredited by an assessment and accreditation agency which is approved, recognized or authorized by an authority, established or incorporated under a law in its home country or any other statutory authority in that country to assess, accredit or assure quality and standards of the educational institution. A relaxation of 5% marks or its equivalent grade may be allowed for those belonging to SC/ST/ Differently-Abled,
    </p>
    <p>Provided that a candidate seeking admission after a 4-year/8-semester bachelor's degree programme should have a minimum of 75% marks in aggregate or its equivalent grade on a point scale wherever the grading system is followed. A relaxation of 5% marks or its equivalent grade may be allowed for those belonging to SC/ST/ Differently-Abled,</p>
    <p>Candidates who have completed the M.Phil. programme with at least 55% marks in aggregate or its equivalent grade in a point scale wherever grading system is followed or equivalent qualification from a foreign educational institution accredited by an assessment and accreditation agency which is approved, recognized or authorized by an authority, established or incorporated under a law in its home country or any other statutory authority in that country to assess, accredit or assure quality and standards of educational institutions, shall be eligible for admission to the Ph.D. programme.</p>
    <p>A relaxation of 5% marks or its equivalent grade may be allowed for those belonging to SC/ST/Differently-Abled.</p>
  </div>

  <div>
    <p>International candidates (NRIs, Foreign Nationals and PIOs who have obtained Master's degree from foreign universities) are required to qualify IELTS with 6.5 score or IELA Test (Band 3) of SIU ELTIS, an equivalent test for English language proficiency.</p>
  </div>

  <div>
    <p>Please note that candidates holding Professional Qualifications/ Diplomas/ Post Graduate Diplomas/ Any other qualifications will need to submit the proof of equivalence of the qualifications to master's Degree from a Statutory Bodies like UGC/AICTE/BCI/MCI etc</p>
  </div>

  <div>
    <h6>Reservation:</h6>
    <ul>
      <li>Scheduled Castes - 15 %</li>
      <li>Scheduled Tribes - 7.5%</li>
      <li>Differently Abled - 3%</li>
      <li>Kashmiri Migrants - 2 Seats</li>
      <li>International Students - 15%</li>
    </ul>
  </div>
      </PopupContent>
    </div>
  );
};

export default Popup;
