import { useEffect, useState } from "react";
import { useAppDispatch } from "../../Store";
// import { fieldOnChange, onErrorHandle } from "../../Utils/commonService";;
import { getErrorModel, getHideFields, onSumbitStatus } from "../../Store/FormConfig/form.selectors";
import { useSelector } from "react-redux";
import React from "react";
import { checkRule, fieldOnChange, onErrorHandle, rule } from "../../utils/commonService";
import { FormAction } from "../../Store/FormConfig/form.actions";
import styled from "styled-components";
import { setModel } from "../../Store/FormConfig/form.slice";
type InputSizeType = "large" | "small" | "default";
export const AppFile = (props: any) => {
	const { field, model, errorModel, fieldConfig, sectionId, setLoading } = props
	const dispatch = useAppDispatch()
	const errorModelData = useSelector(getErrorModel)
	const submitStatus = useSelector(onSumbitStatus);
	const hideFields = useSelector(getHideFields)
	const [textInput, setTextInput] = useState("")
	const [loading, setLoadingvalue] = useState(false); // State to manage loading state
	const [errorMappling, setErrorMappling] = useState<any>({

	})
	const changeFn = (event: any) => {
		setLoadingvalue(true);
		var file = event.target.files[0];
		const formData = new FormData();
		// const reader: any = new FileReader();
		formData.append("source", file);
		// if (!reader || !file) {
		// 	return null;
		//   }
		if (!field?.allowedMediaTypes.includes(file?.type)) {
			setLoadingvalue(false);
			let error: any = { ...errorMappling }
			error[field.id] = "Invalid Formant"
			setErrorMappling(error)
			fieldOnChange("", model, field, fieldConfig, sectionId, errorModelData, dispatch, setLoading, rule);
			model[field.id] = "";
			dispatch(setModel(model));
			return null
		}
		if (field?.allowedMediaSize && file.size > field?.allowedMediaSize) {
			setLoadingvalue(false);
			let error: any = { ...errorMappling }
			error[field.id] = "Invalid Size"
			setErrorMappling(error)
			fieldOnChange("", model, field, fieldConfig, sectionId, errorModelData, dispatch, setLoading, rule);
			model[field.id] = "";
			dispatch(setModel(model));
		} else {
			let error: any = { ...errorMappling }
			error[field.id] = ""
			setErrorMappling(error)
			dispatch(FormAction.uploadDocument(formData))
				.then((res: any) => {
					if (res?.payload?.data?.source) {
						let data = {
							...res?.payload?.data,
							"type": "file"
						}
						setLoadingvalue(false);
						fieldOnChange(data, model, field, fieldConfig, sectionId, errorModelData, dispatch, setLoading, rule);
					}
				})
				.catch((error: any) => {
					model[field.id] = "";
					dispatch(setModel(model));
				})
		}
	}
	const onBlur = () => {		
		checkRule(model, field, fieldConfig, sectionId, errorModelData, dispatch, setLoading, rule);
		onErrorHandle(field, errorModel, model, dispatch, sectionId, hideFields)
	}
	useEffect(() => {
		onErrorHandle(field, errorModel, model, dispatch, sectionId, hideFields)
		checkRule(model, field, fieldConfig, sectionId, errorModelData, dispatch, setLoading, rule);
	}, [])
	const clearFile = () => {
		model[field.id] = {};
		dispatch(setModel(model));
        fieldOnChange("", model, field, fieldConfig, sectionId, errorModelData, dispatch, setLoading, rule);
    }
	return <FileUploadWrapper>
		<div className="form-group">
			{ <input
				className={`form-control ${submitStatus && errorModelData[field.id] && "parsley-error scrollTOError"
					}`}
				type="file"
				// className="form-control"
				id="exampleInputFile"
				aria-describedby="fileHelp"
				onChange={(event) => changeFn(event)}
				disabled={field?.read_only}
				value={""}
				onBlur={() => onBlur()}
				aria-label="Choose a video please"
				//   style={{
				// 	color: "black",
				// 	backgroundColor: "lightgray",
				// 	borderColor: "#337ab7",
				// 	border: "2px solid lightgrey",
				// 	width: "30%"
				//   }}
				accept={field?.allowedMediaTypes}
				style={{ color:  model[field.id] ? "white" : " black"}}
			/> }

			{field?.displayText && !model[field.id] ? <small id="fileHelp" style={{
				color: "lightslategray",
			}} className="file-text form-text">
				{field.displayText}
			</small> : null}
			{loading && <div className="progress">
				<div className="progress-bar" role="progressbar" style={{ width: "100%" }} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}>Uploading...</div>
			</div>}
			 { model[field.id] ? <div className="file-info" style={{color:"green"}}>
				<span className="file-name">{model[field.id]?.name}</span>
				<button
					type="button"
					className="btn btn-link btn-logout"
					data-container="body"
					data-toggle="tooltip"
					disabled={field?.read_only}
					title=""
					data-original-title="Close this login session"
					onClick={() => clearFile()}
				>
					<i className="fa fa-times-circle text-danger"></i>
				</button>
				</div> :null} 
			{errorMappling[field.id] ? <small id="fileHelp" style={{
				color: "red",
			}} className="file-text form-text">
				{errorMappling[field.id]}
			</small> : null}
		</div>
	</FileUploadWrapper>
}

const FileUploadWrapper = styled.div`
&& {
	.form-group {
		input:focus{
			border: 1px solid #2d2d2d;
		}
		display: flex;
		flex-direction: column;
		jsutify-content: center;
		margin-bottom: 0px;
	}
	.file-text {
		padding: 2px;
	}
	input[type="file"] {
		// color: black;
		// border-color: #337ab7;
		// border: 2px solid lightgrey;
	}
}
`;