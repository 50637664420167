import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../Store";
import { FormAction } from "../Store/FormConfig/form.actions";
import {
  getAllCounty,
  getCreateApplicationFormResponse,
  getCurrentStage,
  getErrorModel,
  getFieldJSONConfig,
  getHideFields,
  getModel,
  getPortalUserId,
  getReadOnlyFields,
  getSelectApplicationDetails,
  isPaymentSection,
} from "../Store/FormConfig/form.selectors";
import {
  resetHideFieldsAndReadonlyFields,
  resetModelAndErrorModel,
  setCurrentStage,
  setFieldJSONConfig,
  setHideFields,
  setModel,
  setOnSumbit,
  setPopupData,
  setPortalUserId,
  setRemoveErrorModel,
  showSnackBar,
} from "../Store/FormConfig/form.slice";
import { AppInput } from "../AppComponents/Input";
import { Field } from "./FieldConfig";
import axios from "axios";
import NavbarMenu from "../components/NavbarMenu";
import { AppConfig } from "../Config/app-config";
import Toaster from "../AppComponents/Toaster";
import "./FormConfigStyles.css";
import {
  FieldJSONConfig,
  defaultValue,
  findObjectByName,
  isEmptyObject,
  isMobile,
  isThirteen,
  navBar,
  setHideFieldData,
  splitPhoneNumber,
} from "../utils/commonService";
import AppTable from "../AppComponents/AppTable";
import styled from "styled-components";
import {
  LoaderComponentProps,
  withLoader,
} from "../AppComponents/AppLoader/LoaderComponent";
import { FormNetworkService } from "../Store/FormConfig/form.networkService";
import useInternetConnection from "../AppComponents/UseInternetConnection/useInternetConnection";
import AppModal from "../components/AppModal/AppModal";
import AppModel from "../AppComponents/AppModel/model";
import Easebuzz from "../assets/images/easebuzz.png"
import Billdesk from "../assets/images/billdesk.jpg"
const { useHistory, useParams } = require("react-router-dom");

// Define the props for OptionContainer
interface OptionContainerProps {
  selected: boolean;
}

interface FormConfigProps extends LoaderComponentProps { }
interface Window {
  EasebuzzCheckout?: any; // Replace 'any' with the specific type if available
}
interface Node {
  child?: Node | Node[];
}
const FormConfigRenderer: React.FC<FormConfigProps> = ({ setLoading }) => {
  // const FormConfigRenderer = (): JSX.Element => {
  var formDivRef = useRef();
  const fieldConfigs = useSelector(getFieldJSONConfig);
  let { step, id } = useParams();
  const errorModelData = useSelector(getErrorModel);
  const hideFields = useSelector(getHideFields);
  const readOnlyFields = useSelector(getReadOnlyFields);
  const getAllCountyList = useSelector(getAllCounty);
  const model = useSelector(getModel);
  const currentStage = useSelector(getCurrentStage);
  const applicationDetails = useSelector(getSelectApplicationDetails);
  const isPaymentSectionData = useSelector(isPaymentSection);
  const portal_user_id = localStorage.getItem("portal_user_id");
  const { isOnline } = useInternetConnection();
  const formRef = useRef(null);
  
  let checkInternet = ""
  if (!isOnline) {
    checkInternet = "You are offline. Please connect to the internet to continue."
  } else {
    checkInternet = ""
  }
  // const createFormApplicationResponse = useSelector(getCreateApplicationFormResponse)
  const portalUserId = useSelector(getPortalUserId);
  const dispatch = useAppDispatch();
  const navigate = useHistory();

  // const location = useLocation
  const [tab, setTab] = useState(0);
  const [config, setConfig] = useState<any>([]);
  const [showProgressBar, setShowProgressBar] = useState<any>(false);
  const [htmlContent, setHtmlContent] = useState('');
  const [showModal, setShowModal] = useState(false)
  const [selectedOption, setSelectedOption] = useState("");
  const [paymentModel, setPaymentModel] = useState(false)
  const iframeRef = useRef<HTMLIFrameElement>(null); // Explicitly specify the type

  useEffect(() => {
    if (fieldConfigs.length !== 0) {
      setConfig(fieldConfigs);
    }
  }, [fieldConfigs]);
  useEffect(() => {
    AppConfig.setConfigData(FieldJSONConfig);
    dispatch(FormAction.verifyLogin({}))
      .then((res: any) => {
        if (!res?.payload?.data?.status) {
          navigate.push("/login", { replace: true });
        }
      })
      .catch((error: any) => {
        console.error("error", error);
      });
  }, []);
  const getApplicationResponse = (id: any, checkMaxStage?: boolean) => {
    dispatch(FormAction.getApplicationResponseDetails(id))
      .then((res: any) => {
        if (res?.payload?.data?.Status) {
          if (checkMaxStage) {
            let max_stage = res?.payload?.data?.data?.max_stage
            let navBarStage = navBar.find((res: any, index: any) => index === Number(step))
            if (navBarStage?.name !== max_stage) {
              navBar.map((res: any, index: any) => {
                if (res.name === max_stage) {
                  navigate.push("/form/" + index + "/" + id);
                  window.location.reload();
                  setShowProgressBar(false)
                }
              });
            }
            setShowProgressBar(false)
            dispatch(setFieldJSONConfig(FieldJSONConfig));
            let mobile_phone_number_id = findObjectByName(FieldJSONConfig, "mobile_phone_number")
            let mobileNumber = "+" + res.payload.data.data.fields[mobile_phone_number_id]
            res.payload.data.data.fields[mobile_phone_number_id] = splitPhoneNumber(mobileNumber);
            let payment_status = res?.payload?.data?.data?.payment_status
            let applicant_id = res?.payload?.data?.data?.applicant_id
            let application_fee = res?.payload?.data?.data?.application_fee
            let payment_mode = res?.payload?.data?.data?.payment_mode
            let payment_initiated = res?.payload?.data?.data?.payment_initiated
            let payment_details = res?.payload?.data?.data?.payment_details
            let allow_resubmission = res?.payload?.data?.data?.allow_resubmission
            let portal_user_id = res?.payload?.data?.data?.portal_user_id
            let data = { ...res?.payload?.data?.data?.fields, payment_status: payment_status, application_fee: application_fee, applicant_id: applicant_id, payment_initiated: payment_initiated, payment_mode: payment_mode, payment_details: payment_details, allow_resubmission: allow_resubmission, portal_user_id: portal_user_id }
            dispatch(setModel(data));
            setLoading(false)
            var myElement = document.getElementById("body-form");
            if (myElement) {
              myElement.scrollTo(0, 0);
            }
          } else {
            setShowProgressBar(false)
            dispatch(setFieldJSONConfig(FieldJSONConfig));
            let mobile_phone_number_id = findObjectByName(FieldJSONConfig, "mobile_phone_number")
            let mobileNumber = "+" + res.payload.data.data.fields[mobile_phone_number_id]
            res.payload.data.data.fields[mobile_phone_number_id] = splitPhoneNumber(mobileNumber);
            let payment_status = res?.payload?.data?.data?.payment_status
            let applicant_id = res?.payload?.data?.data?.applicant_id
            let application_fee = res?.payload?.data?.data?.application_fee
            let payment_mode = res?.payload?.data?.data?.payment_mode
            let payment_initiated = res?.payload?.data?.data?.payment_initiated
            let payment_details = res?.payload?.data?.data?.payment_details
            let allow_resubmission = res?.payload?.data?.data?.allow_resubmission
            let portal_user_id = res?.payload?.data?.data?.portal_user_id
            let data = { ...res?.payload?.data?.data?.fields, payment_status: payment_status, application_fee: application_fee, applicant_id: applicant_id, payment_initiated: payment_initiated, payment_mode: payment_mode, payment_details: payment_details, allow_resubmission: allow_resubmission, portal_user_id: portal_user_id }
            dispatch(setModel(data));
            setLoading(false)
            var myElement = document.getElementById("body-form");
            if (myElement) {
              myElement.scrollTo(0, 0);
            }

          }
        }else{
          navigate.push("/login")
        }
      })
      .catch((error: any) => {
        console.error("error", error);
      });
  };
  const getPortalUserDetails = () => {
    dispatch(FormAction.getPortalUserDetails(portalUserId || portal_user_id))
      .then((res: any) => {
        if (res?.payload?.data?.Status) {
          dispatch(setPortalUserId(res?.payload?.data?.data?.portal_user_id));
          setShowProgressBar(false)
        }
      })
      .catch((error: any) => {
        console.error("error", error);
      });
  };

  useEffect(() => {
    if (portalUserId === "") {
      if (step && id) {
        dispatch(resetModelAndErrorModel({}));
        dispatch(setCurrentStage(step));
        getApplicationResponse(id, true);
        getPortalUserDetails();
      }
    } else {
      getApplicationResponse(applicationDetails?.application_response_id, true);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(setOnSumbit(false));
    dispatch(setModel(modelData));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const modelData = useSelector(getModel);

  const setActiveTab = (index: any) => {
    setTab(index);
  };
  const a = isEmptyObject(errorModelData);
  useEffect(() => {
    if (currentStage || currentStage == 0) {
      dispatch(setOnSumbit(false));
      setConfig(fieldConfigs);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      // setShowProgressBar(false)

      // setLoading(false);
      // setLoading(false)
      // dispatch(setModel(data))

      // dispatch(setModel(defaultValue))
    }
  }, [currentStage]);
  useEffect(() => {
    setHideFieldData(hideFields)
  }, [hideFields])
  useEffect(() => {
    if (getAllCountyList) {

    }
  }, [getAllCountyList]);


  const checkSecondStepValidation = () => {
    let rules = [
      {
        "id": "H2z5teV8",
        "value": "mtDK4jig",
        "checkList": [
          {
            "id": "KuQGAPEW",
            "value": 55
          }
        ]
      },
      {
        "id": "H2z5teV8",
        "value": "mt3DK2jig",
        "checkList": [
          {
            "id": "KuQGAPEW",
            "value": 55
          }
        ]
      },

      {
        "id": "H2z5teV8",
        "value": "mt38K2jig",
        "checkList": [
          {
            "id": "KuQGAPEW",
            "value": 55
          }
        ]
      },
      {
        "id": "H2z5teV8",
        "value": "mtDK6jig",
        "checkList": [
          {
            "id": "KuQGAPEW",
            "value": 50
          }
        ]
      },
      {
        "id": "H2z5teV8",
        "value": "mtDK9jig",
        "checkList": [
          {
            "id": "KuQGAPEW",
            "value": 50
          }
        ]
      },
      {
        "id": "H2z5teV8",
        "value": "mcDK9jig",
        "checkList": [
          {
            "id": "KuQGAPEW",
            "value": 50
          }
        ]
      }
    ];

    for (let rule of rules) {
      if ((model[rule.id]?.id ?? model[rule.id]) === rule.value) {
        let checkListStatus = true;
        for (let checkListItem of rule.checkList) {

          if (Number(model[checkListItem.id]) < Number(checkListItem.value)) {
            checkListStatus = false;
            break;
          }
        }
        if (checkListStatus) {
          return true; // All checklist items satisfied
        }
      }
    }
    return false; // No matching rule or checklist not satisfied
  };
  const checkSecondStepValidationForPG2 = () => {
    let rules = [
      {
        "id": "H2z5teV8",
        "value": "mtDK4jig",
        "checkList": [
          {
            "id": "JU5u4ZEZ",
            "value": 55
          }
        ]
      },
      {
        "id": "H2z5teV8",
        "value": "mt3DK2jig",
        "checkList": [
          {
            "id": "JU5u4ZEZ",
            "value": 55
          }
        ]
      },

      {
        "id": "H2z5teV8",
        "value": "mt38K2jig",
        "checkList": [
          {
            "id": "JU5u4ZEZ",
            "value": 55
          }
        ]
      },
      {
        "id": "H2z5teV8",
        "value": "mtDK6jig",
        "checkList": [
          {
            "id": "JU5u4ZEZ",
            "value": 50
          }
        ]
      },
      {
        "id": "H2z5teV8",
        "value": "mtDK9jig",
        "checkList": [
          {
            "id": "JU5u4ZEZ",
            "value": 50
          }
        ]
      },
      {
        "id": "H2z5teV8",
        "value": "mcDK9jig",
        "checkList": [
          {
            "id": "JU5u4ZEZ",
            "value": 50
          }
        ]
      }
    ];

    for (let rule of rules) {
      if ((model[rule.id]?.id ?? model[rule.id]) === rule.value) {

        let checkListStatus = true;
        for (let checkListItem of rule.checkList) {
          if (Number(model[checkListItem.id]) < Number(checkListItem.value)) {
            checkListStatus = false;
            break;
          }
        }
        if (checkListStatus) {
          return true; // All checklist items satisfied
        }
      }
    }
    return false; // No matching rule or checklist not satisfied
  };
  const setPaymentValue = () => {
    let ruleArray = [
      // for international
      {
        condition: {
          action: "assignValue",
          rule: [
            {
              name: "AsdfxG8e",
              action: "is not",
              value: "VGZwFRNY",
            },
          ],
        },
        executeCondition: [
          {
            name: "application_fee",
            value: 4166.83
          },
        ],
      },
      {
        condition: {
          action: "assignValue",
          rule: [
            {
              name: "H2z5teV8",
              action: "is",
              value: "mt38K2jig",
            },
          ],
        },
        executeCondition: [
          {
            name: "application_fee",
            value: 4166.83
          },
        ],
      },
      // Kashmiri migrants non exempted
      {
        condition: {
          action: "assignValue",
          rule: [
            {
              name: "H2z5teV8",
              action: "is",
              value: "mt3DK2jig",
            },
            {
              name: "RFBLQxYn",
              action: "is",
              value: "Na2daYRM"
            }
          ],
        },
        executeCondition: [
          {
            name: "application_fee",
            value: 2150
          },
        ],
      },
      // General non exempted
      {
        condition: {
          action: "assignValue",
          rule: [
            {
              name: "H2z5teV8",
              action: "is",
              value: "mtDK4jig",
            },
            {
              name: "RFBLQxYn",
              action: "is",
              value: "Na2daYRM"
            }
          ],
        },
        executeCondition: [
          {
            name: "application_fee",
            value: 2150
          },
        ],
      },
      // Kashmiri migrants exempted
      {
        condition: {
          action: "assignValue",
          rule: [
            {
              name: "H2z5teV8",
              action: "is",
              value: "mt3DK2jig",
            },
            {
              name: "RFBLQxYn",
              action: "is",
              value: "cx6EABqF"
            }
          ],
        },
        executeCondition: [
          {
            name: "application_fee",
            value: 1150
          },
        ],
      },
      // General exempted
      {
        condition: {
          action: "assignValue",
          rule: [
            {
              name: "H2z5teV8",
              action: "is",
              value: "mtDK4jig",
            },
            {
              name: "RFBLQxYn",
              action: "is",
              value: "cx6EABqF"
            }
          ],
        },
        executeCondition: [
          {
            name: "application_fee",
            value: 1150
          },
        ],
      },
      // SC non exempted
      {
        condition: {
          action: "assignValue",
          rule: [
            {
              name: "H2z5teV8",
              action: "is",
              value: "mtDK9jig",
            },
            {
              name: "RFBLQxYn",
              action: "is",
              value: "Na2daYRM"
            }
          ],
        },
        executeCondition: [
          {
            name: "application_fee",
            value: 1650
          },
        ],
      },
      // ST non exempted
      {
        condition: {
          action: "assignValue",
          rule: [
            {
              name: "H2z5teV8",
              action: "is",
              value: "mtDK6jig",
            },
            {
              name: "RFBLQxYn",
              action: "is",
              value: "Na2daYRM"
            }
          ],
        },
        executeCondition: [
          {
            name: "application_fee",
            value: 1650
          },
        ],
      },
      // DA non exempted
      {
        condition: {
          action: "assignValue",
          rule: [
            {
              name: "H2z5teV8",
              action: "is",
              value: "mcDK9jig",
            },
            {
              name: "RFBLQxYn",
              action: "is",
              value: "Na2daYRM"
            }
          ],
        },
        executeCondition: [
          {
            name: "application_fee",
            value: 1650
          },
        ],
      },
      // SC exempted
      {
        condition: {
          action: "assignValue",
          rule: [
            {
              name: "H2z5teV8",
              action: "is",
              value: "mtDK9jig",
            },
            {
              name: "RFBLQxYn",
              action: "is",
              value: "cx6EABqF"
            }
          ],
        },
        executeCondition: [
          {
            name: "application_fee",
            value: 650
          },
        ],
      },
      // DA exempted
      {
        condition: {
          action: "assignValue",
          rule: [
            {
              name: "H2z5teV8",
              action: "is",
              value: "mcDK9jig",
            },
            {
              name: "RFBLQxYn",
              action: "is",
              value: "cx6EABqF"
            }
          ],
        },
        executeCondition: [
          {
            name: "application_fee",
            value: 650
          },
        ],
      },
      // ST exempted
      {
        condition: {
          action: "assignValue",
          rule: [
            {
              name: "H2z5teV8",
              action: "is",
              value: "mtDK6jig",
            },
            {
              name: "RFBLQxYn",
              action: "is",
              value: "cx6EABqF"
            }
          ],
        },
        executeCondition: [
          {
            name: "application_fee",
            value: 650
          },
        ],
      },

    ];

    let flag = false;
    for (let rule of ruleArray) {
      if (rule.condition.action === "assignValue") {
        let allRulesSatisfied = rule.condition.rule.every(conditionRule => {
          let fieldValue = model[conditionRule.name]?.id || model[conditionRule.name];
          if (conditionRule.action === "is") {
            return fieldValue === conditionRule.value;
          } else {
            return fieldValue !== conditionRule.value;
          }
        });

        if (allRulesSatisfied) {
          model[rule.executeCondition[0]?.name] = rule.executeCondition[0]?.value;
          dispatch(setModel(model))
          return true; // Return true if a rule is applied
        }
      }
    }

    return false; // Return false if no rule is applied

    // Perform additional actions here if needed
  };

  const functionMap: { [key: string]: Function } = {
    basicDetailsFunctionForSiu: () => {
      if (!isThirteen(model["ToYue2gE"])) {
        setLoading(false)
        dispatch(showSnackBar({
          open: true,
          message: "You are not eligible for this form as you are not 13 years old",
          severity: "error",
        }))

        return true
      }
    },
    academicDetailsFunctionForSiu: () => {
      setPaymentValue()
      const isConditionMet =
        (model['tBACempX']?.id ? model['tBACempX']?.id : model['tBACempX']) === "8HWLUeht" ||
        ((model["UVGQF7rU"]?.id ? model["UVGQF7rU"]?.id : model["UVGQF7rU"]) === "Q5AnXSYn" &&
          (model["PUQvtaoe"]?.id ? model["PUQvtaoe"]?.id : model["PUQvtaoe"]) === "j9eVQXgD");

      if (isConditionMet) {
        if (!checkSecondStepValidation()) {
          if (!checkSecondStepValidationForPG2()) {
            setLoading(false);
            dispatch(
              setPopupData({
                open: true,
                message: "You are not eligible for this form please refer eligibility criteria",
                severity: "error",
              })
            );
            return true;
          }
        }
      }
    },
    facultypublicationsFunctionForSiu: () => {
      setPaymentValue()
    }
    // Add more functions as needed
  };
  const executeAsyncFunctions = async () => {
    for (let index = 0; index < config.length; index++) {
      const res = config[index];
      if (index === Number(currentStage)) {
        const onCompleteFunctionName = res?.onComplete;
        if (onCompleteFunctionName) {
          if (await functionMap[onCompleteFunctionName]()) {
            return true;
          }
        } else {
          console.error(`Function ${onCompleteFunctionName} is not defined or not a function.`);
        }
      }
    }
    return false; // Return false if none of the functions return true
  };
  const goToNextStep = async (value: boolean) => {
    if (value) {
      let data = { ...defaultValue, ...model, };      
      let stage;
      let stage_list: any[] = [];
      navBar.map((res: any, index: any) => {
        stage_list.push(res.name);
        if (index === Number(currentStage)) {
          stage = res.name;
        }
      });
      Object.keys(data).map((key) => {
        if (data[key]?.type === "file" || typeof data[key] === "object") {
          data[key] = data[key]?.id;
        }
      });
      let mobileNumberId = findObjectByName(config,'mobile_phone_number')
      data[mobileNumberId] = data[mobileNumberId]?.replaceAll("+",'')
      delete data['payment_status']
      delete data['payment_mode']
      delete data['payment_initiated']
      delete data['payment_details']
      delete data['application_fee']
      delete data['Pk5jz4BY']
      delete data['AoZDDR7m']
      let payload = {
        fields: data,
        stage: stage,
        stage_list: stage_list,
        application_response_id: id,
      };
      dispatch(FormAction.updateValue(payload))
        .then((res: any) => {
          // if (res?.payload?.data?.Status) {
          //   navigate.push(`/thankyou`, { state: { scri_id: modelData['applicant_id'], name: modelData[findObjectByName(config, "name")]?.trim() } });
          // }
        })
        .catch((error: any) => {
          dispatch(showSnackBar({
            open: true,
            message: "Something went wrong Please try again",
            severity: "error",
          }))
          console.error("error", error);
        });
    } else {

      dispatch(setOnSumbit(true));
      hideFields.forEach((key: any) => {
        if (errorModelData.hasOwnProperty(key)) {
          delete errorModelData[key];
        }
      });
      readOnlyFields.forEach((key: any) => {
        if (errorModelData.hasOwnProperty(key)) {
          delete errorModelData[key];
        }
      });

      if (isEmptyObject(errorModelData)) {
        // setLoading(true)
        dispatch(setHideFields([]));
        const asyncFunctionResult = await executeAsyncFunctions();
        if (asyncFunctionResult) {
          return true; // Return true if executeAsyncFunctions returns true
        }
        let data = { ...defaultValue, ...model, };
        let stage;
        let stage_list: any[] = [];
        navBar.map((res: any, index: any) => {
          stage_list.push(res.name);
          if (index === Number(currentStage)) {
            stage = res.name;
          }
        });     
        Object.keys(data).map((key) => {
          if (data[key]?.type === "file" || typeof data[key] === "object") {
            data[key] = data[key]?.id;
          }
        });
        let mobileNumberId = findObjectByName(config,'mobile_phone_number')
        data[mobileNumberId] = data[mobileNumberId]?.replaceAll("+",'')
        setShowProgressBar(true)
        delete data['Pk5jz4BY']
        delete data['AoZDDR7m']
        let payload = {
          fields: data,
          stage: stage,
          stage_list: stage_list,
          application_response_id: id,
        };
        dispatch(FormAction.updateValue(payload))
          .then((res: any) => {
            if (res?.payload?.data?.Status) {
              var myElement = document.getElementById("body-form");
              if (myElement) {
                myElement.scrollTo(0, 0);
              }
              dispatch(resetModelAndErrorModel({}));
              // dispatch(resetHideFieldsAndReadonlyFields({}))
              setConfig([]);
              dispatch(setFieldJSONConfig(FieldJSONConfig));
              getApplicationResponse(id);
              setShowProgressBar(true)
              if (value) {
                // navigate.push(`/thankyou`, {
                //   state: {
                //     scri_id: modelData['applicant_id'], name: modelData[findObjectByName(config, "name")]?.trim()
                //   }
                // });
              } else {
                dispatch(setCurrentStage(Number(currentStage) + 1));
                let step = Number(currentStage) + 1;
                navigate.push("/form/" + step + "/" + id, { replace: true });
              }
            }
          })
          .catch((error: any) => {
            console.error("error", error);
          });
      } else {
        setTimeout(() => {
          let element = document.querySelector(".scrollTOError") as HTMLElement;
          if (element) {
            // If the element is an input, directly scroll and focus it
            if (element.tagName.toLowerCase() === 'input') {
              element.scrollIntoView({ behavior: "smooth" });
              element.focus();
            } else {
              // If the element is a container, find the input inside it
              let inputElement = element.querySelector('input');
              if (inputElement) {
                // Scroll the container into view
                element.scrollIntoView({ behavior: "smooth" });
                // Add focus to the input element
                inputElement.focus();
              } else {
                console.error("Input element not found inside the container with class 'scrollTOError'");
              }
            }
            // Add the class "shake-container"
            element.classList.add('shake-container');
            // Remove the class "shake-container" after 2 seconds
            setTimeout(() => {
              element.classList.remove('shake-container');
            }, 2000);
          } else {
            console.error("Element with class 'scrollTOError' not found");
          }
        }, 100);
      }
    }

  };
  const goToNextStepToNevBar = (value: any) => {
    if (value < Number(currentStage)) {
      dispatch(setOnSumbit(true));
      // if (isEmptyObject(errorModelData)) {
      dispatch(setCurrentStage(value));
      dispatch(resetModelAndErrorModel({}));
      setShowProgressBar(true)

      navigate.push("/form/" + Number(value) + "/" + id, { replace: true });
      setConfig([]);
      setTimeout(() => {
        setShowProgressBar(false)
      }, 3000)
      // }
      var myElement = document.getElementById("body-form");
      if (myElement) {
        myElement.scrollIntoView();
      }
    }

  };
  const goToPrevious = () => {
    setShowProgressBar(true)
    document.getElementById("body-form")?.scrollTo(0, 0);
    getApplicationResponse(id);
    setLoading(true);
    dispatch(setCurrentStage(currentStage - 1));
    let step = currentStage - 1;
    navigate.push("/form/" + step + "/" + id, { replace: true });
    dispatch(resetModelAndErrorModel({}));
    setConfig([]);
    dispatch(setHideFields([]));
  };

  const [pluginLoaded, setPluginLoaded] = useState(false);
  // useEffect(() => {
  //   if(modelData["payment_status"] === "completed" && !modelData["allow_resubmission"]){
  //     goToNextStep(true);
  //   }
  // },[modelData["payment_status"],modelData["allow_resubmission"]])
  useEffect(() => {
    const loadPlugin = async () => {
      try {
        const response = await fetch(
          "https://ebz-static.s3.ap-south-1.amazonaws.com/easecheckout/v2.0.0/easebuzz-checkout-v2.min.js",
        );
        const scriptContent: any = await response.text();

        // Create a <script> element and insert the plugin code
        const scriptElement = document.createElement("script");
        scriptElement.textContent = scriptContent;

        // Append the <script> element to the document body
        document.body.appendChild(scriptElement);
        // Plugin is now loaded
        setPluginLoaded(true);
      } catch (error) {
        console.error("Failed to load plugin:", error);
      }
    };

    // Call the function to load the plugin when the component mounts
    loadPlugin();
  }, []);
  const setPayment = async (e: any) => {
    e.preventDefault();
    setShowProgressBar(true)
    setPaymentModel(false)
    if (selectedOption === "easebuzz") {
      const easebuzzCheckout = new (window as any).EasebuzzCheckout(
        "VNK6UL53DO",
        "prod",
      ); // for test enviroment pass "test"
      let data: any = {
        application_id: modelData['applicant_id'],
        firstname: modelData[findObjectByName(config, "name")]?.trim(),
        phone: modelData[findObjectByName(config, "mobile_phone_number")],
        email: modelData[findObjectByName(config, "email")],
        amount: modelData['application_fee'] ?  modelData['application_fee']  : 1,

        // amount: 1,
        success_url: window.location.href,
        failure_url: window.location.href,
        paymentGetway: "easebuzz"
      };
      FormNetworkService.initiatePayment(data).then((res: any) => {
        var options = {
          access_key: res.data.data, // access key received via Initiate Payment
          onResponse: (response: any) => {
            if (response.success === false || response.status !== "success") {
              dispatch(
                showSnackBar({
                  open: true,
                  message: "Payment Failure",
                  severity: "error",
                }),
              );
              window.location.reload();
              return false;
            }
            FormNetworkService.markPaymentOnline(
              {
                transaction_id: response.txnid,
                amount: response.net_amount_debit,
                metadata: response,
              },
              id,
            ).then((res: any) => {
              goToNextStep(true);
            });
          },
          theme: "##ff0000", // color hex
        };
        easebuzzCheckout.initiatePayment(options);
      });
    } else {
      let data: any = {
        application_id: modelData['applicant_id'],
        firstname: modelData[findObjectByName(config, "name")].trim(),
        phone: modelData[findObjectByName(config, "mobile_phone_number")],
        email: modelData[findObjectByName(config, "email")],
        amount: modelData['application_fee'] ?  modelData['application_fee']  : 1,
        // amount: 1,
        success_url: window.location.href,
        failure_url: window.location.href,
        paymentGetway: "billdesk"
      };
      dispatch(FormAction.initiatePayment(data))
        .then(async (res: any) => {
          try {
            // const formData = new FormData();
            // formData.append('bdorderid', res.payload?.data?.parameters?.bdorderid);
            // formData.append('merchantid', res.payload?.data?.parameters?.mercid);
            // formData.append(
            //   'rdata',
            //   res.payload?.data?.parameters?.rdata
            // );
            const form: any = formRef.current;
            if (form) {
              form.action = 'https://uat1.billdesk.com/u2/web/v1_2/embeddedsdk';
              form.method = 'POST';
            }
            const merchantIdElement = document.getElementById('merchantid') as HTMLInputElement;
            const bdOrderIdElement = document.getElementById('bdorderid') as HTMLInputElement;
            const rDataElement = document.getElementById('rdata') as HTMLInputElement;

            // Check if elements are found and API response is valid
            if (merchantIdElement && bdOrderIdElement && rDataElement &&
              res.payload?.data?.parameters?.mercid &&
              res.payload?.data?.parameters?.bdorderid &&
              res.payload?.data?.parameters?.rdata) {
              merchantIdElement.value = res.payload.data.parameters.mercid;
              bdOrderIdElement.value = res.payload.data.parameters.bdorderid;
              rDataElement.value = res.payload.data.parameters.rdata;
              const form = document.getElementById('sdklaunch') as HTMLFormElement;
              form.submit();


              // Now submit the form programmatically
              // e.target.submit();
            } else {
              console.error('One or more form elements not found or API response invalid.');
            }
            //   const response = await fetch(
            //     'https://uat1.bil66666666666666666666666666666666666desk.com/u2/web/v1_2/embeddedsdk',
            //     {
            //       method: 'POST',
            //       body: formData,
            //     }
            //   );

            //   if (!response.ok) {
            //     throw new Error('Failed to fetch HTML content');
            //   }
            //   const html = await response.text();

            //   const modifiedHtml = `
            //   <html>
            //     <head>
            //       <style>
            //         /* Add your CSS overrides here */
            //         .your-class-name {
            //           /* Example CSS override for elements with class 'your-class-name' */
            //           color: red;
            //           font-size: 20px;
            //         }

            //         #your-element-id {
            //           /* Example CSS override for element with id 'your-element-id' */
            //           background-color: blue;
            //         }
            //       </style>
            //     </head>
            //     <body>
            //       ${html}
            //     </body>
            //   </html>
            // `;
            //   setHtmlContent(modifiedHtml);
            //   setShowModal(true)
            //   setShowProgressBar(false)
          } catch (error) {
            setShowProgressBar(false)
            console.error('Error:', error);
          }

        })
    }
    // Handle the response as needed
    // const easebuzzCheckout = new (window as any).EasebuzzCheckout(
    //   "VNK6UL53DO",
    //   "prod",
    // ); // for test enviroment pass "test"

  };


  return (
    <>

      <AppModal show={showModal} onHide={() => { setShowModal(false) }} size={undefined}>
        <UploadModalWrapper>
          <CloseButton onClick={() => { setShowModal(false) }} >&times;</CloseButton>

          {htmlContent ?
            <ModalContent>

              <iframe
                ref={iframeRef}

                title="Embedded SDK"
                srcDoc={htmlContent}
                style={{ width: '100%', height: 'calc(83vh - 99px)', border: 'none' }}
              />        </ModalContent>
            : null}
        </UploadModalWrapper>
      </AppModal>

      <BodyWrapper>
        <div className="col-md-12">

          <div className="card">
            <div className="body" style={{ marginBottom: "99px",zIndex:1,position:"relative" }}>
              <ul className="nav nav-tabs-new m-b-20 top-tab" style={{ display: isMobile ? "contents" : "flex", marginTop: "19px" }}>
                {navBar.map((nav: any, index: number) => {
                  return (
                    <li
                      className={`nav-item mr-1 ${index > currentStage && "disabled"
                        }`}
                      onClick={() => goToNextStepToNevBar(index)}
                    >
                      <a
                        className={`nav-link ${Number(currentStage) === index ? "active" : "alert-success"
                          } ${index > Number(currentStage) ? "disabled" : ""}`}
                        style={{
                          backgroundColor: index < Number(currentStage) ? "#d4edda" : ""
                        }}
                        onClick={() => goToNextStepToNevBar(index)}
                      >
                        {nav.label}
                      </a>
                    </li>
                  );
                })}
              </ul>
              {showProgressBar ? (
                <div
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)", // Translucent black overlay
                    zIndex: 9999, // Ensure it's above other elements
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      padding: "16px",
                      textAlign: "center",
                      fontSize: "15px",
                      // backgroundColor: "antiquewhite",
                      color: "white",
                      borderRadius: "8px",
                    }}
                  >
                    Please wait, we are processing your request...
                  </div>
                </div>
              ) : null}
              {checkInternet ? (
                <div
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)", // Translucent black overlay
                    zIndex: 9999, // Ensure it's above other elements
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      padding: "16px",
                      textAlign: "center",
                      fontSize: "15px",
                      // backgroundColor: "antiquewhite",
                      color: "white",
                      borderRadius: "8px",
                    }}
                  >
                    You are offline. Please connect to the internet to continue.
                  </div>
                </div>
              ) : null}

              {/* <form
       
                className="ng-untouched ng-dirty ng-invalid body-form"
                id="body-form"
                style={{ display: showProgressBar ? "none" : "block" }}
              > */}
              {/* {modelData['allow_resubmission'] && Number(currentStage) === 0 &&
                <div style={{
                  overflow: "auto"
                }}> <div style={{
                  padding: "9px",
                  fontSize: "15px",
                  backgroundColor: "whitesmoke",
                  color: "green"
                }}>Not Allow to Edit Nationality,Country Represented & Category. If you want to edit please contact 9191919191 </div> </div>
              } */}
              <React.Fragment>
                {config?.[currentStage]?.child?.map((fields: any) => {
                  return (
                    <React.Fragment>
                      {!fields?.is_hidden &&
                        !hideFields.includes(fields.name) && (
                          <div
                            className="alert alert-secondary m-t-20"
                            role="alert"
                          >
                            {fields.label}
                            {
                              fields?.note && <span className="text-danger ml-3">{fields.note}</span>
                            }
                          </div>
                        )}
                      {!fields?.is_hidden &&
                        !hideFields.includes(fields.name) &&
                        fields?.rows?.childs?.map((row: any) => {
                          return (
                            <div className="row">
                              {row?.fields?.map((field: any) => {
                                let length =
                                  row?.fields?.filter(
                                    (field: any) => !field?.is_hidden,
                                  )?.length / 12;
                                return (
                                  <React.Fragment>
                                    {(!hideFields.includes(field.id)
                                    ) ||
                                      (modelData.hasOwnProperty(field?.name || field?.id) &&
                                        !field?.read_only) ? (
                                      field?.type !== "table" ? (
                                        <div
                                          className={
                                            length === 12
                                              ? "col-lg-12 col-md-12"
                                              : length === 6
                                                ? "col-lg-6 col-md-6"
                                                : length === 4
                                                  ? "col-lg-4 col-md-4"
                                                  : "col-lg-3 col-md-3"
                                          }
                                        >
                                          <Field
                                            field={field}
                                            sectionId={fields?.name}
                                            setLoading={setLoading}
                                          ></Field>
                                        </div>
                                      ) : (
                                        <AppTable
                                          headers={field?.header_value}
                                          listing={field?.config}
                                          isPaymentSection={
                                            field?.payment_section
                                          }
                                          isButton={false}
                                          buttonText="Add Column"
                                        />
                                      )
                                    ) : null}
                                  </React.Fragment>
                                );
                              })}
                              <br />
                            </div>
                          );
                        })}
                    </React.Fragment>
                  );
                })}
                <br />
              </React.Fragment>
              <br />
              {/* </form> */}

              <div className=" footer" style={{
                position: "fixed",
                bottom: "0",
                left: "0",
                width: "100%",
                backgroundColor: "white",
                zIndex:0
              }}>
                <div className="btn-wrapper">
                  {currentStage !== 0 && (
                    <button
                      className="btn btn-danger"
                      onClick={() => goToPrevious()}
                      disabled={currentStage === 0}
                    >
                      Previous
                    </button>
                  )}

                  {isPaymentSectionData && modelData['payment_mode'] !== "offline" && modelData["payment_status"] !== "completed" && !modelData["payment_initiated"] ? (
                    // <button
                    //   className="btn btn-success"
                    //   id="ebz-checkout-btn"
                    //   name='submit'
                    //   type='submit'
                    //   onClick={setPayment}
                    //   disabled={modelData["payment_status"] === "completed" ? true : false}
                    // >
                    //   Proceed to Pay
                    // </button>
                    <>
                      <form name="sdklaunch" id="sdklaunch"
                        action="https://uat1.billdesk.com/u2/web/v1_2/embeddedsdk" method="POST">
                        <input type="hidden" id="bdorderid" name="bdorderid" />
                        <input type="hidden" id="merchantid" name="merchantid" />
                        <input type="hidden" id="rdata" name="rdata" />
                      </form>
                      <button
                        className="btn btn-success"
                        id="ebz-checkout-btn"
                        onClick={() => setPaymentModel(true)}
                        // onClick={(e) => setPayment(e)}
                        disabled={modelData["payment_status"] === "completed" ? true : false}
                      >Proceed to Pay
                      </button>
                    </>
                    // </form>
                  ) : (
                    modelData["payment_initiated"] && modelData["payment_status"] === "pending" && isPaymentSectionData ? <>

                    </> : <button
                      className="btn btn-success"
                      // disabled={!isEmptyObject(errorModelData) || currentStage === navBar.length - 1}
                      onClick={() => {
                        goToNextStep(navBar.length === Number(currentStage) + 1);
                      }}
                      disabled={modelData["payment_status"] === null ? true : false}
                    >
                      {navBar.length === Number(currentStage) + 1
                        ? "Sumbit"
                        : "Next"}
                      {navBar.length === currentStage + 1 ? (
                        ""
                      ) : (
                        <i
                          style={{ marginLeft: "10px" }}
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>
                      )}
                    </button>
                  )}
                </div>
                {/* <Toaster /> */}
              </div>
            </div>
          </div>
          <AppModel onClose={() => setPaymentModel(false)} isOpen={paymentModel} width="515px">
            {/* <GridContainer>
              <OptionContainer
                selected={selectedOption === "easebuzz"}
                onClick={() => setSelectedOption("easebuzz")}
              >
                <img src={Easebuzz} alt="Easebuzz" />
              </OptionContainer>
            </GridContainer> */}
            <Note>
              * NOTE: The payment date for PhD Application has passed away. you can not proceed further.
            </Note>
            <ActionButtons>
              <button
                className="btn btn-secondary"

                onClick={() => setPaymentModel(false)}
              >
                Close
              </button>
              {/* <button
                disabled={!selectedOption}
                onClick={(e) => setPayment(e)}
                className="btn btn-success"
              >
                Continue
              </button> */}
            </ActionButtons>
          </AppModel>
        </div>
      </BodyWrapper>
    </>
  );
};
// export default FormConfigRenderer;
export default withLoader(FormConfigRenderer);
const UploadModalWrapper = styled.div`
position: relative;
  && {
    .modal-dialog {
      // max-height: 400px;
      // overflow-x: auto;
      max-width: 500px !important;
    }
    ..modal-lg .modal-xl{
      max-width: 500px !important;
    }
  }
`;

const ModalContent = styled.div`
  background-color: #fff; /* Set your desired background color */
`;
const CloseButton = styled.button`
position: absolute;
right: 0px;
width: 48px;
bottom: 99%;
background: none;
border: none;
background-color: wwho;
cursor: pointer;
font-size: 19px;
color: white;
padding: -1px;
border: 2px solid white;
`;

const BodyWrapper = styled.div`
          && {
            // height: calc(100vh - 112px);
            margin - top: 27px;
          // overflow: auto;
          .card {
            margin: 0px;
    }
          .body {
            padding: 0px;
    }
          .top-tab {
            height: 60px;
          display: flex;
          // justify-content: center;
          align-items: center;
          padding: 0 16px;
          position: relative;
          z-index: 1;
          margin: 0;
          box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px;
    }
          .footer {
            height: 60px;
          padding: 0 16px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          box-shadow: rgba(0, 0, 0, 0.08) 0px -2px 4px;
          position: relative;
          z-index: 1;
    }
          .btn-wrapper {
            display: flex;
          gap: 8px;
          justify-content: flex-end;
    }
          .body-form {
            max - height: calc(100vh - 235px);
          min-height: calc(100vh - 235px);
          overflow: auto;
          padding: 16px;
    }
  }
          `;
const GridContainer = styled.div`
          grid-template-columns: 1fr 1fr;
          margin-top: 20px;
          Display: flex;
          gap: 26px;
         justify-content: center;
        `;

const OptionContainer = styled.div<OptionContainerProps>`
          display: flex;
          justify-content: center;
          align-items: center;
          border: ${(props: any) => (props.selected ? "4px solid darkgreen" : "1px solid black")};
          width: 230px;
          height: 230px;
          cursor: pointer;
          transition: border 0.3s ease;
        
          img {
            width: 150px;
            height: auto;
          }
        `;

const ActionButtons = styled.div`
          display: flex;
          justify-content: flex-end;
          margin-top: 55px;
        
          .btn {
            padding: 10px 20px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
          }
        
          .btn-secondary {
            background-color: white;
            border: 1px solid lightgray;
            color: black;
            margin-right: 10px;
          }
        
          .btn-success {
            background-color: #28a745;
            color: white;
          }
        `;
const Note = styled.p`
&&{
  font-size: 14px;
  color: red;
  margin-top: 3rem;
}`;