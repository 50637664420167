import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FormAction } from "./form.actions";
interface initialStateTypes {
    onSubmit: boolean;
    FieldJSONConfig: any
    model: any
    errorModel: any
    allCounty: any
    allState: any
    currentStage: number;
    portalUserDetails: any;
    createApplicationFormResponse: any;
    portalUserId: any;
    allApplicationsFormList: any;
    allApplicationResponseList: any;
    applicationResponseById: any;
    optionMappingData: any;
    selectApplicationDetails: any,
    hideFields: any;
    snackBarData: any;
    contactId: any;
    uploadDocumentData: any;
    popupData: any;
    isPaymentSection: any;
    readOnlyFields: any;
    hideOptionIds: any;
}
const initialState: initialStateTypes = {
    onSubmit: false,
    FieldJSONConfig: [],
    model: {},
    errorModel: {},
    allCounty: [],
    allState: [],
    currentStage: 0,
    // portal details
    portalUserDetails: {},
    createApplicationFormResponse: {},
    portalUserId: "",
    // form list
    allApplicationsFormList: {},
    // user form list
    allApplicationResponseList: {},
    // selet application details
    selectApplicationDetails: {},
    // set contact id
    contactId: "",
    applicationResponseById: {},
    optionMappingData: {},
    hideFields: [],
    readOnlyFields: [],
    snackBarData: {},
    uploadDocumentData: {},
    isPaymentSection: false,
    popupData: {},
    hideOptionIds : {}
}
const FormSlice = createSlice({
    name: "FormSlice",
    initialState,
    reducers: {
        setOnSumbit: (state: any, action?: PayloadAction<any>) => {
            state.onSubmit = action?.payload;
            return state;
        },
        setFieldJSONConfig: (state: any, action?: PayloadAction<any>) => {
            state.FieldJSONConfig = action?.payload;
            return state;
        },
        setModel: (state: any, action?: PayloadAction<any>) => {
            state.model = { ...state.model, ...action?.payload };
            return state;
        },
        setErrorModel: (state: any, action?: PayloadAction<any>) => {
            state.errorModel = { ...action?.payload, ...state.errorModel };
            return state;
        },
        setRemoveErrorModel: (state: any, action?: PayloadAction<any>) => {
            state.errorModel = {...action?.payload};
            return state;
        },
        setCurrentStage: (state: any, action?: PayloadAction<any>) => {
            state.currentStage = action?.payload;
            return state;
        },
        setPortalUserId: (state: any, action?: PayloadAction<any>) => {
            state.portalUserId = action?.payload;
            return state;
        },
        setOptionsData: (state: any, action?: PayloadAction<any>) => {
            state.optionMappingData = { ...state.optionMappingData, ...action?.payload };
            return state;
        },
        setHideFields: (state: any, action?: PayloadAction<any>) => {

            if (action?.payload?.key === "show") {
                let value = state.hideFields?.filter((item: any) => item !== action?.payload?.name)
                state.hideFields = [...value];

            } else {
                state.hideFields = [...state.hideFields, ...action?.payload];

            }

            return state;
        },
        setReadOnlyFields: (state: any, action?: PayloadAction<any>) => {
            if (action?.payload?.key === "show") {
                let value = state.hideFields?.filter((item: any) => item !== action?.payload?.name)
                state.readOnlyFields = [...value];

            } else {
                state.readOnlyFields = [...state.readOnlyFields, ...action?.payload];

            }

            return state;
        },
        resetModelAndErrorModel: (state: any, action?: PayloadAction<any>) => {
            state.errorModel = {};
            // state.model = {};
            return state;
        },
        showSnackBar: (state: any, action?: PayloadAction<any>) => {
            state.snackBarData = action?.payload;
            return state;
        },
        setSelectApplicationDetails: (state: any, action?: PayloadAction<any>) => {
            state.selectApplicationDetails = action?.payload;
            return state;
        },
        setContactId: (state: any, action?: PayloadAction<any>) => {
            state.contactId = action?.payload;
            return state;
        },
        setPaymentSection: (state: any, action?: PayloadAction<any>) => {
            state.isPaymentSection = action?.payload;
            return state;
        },
        setPopupData: (state: any, action?: PayloadAction<any>) => {
            state.popupData = action?.payload;
        },
        resetHideFieldsAndReadonlyFields : (state: any, action?: PayloadAction<any>) => {
            state.readOnlyFields = []
            state.hideFields = []
        },
        setHideOptionIds : (state: any, action?: PayloadAction<any>) => {            
            state.hideOptionIds = {...state?.hideOptionIds,...action?.payload};
            return state;
        }
    },
    extraReducers: (builder) => {
        builder
            // Get All County
            .addCase(
                FormAction.getAllCountry.pending,
                (state, action) => {
                    // state.allCounty= []
                }
            )
            .addCase(
                FormAction.getAllCountry.fulfilled,
                (state, action) => {
                    state.allCounty = action.payload.data?.data?.options;

                }
            )
            .addCase(
                FormAction.getAllCountry.rejected,
                (state, action) => {
                    state.allCounty = []
                }
            )


            // Get All State
            .addCase(
                FormAction.getAllState.pending,
                (state, action) => {
                    // state.allCounty= []
                }
            )
            .addCase(
                FormAction.getAllState.fulfilled,
                (state, action) => {
                    state.allState = action.payload.data?.data?.options;

                }
            )
            .addCase(
                FormAction.getAllState.rejected,
                (state, action) => {
                    state.allCounty = []
                }
            )

            // Get Portal User Details
            .addCase(
                FormAction.getPortalUserDetails.pending,
                (state, action) => {
                    state.portalUserDetails = false;
                }
            )
            .addCase(
                FormAction.getPortalUserDetails.fulfilled,
                (state, action) => {
                    state.portalUserDetails = action?.payload?.data?.data;
                }
            )

            .addCase(
                FormAction.getPortalUserDetails.rejected,
                (state, action) => {
                    state.portalUserDetails = {};
                }
            )

            // Create Form Application
            .addCase(
                FormAction.createFormApplication.pending,
                (state, action) => {
                    state.createApplicationFormResponse = true;
                }
            )
            .addCase(
                FormAction.createFormApplication.fulfilled,
                (state, action) => {
                    state.createApplicationFormResponse = action?.payload?.data?.data;
                }
            )
            .addCase(
                FormAction.createFormApplication.rejected,
                (state, action) => {
                    state.createApplicationFormResponse = {};
                }
            )

            //get Application Response List
            .addCase(
                FormAction.getApplicationFormsList.pending,
                (state, action) => {
                    state.allApplicationsFormList = true;
                }
            )

            .addCase(
                FormAction.getApplicationFormsList.fulfilled,
                (state, action) => {
                    state.allApplicationsFormList = action?.payload?.data;
                }
            )
            .addCase(
                FormAction.getApplicationFormsList.rejected,
                (state, action) => {
                    state.allApplicationsFormList = {};
                }

            )

            //get Application Form Response List
            .addCase(
                FormAction.getApplicationFormsResponseList.pending,
                (state, action) => {
                    state.allApplicationResponseList = true;
                }
            )

            .addCase(
                FormAction.getApplicationFormsResponseList.fulfilled,
                (state, action) => {
                    state.allApplicationResponseList = action?.payload?.data;
                }
            )
            .addCase(
                FormAction.getApplicationFormsResponseList.rejected,
                (state, action) => {
                    state.allApplicationResponseList = {};
                }

            )

            //get Application Response By Id
            .addCase(
                FormAction.getApplicationResponseById.pending,
                (state, action) => {
                    state.applicationResponseById = true;
                }
            )
            .addCase(
                FormAction.getApplicationResponseById.fulfilled,
                (state, action) => {
                    state.applicationResponseById = action?.payload?.data;
                }
            )
            .addCase(
                FormAction.getApplicationResponseById.rejected,
                (state, action) => {
                    state.applicationResponseById = {};
                }

            )

            //Upload Document
            .addCase(
                FormAction.uploadDocument.pending,
                (state, action) => {
                    state.uploadDocumentData = true;
                }
            )

            .addCase(
                FormAction.uploadDocument.fulfilled,
                (state, action) => {
                    state.uploadDocumentData = action?.payload?.data;
                }
            )
            .addCase(
                FormAction.uploadDocument.rejected,
                (state, action) => {
                    state.uploadDocumentData = {};
                }

            )
    }
})
export const { setOnSumbit, setFieldJSONConfig, setModel, setErrorModel, setRemoveErrorModel, setCurrentStage, setPortalUserId, setOptionsData, resetModelAndErrorModel, setHideFields, showSnackBar, setSelectApplicationDetails, setContactId, setPaymentSection, setPopupData, setReadOnlyFields,resetHideFieldsAndReadonlyFields,setHideOptionIds } = FormSlice.actions;
export default FormSlice.reducer;
