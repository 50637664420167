// Modal.tsx

import React from 'react';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';
import styled from 'styled-components';

interface ModalProps {
    show: boolean;
    onHide: () => void;
    title?: string;
    body?: React.ReactNode;
    onSave?: () => void;
    children?: React.ReactNode;
    size?: 'sm' | 'lg' | "xl";
}

const AppModal: React.FC<ModalProps> = ({ show, onHide, title, body, onSave, children, size,  }) => {
    return (
        <BootstrapModal show={show} onHide={onHide} centered size={size}  backdrop="static">
            <ModalWrapper>
                {children}
            </ModalWrapper>
        </BootstrapModal>
    );
};

export default AppModal;

const ModalWrapper = styled.div`
&& {
  .modal-body {
    padding: 24px;
  }
  .modal-header {
    background-color: #CCE5FF;
    .modal-title {
        color: #004085;
    }
  } 
  .instructionlist {
    padding-left: 16px;
    li {
      margin-bottom: 10px;
    }
  }
}
`;