import React, { useState, useEffect } from "react";
import { Nav } from "react-bootstrap";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  onPressDashbord,
  onPressDashbordChild,
  onPressEqualizer,
  onPressGeneralSetting,
  onPressMenuProfileDropdown,
  onPressNotification,
  onPressSideMenuTab,
  onPressSideMenuToggle,
  onPressThemeColor,
  tostMessageLoad,
} from "../actions";
import LogoWhite from "../assets/images/logo-white.svg";
import Logo from "../assets/images/logo.svg";
import Avatar1 from "../assets/images/xs/avatar1.jpg";
import Avatar2 from "../assets/images/xs/avatar2.jpg";
import Avatar3 from "../assets/images/xs/avatar3.jpg";
import Avatar4 from "../assets/images/xs/avatar4.jpg";
import Avatar5 from "../assets/images/xs/avatar5.jpg";
import { FormAction } from "../Store/FormConfig/form.actions";
import { useAppDispatch } from "../Store";
import {
  getAllApplicationFormResponse,
  getCreateApplicationFormResponse,
  getModel,
  getPortalUserId,
} from "../Store/FormConfig/form.selectors";
import { showSnackBar } from "../Store/FormConfig/form.slice";
const { useHistory } = require("react-router-dom");

const NavbarMenu = ({
  addClassactive,
  addClassactiveChildAuth,
  addClassactiveChildMaps,
  themeColor,
  toggleNotification,
  toggleEqualizer,
  sideMenuTab,
  isToastMessage,
  activeKey,
  data,
  onPressSideMenuToggle,
  onPressThemeColor,
  tostMessageLoad,
}) => {
  const [width, setWidth] = useState(0);
  const dispatch = useAppDispatch();
  const model = useSelector(getModel);
  const portalUserId = useSelector(getPortalUserId);
  const allApplicationsResponse = useSelector(getAllApplicationFormResponse);
  const navigate = useHistory();
  const is_user_view = localStorage.getItem("is_user_view");
  let path = window.location.pathname;

  const [applicationResID, setApplicationResID] = useState(null);
  const [applicationResStatus, setApplicationResStatus] = useState("");

  useEffect(() => {
    tostMessageLoad(true);
    const res = window.location.pathname.split("/").slice(-1)[0];
    activeMenutabwhenNavigate("/" + activeKey);
  }, []);

  const activeMenutabwhenNavigate = (activeKey) => {
    // Your navigation logic here
  };

  const toggleWidth = () => {
    setWidth((prevWidth) => (prevWidth === 250 ? 0 : 250));
  };

  const logoutFn = () => {
    dispatch(
      FormAction.logout(portalUserId ? portalUserId : model["portal_user_id"])
    )
      .then((res) => {
        if (res?.payload?.data?.data) {
          dispatch(
            showSnackBar({
              open: true,
              message: res?.payload?.data?.data,
              severity: "success",
            })
          );
          navigate.push("/login", { replace: true });
        }
      })
      .catch((error) => {});
    // logout({});
  };

  useEffect(() => {
    if (allApplicationsResponse?.data?.length > 0) {
      setApplicationResID(
        allApplicationsResponse?.data[0]?.application_response_id
      );
      setApplicationResStatus(allApplicationsResponse.data[0]?.status);
    } else {
      setApplicationResID(null);
    }
  }, [allApplicationsResponse]);

  const navigateToAdmitCard = (id) => {
    const url = `admit-card?id=${id}`;
    window.location.href = url;
  };

  const navigateToReportCard = (id) => {
    const url = `report-card?id=${id}`;
    window.location.href = url;
  };

  const navigateToPISchedule = (id) => {
    const url = `pi-card?id=${id}`;
    window.location.href = url;
  };

  return (
    <div>
      {isToastMessage ? <></> : null}
      <nav
        className="navbar navbar-fixed-top"
        style={{ borderBottom: "1px solid red" }}
      >
        <div className="container-fluid">
          <div className="navbar-btn">
            <button
              className="btn-toggle-offcanvas"
              onClick={() => {
                onPressSideMenuToggle();
              }}
            >
              <i className="lnr lnr-menu fa fa-bars"></i>
            </button>
          </div>

          <div
            className="navbar-brand"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "30px",
            }}
          >
            <button
              style={{
                backgroundColor: "transparent",
                border: "none",
              }}
              onClick={toggleWidth}
              disabled={path?.includes("view") && !is_user_view ? true : false}
            >
              <i className="fa fa-bars" aria-hidden="true"></i>
            </button>

            <a
              href="/"
              style={{
                opacity: path?.includes("view") && !is_user_view ? "0.4" : "1",
                cursor:
                  path?.includes("view") && !is_user_view
                    ? "default !important"
                    : "pointer",
                pointerEvents:
                  path?.includes("view") && !is_user_view && "none",
              }}
            >
              <img
                src={
                  document.body.classList.contains("full-dark")
                    ? LogoWhite
                    : Logo
                }
                alt="Lucid Logo"
                className="img-responsive logo"
              />
            </a>
          </div>

          <div className="navbar-right">
            <div id="navbar-menu">
              <ul
                className="nav navbar-nav"
                style={{
                  opacity:
                    path?.includes("view") && !is_user_view ? "0.4" : "1",
                  cursor:
                    path?.includes("view") && !is_user_view
                      ? "default !important"
                      : "pointer",
                  pointerEvents:
                    path?.includes("view") && !is_user_view && "none",
                }}
              >
                <li
                  disabled={
                    path?.includes("view") && !is_user_view ? true : false
                  }
                >
                  <a className="icon-menu" onClick={() => logoutFn()}>
                    <i
                      className="fa fa-power-off"
                      aria-hidden="true"
                      disabled={true}
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>

      <div
        id="left-sidebar"
        className="sidebar"
        style={{
          backgroundColor: "#fff",
          zIndex: 9,
          height: "calc(100vh - 80px)",
          borderRight: "1px solid red",
          marginTop: "12px",
          width: `${width}px`,
          //  width: width === 0 ? 'auto' : `${width}px`,
          transition: "width 0.5s ease",
        }}
      >
        <div className="sidebar-scroll">
          <div className="user-account">
            <div className="user-box">
              {/* <img
              src={UserImage}
              className="rounded-circle user-photo"
              alt="User Profile Picture"
            /> */}
              <i className="fa fa-user-circle-o" aria-hidden="true"></i>
              <div>
                <div>
                  <span>Welcome,</span>
                  <strong>{data.name}</strong>
                </div>
              </div>
            </div>
          </div>
          <ul className="nav nav-tabs"></ul>
          <div className="tab-content p-l-0 p-r-0">
            <div
              className={sideMenuTab[0] ? "tab-pane active show" : "tab-pane"}
              id="menu"
            >
              <Nav id="left-sidebar-nav" className="sidebar-nav">
                <ul id="main-menu" className="metismenu">
                  {width !== 0 && (
                    <li className="" id="dashboradContainer">
                      <a
                        href="#!"
                        className={activeKey === "dashboard" ? "active" : ""}
                        // onClick={(e) => {
                        //   e.preventDefault();
                        //   activeMenutabContainer("dashboradContainer");
                        // }}
                      >
                        <i className="fa fa-file-text-o" aria-hidden="true"></i>{" "}
                        <span>Application Form</span>
                      </a>
                      {/* <ul className="collapse">
                    <li
                      className={activeKey === "dashboard" ? "active" : ""}
                    >
                      <Link to="/">Application Form </Link>
                    </li>
                  </ul> */}
                    </li>
                  )}

                  {applicationResStatus.toLowerCase() === "submitted" && (
                    <li className="" id="dashboradContainer">
                      <a
                        href="#!"
                        className={activeKey === "admit-card" ? "active" : ""}
                        onClick={(e) => {
                          e.preventDefault();
                          navigateToAdmitCard(
                            applicationResID && applicationResID
                          );
                        }}
                      >
                        <i className="fa fa-id-card-o" aria-hidden="true"></i>
                        <span>Admit Card</span>
                      </a>
                    </li>
                  )}
                  <li className="" id="dashboradContainer">
                    <a
                      href="#!"
                      className={activeKey === "report-card" ? "active" : ""}
                      onClick={(e) => {
                        e.preventDefault();
                        navigateToReportCard(
                          applicationResID && applicationResID
                        );
                      }}
                    >
                      <i className="fa fa-credit-card" aria-hidden="true"></i>
                      <span>Report Card</span>
                    </a>
                  </li>
                  <li className="" id="dashboradContainer">
                    <a
                      href="#!"
                      className={activeKey === "pi-card" ? "active" : ""}
                      onClick={(e) => {
                        e.preventDefault();
                        navigateToPISchedule(
                          applicationResID && applicationResID
                        );
                      }}
                    >
                      <i className="fa fa-user" aria-hidden="true"></i>
                      <span>PI Schedule</span>
                    </a>
                  </li>
                </ul>
              </Nav>
            </div>
            <div
              className={
                sideMenuTab[1]
                  ? "tab-pane p-l-15 p-r-15 show active"
                  : "tab-pane p-l-15 p-r-15"
              }
              id="Chat"
            >
              <form>
                <div className="input-group m-b-20">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="icon-magnifier"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                  />
                </div>
              </form>
              <ul className="right_chat list-unstyled">
                <li className="online">
                  <a>
                    <div className="media">
                      <img className="media-object " src={Avatar4} alt="" />
                      <div className="media-body">
                        <span className="name">Chris Fox</span>
                        <span className="message">Designer, Blogger</span>
                        <span className="badge badge-outline status"></span>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="online">
                  <a>
                    <div className="media">
                      <img className="media-object " src={Avatar5} alt="" />
                      <div className="media-body">
                        <span className="name">Joge Lucky</span>
                        <span className="message">Java Developer</span>
                        <span className="badge badge-outline status"></span>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="offline">
                  <a>
                    <div className="media">
                      <img className="media-object " src={Avatar2} alt="" />
                      <div className="media-body">
                        <span className="name">Isabella</span>
                        <span className="message">CEO, Thememakker</span>
                        <span className="badge badge-outline status"></span>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="offline">
                  <a>
                    <div className="media">
                      <img className="media-object " src={Avatar1} alt="" />
                      <div className="media-body">
                        <span className="name">Folisise Chosielie</span>
                        <span className="message">Art director, Movie Cut</span>
                        <span className="badge badge-outline status"></span>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="online">
                  <a>
                    <div className="media">
                      <img className="media-object " src={Avatar3} alt="" />
                      <div className="media-body">
                        <span className="name">Alexander</span>
                        <span className="message">Writter, Mag Editor</span>
                        <span className="badge badge-outline status"></span>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <div
              className={
                sideMenuTab[2]
                  ? "tab-pane p-l-15 p-r-15 show active"
                  : "tab-pane p-l-15 p-r-15"
              }
              id="setting"
            >
              <h6>Choose Mode</h6>
              <ul className="choose-skin list-unstyled">
                <li
                  data-theme="white"
                  className={
                    document.body.classList.contains("full-dark")
                      ? ""
                      : "active"
                  }
                  onClick={() => {
                    // setState({ somethi: false });
                    document.body.classList.remove("full-dark");
                  }}
                >
                  <div className="white"></div>
                  <span>Light</span>
                </li>
                <li
                  data-theme="black"
                  className={
                    document.body.classList.contains("full-dark")
                      ? "active"
                      : ""
                  }
                  onClick={() => {
                    // setState({ somethi: true });
                    document.body.classList.add("full-dark");
                  }}
                >
                  <div className="black"></div>
                  <span>Dark</span>
                </li>
              </ul>
              <hr />
              <h6>Choose Skin</h6>
              <ul className="choose-skin list-unstyled">
                <li
                  data-theme="purple"
                  className={themeColor === "theme-purple" ? "active" : ""}
                >
                  <div
                    className="purple"
                    onClick={() => {
                      if (themeColor !== "theme-purple") {
                        document.body.classList.remove(themeColor);
                      }
                      onPressThemeColor("purple");
                    }}
                  ></div>
                  <span>Purple</span>
                </li>
                <li
                  data-theme="blue"
                  className={themeColor === "theme-blue" ? "active" : ""}
                >
                  <div
                    className="blue"
                    onClick={() => {
                      if (themeColor !== "theme-blue") {
                        document.body.classList.remove(themeColor);
                      }
                      onPressThemeColor("blue");
                    }}
                  ></div>
                  <span>Blue</span>
                </li>
                <li
                  data-theme="cyan"
                  className={themeColor === "theme-cyan" ? "active" : ""}
                >
                  <div
                    className="cyan"
                    onClick={() => {
                      if (themeColor !== "theme-cyan") {
                        document.body.classList.remove(themeColor);
                      }
                      onPressThemeColor("cyan");
                    }}
                  ></div>
                  <span>Cyan</span>
                </li>
                <li
                  data-theme="green"
                  className={themeColor === "theme-green" ? "active" : ""}
                >
                  <div
                    className="green"
                    onClick={() => {
                      if (themeColor !== "theme-green") {
                        document.body.classList.remove(themeColor);
                      }
                      onPressThemeColor("green");
                    }}
                  ></div>
                  <span>Green</span>
                </li>
                <li
                  data-theme="orange"
                  className={themeColor === "theme-orange" ? "active" : ""}
                >
                  <div
                    className="orange"
                    onClick={() => {
                      if (themeColor !== "theme-orange") {
                        document.body.classList.remove(themeColor);
                      }
                      onPressThemeColor("orange");
                    }}
                  ></div>
                  <span>Orange</span>
                </li>
                <li
                  data-theme="blush"
                  className={themeColor === "theme-blush" ? "active" : ""}
                >
                  <div
                    className="blush"
                    onClick={() => {
                      if (themeColor !== "theme-blush") {
                        document.body.classList.remove(themeColor);
                      }
                      onPressThemeColor("blush");
                    }}
                  ></div>
                  <span>Blush</span>
                </li>
              </ul>
              <hr />
              <h6>General Settings</h6>
              <ul className="setting-list list-unstyled">
                <li>
                  <label className="fancy-checkbox">
                    <input type="checkbox" name="checkbox" />
                    <span>Report Panel Usag</span>
                  </label>
                </li>
                <li>
                  <label className="fancy-checkbox">
                    <input type="checkbox" name="checkbox" />
                    <span>Email Redirect</span>
                  </label>
                </li>
                <li>
                  <label className="fancy-checkbox">
                    <input type="checkbox" name="checkbox" />
                    <span>Notifications</span>
                  </label>
                </li>
                <li>
                  <label className="fancy-checkbox">
                    <input type="checkbox" name="checkbox" />
                    <span>Auto Updates</span>
                  </label>
                </li>
                <li>
                  <label className="fancy-checkbox">
                    <input type="checkbox" name="checkbox" />
                    <span>Offline</span>
                  </label>
                </li>
                <li>
                  <label className="fancy-checkbox">
                    <input type="checkbox" name="checkbox" />
                    <span>Location Permission</span>
                  </label>
                </li>
              </ul>
            </div>
            <div
              className={
                sideMenuTab[3]
                  ? "tab-pane p-l-15 p-r-15 show active"
                  : "tab-pane p-l-15 p-r-15"
              }
              id="question"
            >
              <form>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="icon-magnifier"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                  />
                </div>
              </form>
              <ul className="list-unstyled question">
                <li className="menu-heading">HOW-TO</li>
                <li>
                  <a
                    href="#!"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    How to Create Campaign
                  </a>
                </li>
                <li>
                  <a
                    href="#!"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    Boost Your Sales
                  </a>
                </li>
                <li>
                  <a
                    href="#!"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    Website Analytics
                  </a>
                </li>
                <li className="menu-heading">ACCOUNT</li>
                <li>
                  <a
                    href="registration"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    Cearet New Account
                  </a>
                </li>
                <li>
                  <a
                    href="forgotpassword"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    Change Password?
                  </a>
                </li>
                <li>
                  <a
                    href="#!"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    Privacy &amp; Policy
                  </a>
                </li>
                <li className="menu-heading">BILLING</li>
                <li>
                  <a
                    href="#!"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    Payment info
                  </a>
                </li>
                <li>
                  <a
                    href="#!"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    Auto-Renewal
                  </a>
                </li>
                <li className="menu-button m-t-30">
                  <a
                    href="#!"
                    className="btn btn-primary"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <i className="icon-question"></i> Need Help?
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

NavbarMenu.propTypes = {
  addClassactive: PropTypes.array.isRequired,
  addClassactiveChildAuth: PropTypes.array.isRequired,
  addClassactiveChildMaps: PropTypes.array.isRequired,
  themeColor: PropTypes.string.isRequired,
  toggleNotification: PropTypes.bool.isRequired,
  toggleEqualizer: PropTypes.bool.isRequired,
  sideMenuTab: PropTypes.array.isRequired,
  isToastMessage: PropTypes.bool.isRequired,
  activeKey: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  onPressSideMenuToggle: PropTypes.func.isRequired,
  onPressThemeColor: PropTypes.func.isRequired,
  tostMessageLoad: PropTypes.func.isRequired,
};

const mapStateToProps = ({ navigationReducer }) => {
  const {
    addClassactive,
    addClassactiveChildAuth,
    addClassactiveChildMaps,
    themeColor,
    toggleNotification,
    toggleEqualizer,
    sideMenuTab,
    isToastMessage,
  } = navigationReducer;

  return {
    addClassactive,
    addClassactiveChildAuth,
    addClassactiveChildMaps,
    themeColor,
    toggleNotification,
    toggleEqualizer,
    sideMenuTab,
    isToastMessage,
  };
};

export default connect(mapStateToProps, {
  onPressDashbord,
  onPressDashbordChild,
  onPressThemeColor,
  onPressGeneralSetting,
  onPressNotification,
  onPressEqualizer,
  onPressSideMenuToggle,
  onPressMenuProfileDropdown,
  onPressSideMenuTab,
  tostMessageLoad,
})(NavbarMenu);
