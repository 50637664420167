import React, { useState } from "react";

export interface HocBackdropLoaderProps {
  setLoading: (status: boolean) => void;
  isLoading: boolean;
  setLoadingText: React.Dispatch<React.SetStateAction<string>>;
}

const HocBackdropLoader = (WrappedComponent: any, loadingMessage?: string) => {
  function HocBackdropLoader(props: any) {
    const debounceDelay = 300;
    let isLoadingIntermediate: boolean = true;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [loadingText, setLoadingText] = useState<string>(
      loadingMessage || 'Please wait while we process your request'
    );
    let timeoutVar: any = null;

    const setLoadingState = (isComponentLoading: boolean) => {
      isLoadingIntermediate = isComponentLoading;
      if (!timeoutVar) {
        timeoutVar = setTimeout(() => {
          setIsLoading(isLoadingIntermediate);
          timeoutVar = null;
        }, debounceDelay);
      }
    };

    return (
      <React.Fragment>
        {isLoading && (
          <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 9999 }}>
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', color: 'white' }}>
              <div style={{ marginBottom: '1.5rem' }}>
                {/* You can replace the following line with your custom loading indicator */}
                <div className="loader">Loading...</div>
              </div>
              <p style={{ fontSize: "1rem" }}>{loadingText}</p>
            </div>
          </div>
        )}
        <WrappedComponent {...props} setLoading={setLoadingState} isLoading={isLoading} setLoadingText={setLoadingText} />
      </React.Fragment>
    );
  }
  return HocBackdropLoader;
};

export default HocBackdropLoader;
