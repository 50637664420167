import React from "react";
import AppModal from "../../components/AppModal/AppModal";
import styled from "styled-components";

interface IAgreementModalProps {
  showModal: boolean;
  closeModal: () => void;
  contiue: any;
  data: any;
}

const AgreementModal: React.FC<IAgreementModalProps> = ({
  showModal,
  closeModal,
  contiue,
  data,
}) => {
  return (
    <AppModal show={showModal} onHide={closeModal} size="lg">
      <UploadModalWrapper>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">
              Eligibility Criteria
            </h5>
            <button
              onClick={() => {
                closeModal();
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div dangerouslySetInnerHTML={{ __html: data }} />
          </div>
          <div className="modal-footer">
            <button
              onClick={() => {
                contiue();
              }}
              className="btn btn-danger"
            >
              I Agree
            </button>
          </div>
        </div>
      </UploadModalWrapper>
    </AppModal>
  );
};

export default AgreementModal;

const UploadModalWrapper = styled.div`
  && {
    .modal-body {
      max-height: 400px;
      overflow-x: auto;
    }
  }
`;
