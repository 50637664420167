import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import "./loader.css";
export interface LoaderComponentProps {
    setLoading: any;
    setDebounceDelay?: React.Dispatch<React.SetStateAction<number>>;
}

export const withLoader = (WrappedComponent: any, loadingMessage?: string) => {
    function LoadingHOC(props: any) {
        let isLoadingIntermediate = true;
        const [isLoading, setLoading] = useState(true);
        const [debounceDelay] = useState<number>(600);
        let timeoutVar: any = null;

        const setLoadingState = (isComponentLoading: boolean) => {
            isLoadingIntermediate = isComponentLoading;
            if (!timeoutVar) {
                timeoutVar = setTimeout(() => {
                    setLoading(isLoadingIntermediate);
                    timeoutVar = null;
                }, debounceDelay);
            }
        };

        return (
            <div
                style={{
                    position: "relative",
                    width: "100%",
                    minHeight: isLoading ? "300px" : "0px",
                    height: "100%",
                }}
            >
                {isLoading && (
                    <div
                        style={{
                            width: "100%",
                            position: "absolute",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background: "rgba(255,255,255,1)",
                            height: "100%",
                            zIndex: 1000,
                        }}
                    >
                        <Loader />
                    </div>
                )}
                <WrappedComponent {...props} setLoading={setLoadingState} />
            </div>
        );
    }
    return LoadingHOC;
};

const moving = keyframes`
  50% {
    width: 100%;
  }
  100% {
    width: 0;
    right: 0;
    left: unset;
  }
`;

const Loader = styled.div`
  display: block;
  --height-of-loader: 4px;
  --loader-color: #0071e2;
  width: 130px;
  height: var(--height-of-loader);
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.2);
  position: relative;

  &::before {
    content: "";
    position: absolute;
    background: var(--loader-color);
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    border-radius: 30px;
    animation: ${moving} 1s ease-in-out infinite;
  }
`;