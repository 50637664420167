import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../Store";
import { FormAction } from "../../Store/FormConfig/form.actions";
import { showSnackBar } from "../../Store/FormConfig/form.slice";
const { FaEye, FaEyeSlash } = require("react-icons/fa")
const { useHistory } = require("react-router-dom")

const ResetPassword: React.FC = (): JSX.Element => {
  let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
  consoleBaseUrl = consoleBaseUrl + "api/v1";
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState<any>("");
  const [getahost, setGetahost] = useState<any>("");
  const [code, setCode] = useState<any>("");
  const [showPassword, setShowPassword] = useState(false); // State to manage password visibility
  const [showPassword2, setShowPassword2] = useState(false); // State to manage password visibility
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const dispatch = useAppDispatch();
  const navigate = useHistory();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const getaHost = searchParams.get("geta_host");
    const code = searchParams.get("code");
    setGetahost(getaHost);
    setCode(code);
  }, [window.location.search]);

  const handleResetPassword = () => {
    if(password === password2){
        if(!validatePassword(password)){
            setIsError(true)
            setErrorMessage("Use At Least 5 Characters One Uppercase Letter, One Lowercase Letter And One Number In Your Password.")
        }else{
            setIsError(false)
            let data = {
                "code" : code,
                "password" : password
            }
            fetch(`${consoleBaseUrl}/workspace/application-change-password`, {
                method: 'post',
                body: JSON.stringify(data),
                headers: {
                  'geta-host': getahost,
                }
              })
              .then(response => {
                return response.json(); // Assuming response is JSON data
              })
                .then(data => {
                    dispatch(
                        showSnackBar({
                          open: true,
                          message: data?.message,
                          severity: "success",
                        }),
                      );
                      navigate.push("/login", { replace: true });
                      setPassword("")
                      setPassword2("")
                    
                })
                .catch(error => {
                  dispatch(
                    showSnackBar({
                      open: true,
                      message: "something went wrong",
                      severity: "error",
                    }),
                  );
                });

        }
    }else{
        setErrorMessage("Passwords do not match")
        setIsError(true)
    }
    
  };
  const validatePassword = (password: string): boolean => {
    const eightCharRegex = /^.{5,}$/;
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const numberRegex = /[0-9]/;
    return (
      eightCharRegex.test(password) &&
      uppercaseRegex.test(password) &&
      lowercaseRegex.test(password) &&
      numberRegex.test(password)
    );
  };
  return (
    <div className="theme-cyan">
      <div>
        <div className="vertical-align-wrap">
          <div className="vertical-align-middle auth-main">
            <div className="auth-box">
              <div className="card">
                <div className="header">
                  <p className="lead">Reset Password</p>
                </div>
                <div className="body">
                  <p>Please enter your new password</p>
                  <form className="form-auth-small ng-untouched ng-pristine ng-valid">
                    <div className="form-group">
                      <div className="password-input" style={{display: "flex"}}>
                        <input
                          className="form-control"
                          placeholder="New Password"
                          type={showPassword ? "text" : "password"} // Toggle input type based on showPassword state
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        {/* Toggle password visibility button */}
                        <button
                          type="button"
                          className="toggle-password"
                          onClick={() => setShowPassword(!showPassword)}
                          style={{
                            border: "none",
                            backgroundColor: "white"
                          }}
                        >
                          {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </button>
                      </div>
                    </div>
                    <div className="form-group" style={{display: "flex"}}>
                      <input
                        className="form-control"
                        placeholder="Confirm New Password"
                        type={showPassword2 ? "text" : "password"} // Toggle input type based on showPassword state
                        value={password2}
                        onChange={(e) => setPassword2(e.target.value)}
                      />
                          <button
                          type="button"
                          className="toggle-password"
                          onClick={() => setShowPassword2(!showPassword2)}
                          style={{
                            border: "none",
                            backgroundColor: "white"
                          }}
                        >
                          {showPassword2 ? <FaEyeSlash /> : <FaEye />}
                        </button>
                    </div>
                    {isError ? <span className="helper-text" style={{color:"red"}}>
                        {errorMessage}
                      </span> :null}
                    <button
                      className="btn btn-primary btn-lg btn-block"
                      type="button"
                      onClick={() => handleResetPassword()}
                    >
                      RESET PASSWORD
                    </button>
                    <div className="bottom">
                      <span className="helper-text">
                        Know your password? <a href="login">Login</a>
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
