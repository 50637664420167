import { useEffect, useState } from 'react'
import Select from 'react-select'
import { checkRule, fieldOnChange, findObjectById, findObjectByName, onErrorHandle,rule } from '../../utils/commonService'
import { useAppDispatch } from '../../Store'
import { getAllCounty, getAllState, getErrorModel, getFieldJSONConfig, getHideFields, getHideOptionIds, getOptionMappingData, getReadOnlyFields, onSumbitStatus } from '../../Store/FormConfig/form.selectors'
import { useSelector } from 'react-redux'
import React from 'react'
import { FormAction } from '../../Store/FormConfig/form.actions'
import { setErrorModel, setHideFields, setModel, setOptionsData } from '../../Store/FormConfig/form.slice'
import { withLoader } from '../AppLoader/LoaderComponent'
export const AppSelect: React.FC<any> = ({ field, model, errorModel, fieldConfig, sectionId, setLoading,isMulti }) => {
    const submitStatus = useSelector(onSumbitStatus);
    const errorModelData = useSelector(getErrorModel)
    const getAllCountyList = useSelector(getAllCounty)
    const getAllStateList = useSelector(getAllState)
    const readOnlyField = useSelector(getReadOnlyFields)
    const hideOptionIds = useSelector(getHideOptionIds)
    const optionMappingData = useSelector(getOptionMappingData)
    const dispatch = useAppDispatch()
    const hideFields = useSelector(getHideFields)
    const [defaultValue, setDefaultValue] = useState("")
    const [customChoice, setCustomChoice] = useState([])
    const [customField, setCustomField] = useState(field)
    const [selestId , setSelectId] = useState("")
    let path = window.location.pathname
    const changeFn = (value: any) => {        
   
        fieldOnChange(value, model, field, fieldConfig, sectionId, errorModelData, dispatch,setLoading,rule);
        if(field?.onChange){
            let fieldData = findObjectById(fieldConfig,field?.option_mapping?.dependant_id)
            if(field?.option_mapping?.dependant_id){
                model[field?.option_mapping?.dependant_id] = ""
                if(field?.resetField){
                    model[field?.resetField] = ""
                }
                dispatch(setModel(model));
                let key = field?.option_mapping?.depednat_option_id 
                let data :any = {}  
                data[key] = []
                dispatch(setOptionsData(data))
            }
            let data :any = {}  
            data[fieldData.name] = []
            dispatch(setOptionsData(data))
            getAllStateListData(fieldData,true)
        }
        if(field?.resetField && !path?.includes("view")){
            let errorData = { ...errorModelData };
            let modelData = {...model}
            modelData[field?.resetField] = "" 
            dispatch(setModel(modelData));
            errorData[field?.resetField] = "Required";
            dispatch(setErrorModel(errorData));
        }
        
    }
    const onBlur = () => {
        onErrorHandle(field, errorModel, model, dispatch,sectionId,hideFields)
    }
    useEffect(() => {
        // setLoading(false)
        // if(getAllCountyList && field?.onInit === "getAllCountry" ){
        //     let data :any= {...field}
        //     setCustomChoice(getAllCountyList)

        //     data['choices'] = getAllCountyList
        //     field = data
        //     setCustomField(field)

        // }else if(getAllStateList && field?.onInit === "getAllState"){
        //     let data :any= {...field}

        //     data['choices'] = getAllCountyList
        //     field = data
        //     setCustomField(field)
        // }
    }, [])
    useEffect(() => {
        if(model[field?.id]?.id==='hinhv58W'){
            let hideFields = ['Vx9LTbTQ']
            dispatch(setHideFields(hideFields));
        }
        if(model[field?.id] || model[field?.name] || model[field?.id]?.id){
            fieldOnChange(model[field?.id] || model[field?.name], model, field, fieldConfig, sectionId, errorModelData, dispatch,setLoading,rule);
        }
        checkRule( model, field, fieldConfig, sectionId, errorModelData, dispatch,setLoading,rule)
        onErrorHandle(field, errorModel, model, dispatch,sectionId,hideFields)
        setDefaultValue(field?.options?.filter((res: any) => res.value === model[field?.code]))
        // if (field?.onInit) {
        //     if (field?.onInit === "getAllCountry") {
        //         let data = {
        //             "id" : field?.option_mapping?.id || field?.id 
        //         }
        //         getAllCountyListData(data?.id)
        //     } else if (field?.onInit && field?.onInit !== "getAllCountry") {
        //         getAllStateListData(field)
        //     }
        // }else{
        //     getAllCountyListData(field?.id)
        // }
    }, [])
    const onClickFn = (field:any) => {
   
        setSelectId(field.id)
        if (field?.onInit) {
            if (field?.onInit === "getAllCountry") {
                let data = {
                    "id" : field?.option_mapping?.id || field?.id 
                }
                getAllCountyListData(data?.id)
           
             
            } else if (field?.onInit && field?.onInit !== "getAllCountry") {
                getAllStateListData(field)
            }
        }else{

            getAllCountyListData(field?.id)
        }
    }
    const getAllCountyListData = (fieldId: any) => {
        // setLoading(true)
        let data = {
            moduleId : field?.moduleId,
            fieldId : fieldId
        }
        dispatch(FormAction.getAllCountry(data))
        .then((res:any) =>{
            let key = field.name 
            let data :any = {}  
            data[key] = res?.payload?.data?.data?.options
            dispatch(setOptionsData(data))
            // setLoading(false)
        })
        .catch((error:any) =>{
            // setLoading(false)
            
        })
    }
    const getAllStateListData = (field: any,action ?:any) => {
        // setLoading(true)
        let data = {
            fieldId: field?.option_mapping?.id,
            selectionId: model[field.dependant_field_id]?.id ? model[field.dependant_field_id]?.id : model[field.dependant_field_id],
            moduleId : field?.moduleId
        }
        dispatch(FormAction.getAllState(data))
        .then((res:any) => {
            if(res?.payload?.data?.data?.options?.length !== 0){
                let key = field.name 
                let data :any = {}  
                data[key] = res?.payload?.data?.data?.options
                dispatch(setOptionsData(data))
                if(action){
                    let hideFields = {
                        key: "show",
                        name: field.id,
                      };
                    dispatch(setHideFields(hideFields));
                    let errorData = { ...errorModelData };
                    model[res?.payload?.data?.data?.id] = ""
                    dispatch(setModel(model));
                    if (model[field?.id] === "" && field?.required) {
                      errorData[field?.id] = "Required";
                      dispatch(setErrorModel(errorData));
                    }
                }
                // setLoading(false)
            }else{
                if(action){
                    let hideFields = [field.id]
                    dispatch(setHideFields(hideFields));
                    model[field.id] = ""
                    dispatch(setModel(model));
                }
                // setLoading(false)
            }
         
        })
        .catch((error :any) => {
            // setLoading(false)
        })
    }    

    return (
        <>
           <div >
            <Select
                onMenuOpen={() => onClickFn(field)}
                className={`${submitStatus && errorModelData[field.id] && "parsley-error scrollTOError"
                            }`}
                onChange={(value: any) => changeFn(value)}
                onBlur={() => onBlur()}
                isLoading={optionMappingData[field.name] ? false :true}
                noOptionsMessage={() =>  'No found please click again'}
                options={
                    field?.condition !== undefined
                      ? model[field?.condition?.key]
                        ? optionMappingData[field?.option_key]  
                        : optionMappingData[field.name] 
                          ? optionMappingData[field.name] 
                          : field?.choices
                      : optionMappingData[field.name] ? hideOptionIds[field.name] ?  optionMappingData[field.name]?.filter((res :any) => !hideOptionIds[field.name]?.includes(res.id) ) : optionMappingData[field.name] : field.choices
                  }                // value={defaultValue[0]}
              value={model[field.id] === undefined ? null : typeof model[field.id] === 'object' ? model[field.id] :field?.condition !== undefined ? 
                    model[field?.condition?.key] ?
                        optionMappingData[field?.option_key]?.filter((res:any) => res?.id === model[field?.id] || model[field?.name] ) || optionMappingData[field.name] 
                        :optionMappingData[field.name]?.filter((res:any) => res?.id === model[field?.id] || model[field?.name] ) || optionMappingData[field.name] 
                        :optionMappingData[field.name]?.filter((res:any) => res?.id === model[field?.id] || model[field?.name] ) || optionMappingData[field.name] }
                defaultValue={model[field?.id]} 
                placeholder={field?.placeholder}
                isDisabled={readOnlyField.includes(field.id) ? true : field?.read_only ? true : field?.dependant_field_id  && !model[field?.dependant_field_id] && !model[field?.id]  ? true :false}
                key={"label"}
                isMulti={isMulti}
                menuPlacement="auto"
                styles={{
                    loadingIndicator : (base, state) => ({
                        display:"none"
                    }), 
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        maxWidth: "100%",
                        minHeight: "40px",
                        border: errorModel[field?.id] && submitStatus && state.isFocused ? `1px solid #2d2d2d !important` : (errorModel[field?.id] && submitStatus ? `1px solid red !important` : `1px solid #dedede !important`),
                        // background: errorModel[field?.id] && submitStatus ? "#f8f5f5" : "#ffff",
                        // border: errorModel[field?.id] && submitStatus ? `1px solid #efd8d8` : `1px solid #ced4da`,
                        boxShadow: state.isFocused ? `` : ``,
                        // border: errorModel[field?.id] && !readOnlyField.includes(field?.id) && submitStatus ? `1px solid red` : `1px solid #ced4da`,
                        // boxShadow: errorModel[field?.id]&& !readOnlyField.includes(field?.id) && submitStatus ? `1px solid red` : `1px solid #ced4da !important`,
                        '&:hover': {
                            // border: errorModel[field?.id] && !readOnlyField.includes(field?.id) && submitStatus ? `1px solid red` : `1px solid #ced4da`,
                            // boxShadow: errorModel[field?.id] && !readOnlyField.includes(field?.id) && submitStatus ? `1px solid red` : `1px solid #ced4da !important`,
                            // background: "#ffff"
                        }
                    }),
                }}
            />
            </div>
               
        </>
    )
}
