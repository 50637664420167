import React, { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  LoaderComponentProps,
  withLoader,
} from "../AppComponents/AppLoader/LoaderComponent";
import useInternetConnection from "../AppComponents/UseInternetConnection/useInternetConnection";
import { AppConfig } from "../Config/app-config";
import { useAppDispatch } from "../Store";
import { FormAction } from "../Store/FormConfig/form.actions";
import {
  getAllApplicationFormResponse,
  getPortalUserDetails,
  getPortalUserId
} from "../Store/FormConfig/form.selectors";
import {
  setCurrentStage,
  setPortalUserId,
  setSelectApplicationDetails
} from "../Store/FormConfig/form.slice";
import LogoiCON from "../assets/images/three-dot.svg";
import {
  navBar
} from "../utils/commonService";
import AgreementModal from "./AgreementModal/AgreementModal";
const { useHistory } = require("react-router-dom");

interface FormsListingProps extends LoaderComponentProps { }

const FormsListing: React.FC<FormsListingProps> = ({ setLoading }) => {
  // const FormsListing = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useHistory();
  const portal_user_id = localStorage.getItem("portal_user_id");
  const portalUserDetails = useSelector(getPortalUserDetails);
  const allApplicationsResponse = useSelector(getAllApplicationFormResponse);
  const [applicationResID, setApplicationResID] = React.useState(null)
  const [applicationResStatus, setApplicationResStatus] = React.useState("")


  const [item, setItem] = React.useState({});
  const { isOnline } = useInternetConnection();
  let checkInternet = ""
  if (!isOnline) {
    checkInternet = "You are offline. Please connect to the internet to continue."
  } else {
    checkInternet = ""
  }

  const portalUserId = useSelector(getPortalUserId);
  useEffect(() => {
    const appcongig_data = AppConfig.getLoggedInData();
    if (Object.keys(appcongig_data).length === 0) {
      dispatch(FormAction.verifyLogin({}));
    }
    dispatch(FormAction.getPortalUserDetails(portalUserId || portal_user_id))
      .then((res: any) => {
        if (res?.payload?.data?.Status) {
          dispatch(setPortalUserId(res?.payload?.data?.data?.portal_user_id));
        }
      })
      .catch((error: any) => {
        console.error("error", error);
      });
    // dispatch(setModel(data))
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLoading(true);
    localStorage.removeItem("is_user_view");
    if (portalUserDetails && portalUserDetails?.portal_user_id) {
      dispatch(
        FormAction.getApplicationFormsResponseList({
          portal_user_id: portalUserDetails?.portal_user_id,
          portal_id: portalUserDetails?.portal_id,
        }),
      );
      dispatch(
        FormAction.getApplicationFormsList({
          portal_id: portalUserDetails?.portal_id,
        }),
      ).finally(() => setLoading(false));
    }
  }, [portalUserDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = (item: any) => {
    dispatch(
      FormAction.createFormApplication({
        portal_id: portalUserDetails?.portal_id,
        portal_user_id: portalUserDetails?.portal_user_id,
        contact_id: portalUserDetails?.contact_id,
        application_form_id: item?.id,
      }),
    )
      .then((res: any) => {
        if (res?.payload?.data?.Status) {
          dispatch(setCurrentStage(0));
          dispatch(
            setSelectApplicationDetails(
              res?.payload?.data?.data?.application_response,
            ),
          );
          navigate.push(
            "/form/0/" +
            res?.payload?.data?.data?.application_response
              ?.application_response_id,
            { replace: true },
          );
        }
      })
      .catch((error: any) => {
        console.error("error", error);
      });
  };
  // const searchParams = new URLSearchParams(window.location.search);
  // const getaHost = searchParams.get('geta_host');
  // const portal_user_id_data = searchParams.get('portal_user_id');
  useEffect(() => {
    const data = AppConfig.getLoggedInData();
    if (Object.keys(data).length !== 0) {
      dispatch(FormAction.verifyLogin({}))
        .then((res: any) => {
          if (!res?.payload?.data?.status) {
            navigate.push("/login", { replace: true });
          }
        })
        .catch((error: any) => {
          console.error("error", error);
        });
    } else {
      navigate.push("/login", { replace: true });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onContinue = (
    stepArray: any,
    currentStep: any,
    applicantDetails: any,
  ) => {
    navBar.map((res: any, index: any) => {
      if (currentStep && res.name === currentStep) {
        dispatch(setCurrentStage(index));
        navigate.push(
          "/form/" + index + "/" + applicantDetails?.application_response_id,
          { replace: true },
        );
        dispatch(setSelectApplicationDetails(applicantDetails));
      } else {
        if (!applicantDetails?.max_stage) {
          dispatch(setCurrentStage(0));
          navigate.push(
            "/form/" + 0 + "/" + applicantDetails?.application_response_id,
            { replace: true },
          );
          dispatch(setSelectApplicationDetails(applicantDetails));
        }

      }
    });
  };

  const [showModal, setShowModal] = React.useState(false);
  const closeModal = () => {
    setShowModal(false);
  };
  const handleContinue = () => {
    closeModal();
    handleClick(item);
    setItem({});
  };

  const openUploadModal = (item: any) => {
    setItem(item);
    setShowModal(true);
  };

  const onView = (id: any) => {
    localStorage.setItem("is_user_view", "true");
    navigate.push(`/view/form/0/${id}`, { replace: true });
  }

  const handleDownloadPDF = (id: any) => {
    dispatch(FormAction.printPDFAction(id)).then(res => {
      if (res?.payload?.data?.data?.pdf_url) {
        window.open(res?.payload?.data?.data?.pdf_url, '_blank');
      }
    }).catch((error: any) => {
      console.error(error)
    })
  }

  useEffect(() => {
    if (allApplicationsResponse?.data?.length > 0) {
      setApplicationResID(allApplicationsResponse?.data[0]?.application_response_id);
      setApplicationResStatus(allApplicationsResponse?.data[0]?.status)
    } else {
      setApplicationResID(null);
    }
  }, [allApplicationsResponse]);

  const handleRedirect = () => {
    if (applicationResID) {
      navigate.push(`pi-card?id=${applicationResID}`)
    }
  };

  const eligibilityRequirements = `
  <div>
    <h6>A candidate seeking admission to the Ph.D. programme must satisfy any one of the following eligibility requirements:</h6>
    <p>
      A 1-year/2-semester master's degree programme after a 4-year/8-semester bachelor's degree programme <br />
      or <br />
      A 2-year/4-semester master's degree programme after a 3-year bachelor's degree programme or qualifications declared equivalent to the master's degree by the corresponding statutory regulatory body, with at least 55% marks in aggregate or its equivalent grade in a point scale wherever grading system is followed or equivalent qualification from a foreign educational institution accredited by an assessment and accreditation agency which is approved, recognized or authorized by an authority, established or incorporated under a law in its home country or any other statutory authority in that country to assess, accredit or assure quality and standards of the educational institution. A relaxation of 5% marks or its equivalent grade may be allowed for those belonging to SC/ST/ Differently-Abled,
    </p>
    <p>Provided that a candidate seeking admission after a 4-year/8-semester bachelor's degree programme should have a minimum of 75% marks in aggregate or its equivalent grade on a point scale wherever the grading system is followed. A relaxation of 5% marks or its equivalent grade may be allowed for those belonging to SC/ST/ Differently-Abled,</p>
    <p>Candidates who have completed the M.Phil. programme with at least 55% marks in aggregate or its equivalent grade in a point scale wherever grading system is followed or equivalent qualification from a foreign educational institution accredited by an assessment and accreditation agency which is approved, recognized or authorized by an authority, established or incorporated under a law in its home country or any other statutory authority in that country to assess, accredit or assure quality and standards of educational institutions, shall be eligible for admission to the Ph.D. programme.
    <p>A relaxation of 5% marks or its equivalent grade may be allowed for those belonging to SC/ST/Differently-Abled.</p>
  </div>

  <div>
    <p>International candidates (NRIs, Foreign Nationals and PIOs who have obtained Master's degree from foreign universities) are required to qualify IELTS with 6.5 score or IELA Test (Band 3) of SIU ELTIS, an equivalent test for English language proficiency.</p>
  </div>

  <div>
    <p>Please note that candidates holding Professional Qualifications/ Diplomas/ Post Graduate Diplomas/ Any other qualifications will need to submit the proof of equivalence of the qualifications to master's Degree from a Statutory Bodies like UGC/AICTE/BCI/MCI etc</p>
  </div>

  <div>
    <h6>Reservation:</h6>
    <ul>
      <li>Scheduled Castes - 15 %</li>
      <li>Scheduled Tribes - 7.5%</li>
      <li>Differently Abled - 3%</li>
      <li>Kashmiri Migrants - 2 Seats</li>
      <li>International Students - 15%</li>
    </ul>
  </div>
`;

  return (
    <TableWrapper>
      {checkInternet ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Translucent black overlay
            zIndex: 9999, // Ensure it's above other elements
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              padding: "16px",
              textAlign: "center",
              fontSize: "15px",
              // backgroundColor: "antiquewhite",
              color: "white",
              borderRadius: "8px",
            }}
          >
            You are offline. Please connect to the internet to continue.
          </div>
        </div>
      ) : null}
      <div className="col-lg-12">
        <div className="card">
          <div className="heading">
            <h6>User Details</h6>
          </div>

          {
            applicationResStatus.toLowerCase() === "submitted" && <DownloadPDF>
              <p className="text">Schedule for Personal Interactions Ph D 2024 has been generated. Click here to download</p>
              <button onClick={handleRedirect} className="btn btn-danger">Download Now</button>
            </DownloadPDF>
          }

          <UserDataWrapper>
            <div className="row">
              <div className="col">
                <strong>Registration Number</strong>
              </div>
              <div className="col">{portalUserDetails?.registration_number}</div>
            </div>

            <div className="row">
              <div className="col">
                <strong>Applicant Name</strong>
              </div>
              <div className="col">{portalUserDetails?.name}</div>
            </div>

            <div className="row">
              <div className="col">
                <strong>Mobile No.</strong>
              </div>
              <div className="col">
                {portalUserDetails?.mobile_phone_number}
              </div>
            </div>

            <div className="row">
              <div className="col">
                <strong>Email ID</strong>
              </div>
              <div className="col">{portalUserDetails?.email}</div>
            </div>
          </UserDataWrapper>

          <div className="body table-responsive" style={{ overflow: "initial" }}>
            <table className="table table-bordered">
              <thead className="thead-light">
                <tr style={{}}>
                  <th scope="col">Sr.No</th>
                  <th scope="col">Applicant ID</th>
                  <th scope="col">Name</th>
                  <th scope="col">Action</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {allApplicationsResponse?.data?.map(
                  (item: any, index: number) => (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>{item?.applicant_id}</td>
                      <td>{item?.form_name}</td>
                      <td style={{ width: "15%" }}>
                        {item?.payment_status === "completed" ? (
                          item?.application_response_id ? (
                            item?.status !== "submitted" ? (
                              <button
                                type="button"
                                className="btn btn-danger"
                                disabled={item?.status === "published"}
                                onClick={() => onContinue(navBar, item?.max_stage, item)}
                              >
                                Continue
                              </button>
                            ) : (
                              <p style={{ color: "green", margin: "0px" }}>Submitted</p>
                            )
                          ) : (
                            // <button
                            //   type="button"
                            //   className="btn btn-primary"
                            //   disabled={item?.status === "published"}
                            //   onClick={() => openUploadModal(item)}
                            // >
                            //   Apply Now
                            // </button>
                            <></>
                          )
                        ) : (
                          <a style={{ color: "red", width: "15%" }}>
                            The apply date for PhD Application has passed away. You cannot proceed further.
                          </a>
                        )}
                      </td>
                      <td>

                        {item?.application_response_id &&
                          item?.application_response_id !== null ? <Dropdown style={{ maxWidth: "19px" }}
                            drop="down" className="input-group-append dropdown">
                          <Dropdown.Toggle
                            variant="success"
                            as="div"
                            id="dropdown-basic"
                            className="hide-toggle"
                          >
                            <img style={{ cursor: "pointer" }} src={LogoiCON} width="20" height="20" alt="Lucid" />
                            {/* <button
                              aria-haspopup="true"
                              className="btn btn-outline-secondary dropdown-toggle dropdown-toggle"
                              type="button"
                              aria-expanded="false"
                              style={{ color: "green",border: "1px solid green"}}
                            >
                              More
                            </button> */}
                          </Dropdown.Toggle>

                          <Dropdown.Menu as="div" className="dropup" style={{
                            minWidth: "190px",
                            transform: "translate(-69px, -40px) "
                          }}>
                            <a style={{ cursor: "pointer" }} onClick={() => onView(item?.application_response_id)} className="dropdown-item">View Form</a>
                            {
                              item?.payment_status === "completed" && item?.status === "submitted" ?
                                <a style={{ cursor: "pointer" }} onClick={() => handleDownloadPDF(item?.application_response_id)} className="dropdown-item">Download PDF</a>
                                : null
                            }
                          </Dropdown.Menu>

                        </Dropdown> : null}

                      </td>
                    </tr>
                  ),
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div >

      <AgreementModal
        showModal={showModal}
        closeModal={closeModal}
        contiue={handleContinue}
        data={eligibilityRequirements}
      />
    </TableWrapper >
  );
};

// export default FormsListing;
export default withLoader(FormsListing);

const TableWrapper = styled.div`
  && {
    .heading {
      padding: 20px 20px 0px 20px;
      h6 {
        margin: 0;
      }
    }
    .card {
      overflow: auto;
      margin: 0px;
    }
    height: calc(100vh - 108px);
    margin-top: 24px;
    .col-lg-12 {
      height: 100%;
      .card {
        height: 100%;
      }
    }
  }
`;

const UserDataWrapper = styled.div`
  && {
    border: 1px solid #e0e0e0;
    padding: 0px 20px;
    overflow: hidden;
    margin: 20px;
    .row {
      padding: 16px 10px;
      border-bottom: 1px solid #e0e0e0;
    }
  }
`;

const DownloadPDF = styled.div`
    margin-top: 10px; 
    background: #dc35451a; 
    display: flex; 
    justify-content: center; 
    gap: 16px;
    padding: 16px 0px;
    
    .text {
      margin: 0px; 
      padding: 8px 0px;
      color: #000; 
      text-align: center;
    }
`;