import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../Store";
import { FormAction } from "../Store/FormConfig/form.actions";
import {
  setContactId,
  setPortalUserId,
  showSnackBar,
} from "../Store/FormConfig/form.slice";
import Logo from "../assets/images/logo.svg";
import { getLoginDataFromToken } from "../utils/commonService";
import styled from "styled-components";
import AppModal from "../components/AppModal/AppModal";
import AppTable from "../AppComponents/AppTable";
import {
  LoaderComponentProps,
  withLoader,
} from "../AppComponents/AppLoader/LoaderComponent";
const { FaEye, FaEyeSlash } = require("react-icons/fa");

const { useHistory } = require("react-router-dom");
interface LoginProps extends LoaderComponentProps {
  email: string;
  password: string;
  updateEmail: (email: string) => void;
  updatePassword: (password: string) => void;
  onLoggedin: () => void;
}

const Login: React.FC<LoginProps> = ({
  email,
  password,
  updateEmail,
  updatePassword,
  onLoggedin,
  setLoading,
}) => {
  const [isLoad, setIsLoad] = useState<boolean>(true);
  const [localEmail, setLocalEmail] = useState<string>("");
  const [localPassword, setLocalPassword] = useState<string>("");
  const [message, setMessage] = useState("");
  const navigate = useHistory();
  const dispatch = useAppDispatch();
  const [active, setActiveTab] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State to manage password visibility

  // Modals
  const [showModal, setShowModal] = useState(false);
  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const handleSave = () => {
    // Handle save logic here
    closeModal();
  };

  const [showModalDates, setShowModalDates] = useState(false);
  const openModalDates = () => {
    setShowModalDates(true);
  };
  const closeModalDates = () => {
    setShowModalDates(false);
  };

  const [showModalCities, setShowModalCities] = useState(false);
  const openModalCities = () => {
    setShowModalCities(true);
  };
  const closeModalCities = () => {
    setShowModalCities(false);
  };

  const [showModalPayment, setShowModalPayment] = useState(false);
  const openModalPayment = () => {
    setShowModalPayment(true);
  };
  const closeModalPayment = () => {
    setShowModalPayment(false);
  };

  const [showModalAdmitCard, setShowModalAdmitCard] = useState(false);
  const openModalAdmitCard = () => {
    setShowModalAdmitCard(true);
  };
  const closeModalAdmitCard = () => {
    setShowModalAdmitCard(false);
  };

  const [showModalHelpDesk, setShowModalHelpDesk] = useState(false);
  const openModalHelpdesk = () => {
    setShowModalHelpDesk(true);
  };
  const closeModalHelpDesk = () => {
    setShowModalHelpDesk(false);
  };

  // useEffect(() => {
  //   // setLoading(false);
  // }, []);

  const login = () => {
    const data = {
      email: localEmail,
      password: localPassword,
      domain: "symbiosis-applications.geta.ai",
    };
    if (localEmail === "") {
      setMessage("please enter email id");
    } else if (localPassword === "") {
      setMessage("please enter password");
    }
    dispatch(FormAction.loginApplication(data))
      .then((res: any) => {
        if (res?.payload?.data?.data?.status === true) {
          const decodedToken = getLoginDataFromToken(
            res?.payload?.data?.data?.token_serialized,
          );
          dispatch(setPortalUserId(res?.payload?.data?.data?.portal_user_id));
          dispatch(setContactId(res?.payload?.data?.data?.contact_id));
          localStorage.setItem(
            "portal_user_id",
            res?.payload?.data?.data?.portal_user_id,
          );
          navigate.push("/", { replace: true });
          dispatch(
            showSnackBar({
              open: true,
              message: "Login Successfully",
              severity: "success",
            }),
          );
        } else {
          setMessage(res?.payload?.data?.data?.message);
          dispatch(
            showSnackBar({
              open: true,
              message: "Login Failed",
              severity: "error",
            }),
          );
        }
      })
      .catch((error: any) => {
        console.error("error", error);
      });
  };
  useEffect(() => {
    const iframe = document.getElementById("formIfram") as HTMLIFrameElement; // Cast to HTMLIFrameElement
    if (!iframe) return;

    iframe.src =
      `https://forms.geta.ai/form/Jdc2HAjLnxDTUHc2C9G2BT/eEVvaGRUc2RaUzB6U2h3TDo2ZWQ4OTBiZDQwZWY5ODQ1NzUwYmZjNGZhYmQ5NjU0YTg0ZmFhOWYzMWRiOWYxYzE4NzNlMmM3NzdkYzdjYjdhYjA3M2UxMGZmN2U0NjBmM2MyMmI4OTdhYWVjZDkwN2Y=/asd` ||
      "";
  }, []);
  return (
    <div className="theme-cyan">
      {/* <div className="page-loader-wrapper" style={{ display: isLoad ? 'block' : 'none' }}>
        <div className="loader">
          <div className="m-t-30"><img src={Logo} width="48" height="48" alt="Lucid" /></div>
          <p>Please wait...</p>
        </div>
      </div> */}

      <div className="hide-border">
        <div className="vertical-align-wrap" style={{ background: "#fff" }}>
          <LoginPageWrapper>
            <div className=" auth-main">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-5 px-0">
                    <div className="left_login_box p992">
                      <div className="auth-box">
                        <div className="top logo-box">
                          <img
                            src={
                              "https://set2024.ishinfosys.com/SET20Y24/Images/LoginClientLogo.png"
                            }
                            alt="Lucid"
                            style={{
                              width: "301px",
                              height: "102px",
                              margin: "10px",
                            }}
                          />
                        </div>
                        <div className="form_heading">
                          <h1>Symbiosis Ph.D Application 2024</h1>
                        </div>
                        <div className="card" style={{ boxShadow: "none" }}>
                          <div className="header">
                          <p
                              className="lead"
                              onClick={() =>
                                navigate.push("/login", { replace: true })
                              }
                              style={{
                                fontSize: "14px",
                                color: "blue",
                                cursor: "pointer",
                              }}
                            >
                              {" "}
                              <i
                                className="fa fa-arrow-left"
                                style={{ marginRight: "5px" }}
                                aria-hidden="true"
                              ></i>
                              Back to Login
                            </p>
                            <p className="lead">Signup your account</p>
                          </div>
                          <div>
                            
                            <iframe
                              id="formIfram"
                              style={{
                                height: "1000px",
                                width: "100%",
                                border: "none",
                                marginTop: "-15px",
                              }}
                            />

                          </div>
                        </div>
                        {/* <div className="regitser">
                          <p>Don't have an account yet?  <a href='#'>Register</a></p>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 px-0 col-lg-7 px-0">
                    <div className="left_img">
                      <img
                        src="https://set2024.ishinfosys.com/SET20Y24/Images/loginClientBG.png"
                        alt=""
                      />
                    </div>
                    <div className="footer_info">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-sm-6 col-md-4">
                            <h4>Important Dates</h4>
                            <ul className="footer_listing">
                              <li
                                className={active ? "active" : ""}
                                onClick={() => {
                                  openModal();
                                  setActiveTab(true);
                                }}
                              >
                                Important Dates for Admission to Ph.D. Programme
                                2024
                              </li>
                              {/* <li onClick={openModalPayment}>
                                Payment Instructions
                              </li>
                              <li onClick={openModalAdmitCard}>
                                Admit Card Instructions
                              </li> */}
                            </ul>
                          </div>
                          <div className="col-sm-6 col-md-4">
                            <h4>Instructions</h4>
                            <ul className="footer_listing">
                              <li onClick={openModalDates}>
                                Payment Instructions
                              </li>
                            </ul>
                          </div>
                          <div className="col-sm-6 col-md-4">
                            <h4> Help Desk</h4>
                            <ul className="footer_listing">
                              <li onClick={openModalCities}>
                                Help Desk for Ph.D. programme
                              </li>
                              {/* <li onClick={openModalHelpdesk}>Help Desk</li>
                              <li>
                                <a
                                  style={{ color: "inherit" }}
                                  href="https://set2024.ishinfosys.com/SET20Y24/Bulletin.pdf"
                                  target="_blank"
                                >
                                  Admission Bulletin
                                </a>{" "}
                              </li> */}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </LoginPageWrapper>
        </div>
      </div>

      <AppModal
        show={showModal}
        onHide={closeModal}
        onSave={handleSave}
        size="xl"
      >
        <ModalWrapper>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Important Dates
              </h5>
              <button
                onClick={() => {
                  closeModal();
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h5 style={{ marginBottom: "12px" }}>
                Important Dates for Admission to Ph.D. Programme 2024
              </h5>

              <div className="body table-responsive">
                <table className="table table-bordered table1">
                  <thead>
                    <tr>
                      <th>Particulars</th>
                      <th>Date</th>
                      <th>Day</th>
                      <th>Time</th>
                      <th>Website / Location</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Online registration starts</td>
                      <td>13 June 2024</td>
                      <td>Thursday</td>
                      <td>12:00 Noon</td>
                      <td>
                        <a href="https://scri.siu.edu.in/">
                          https://scri.siu.edu.in/
                        </a>
                      </td>
                    </tr>

                    <tr>
                      <td>Release of print/ Website advertisements</td>
                      <td>16 June 2024</td>
                      <td>Sunday</td>
                      <td></td>
                      <td>As per the policy of SIU</td>
                    </tr>

                    <tr>
                      <td>Last date for online registration</td>
                      <td>29 July 2024</td>
                      <td>Monday</td>
                      <td>12 Midnight</td>
                      <td>
                        <a href="https://scri.siu.edu.in/">
                          https://scri.siu.edu.in/
                        </a>
                      </td>
                    </tr>

                    <tr>
                      <td>Last date for eligibility Checking</td>
                      <td>2 August 2024</td>
                      <td>Friday</td>
                      <td colSpan={2}>For internal reference only</td>
                    </tr>

                    <tr>
                      <td>Downloading admit cards</td>
                      <td>7 - 10 August 2024</td>
                      <td>Wednesday to Saturday</td>
                      <td>12 Midnight to 12:00 Noon</td>
                      <td>
                        <a href="https://scri.siu.edu.in/">
                          https://scri.siu.edu.in/
                        </a>
                      </td>
                    </tr>

                    <tr>
                      <td>Ph.D. Entrance Test</td>
                      <td>10 August 2024</td>
                      <td>Saturday</td>
                      <td>2:00 P.M. to 3:30 P.M.</td>
                      <td>Online</td>
                    </tr>

                    <tr>
                      <td>Release of results through login and password</td>
                      <td>16 August 2024</td>
                      <td>Friday</td>
                      <td>10:00 PM</td>
                      <td>
                        <a href="https://scri.siu.edu.in/">
                          https://scri.siu.edu.in/
                        </a>
                      </td>
                    </tr>

                    <tr>
                      <td>Personal Interaction</td>
                      <td>27 Aug - 13 Sept 2024</td> 
                      <td>Tuesday to Friday</td>
                      <td>10:00 A.M. to 5:00 P.M.</td>
                      <td>SCRI, Lavale, Pune through ZOOM</td>
                    </tr>

                    <tr>
                      <td>First Merit List through login and password</td>
                      <td>01 October 2024</td>
                      <td>Tuesday</td>
                      <td>10:00 PM</td>
                      <td>
                        <a href="https://scri.siu.edu.in/">
                          https://scri.siu.edu.in/
                        </a>
                      </td>
                    </tr>

                    <tr>
                      <td>Last date for payment of Fees</td>
                      <td>15 October 2024</td>
                      <td>Tuesday</td>
                      <td>12 Midnight</td>
                      <td>
                        <a href="https://scri.siu.edu.in/">
                          https://scri.siu.edu.in/
                        </a>
                      </td>
                    </tr>

                    <tr>
                      <td>Waitlist Result through login and password</td>
                      <td>18 October 2024</td>
                      <td>Friday</td>
                      <td>10:00 PM</td>
                      <td>
                        <a href="https://scri.siu.edu.in/">
                          https://scri.siu.edu.in/
                        </a>
                      </td>
                    </tr>

                    <tr>
                      <td>Waitlist Payment Closed</td>
                      <td>1 November 2024</td>
                      <td>Friday</td>
                      <td>12 Midnight</td>
                      <td>
                        <a href="https://scri.siu.edu.in/">
                          https://scri.siu.edu.in/
                        </a>
                      </td>
                    </tr>

                    <tr>
                      <td>Closing of Admissions</td>
                      <td>4 November 2024</td>
                      <td>Monday</td>
                      <td>5:00 PM</td>
                      <td>
                        <a href="https://scri.siu.edu.in/">
                          https://scri.siu.edu.in/
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary">
                Close
              </button>
            </div>
          </div>
        </ModalWrapper>
      </AppModal>

      <AppModal show={showModalDates} onHide={closeModalDates} size="lg">
        <ModalWrapper>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Payment Instructions
              </h5>
              <button
                onClick={() => {
                  closeModalDates();
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="body table-responsive">
              <h6>
                  NOTE:
                </h6>
                <ul>
                  <li>
                    The application fee is non-refundable and non-transferable.
                  </li>
                  <li>
                    The application  fee can be paid on or before the last date of the registration.
                  </li>
                  <li>
                    The application fees for the registration is applicable as per the following categories.
                  </li>
                </ul>

                <div className="body table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <td>General</td>
                        <td>Rs. 2150/- *</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>SC/ST</td>
                        <td>Rs. 1650/- *</td>
                      </tr>

                      <tr>
                        <td>Differently Abled</td>
                        <td>Rs. 1650/- *</td>
                      </tr>

                      <tr>
                        <td>Exempted General</td>
                        <td>Rs. 1150/- *</td>
                      </tr>

                      <tr>
                        <td>Exempted SC/ST</td>
                        <td>Rs. 650/- *</td>
                      </tr>

                      <tr>
                        <td>Exempted Differently Abled</td>
                        <td>Rs. 650/- *</td>
                      </tr>

                      <tr>
                        <td>Fees for applying (foreign students)</td>
                        <td>USD 50/*</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p>
                  Please read the rulebook carefully for exemption and non-exemption from Ph.D. Entrance Test before applying for the Ph.D. programm.
                </p>

                {/* <p><strong>You have 2 choices of payment modes.</strong></p>
                <ol>
                  <li>Billdesk Payment Gateway</li>
                  <li>Easebuzz payment Gateway</li>
                </ol> */}

                <p>
                  Kindly Visit{" "}
                  <a href="https://scri.siu.edu.in/">
                    https://scri.siu.edu.in/
                  </a>{" "}
                  for details
                </p>
              </div>
            </div>
            <div className="modal-footer">
              <button
                onClick={closeModalDates}
                type="button"
                className="btn btn-primary"
              >
                Close
              </button>
            </div>
          </div>
        </ModalWrapper>
      </AppModal>

      <AppModal show={showModalCities} onHide={closeModalCities} size="lg">
        <ModalWrapper>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Help Desk
              </h5>
              <button
                onClick={() => {
                  closeModalCities();
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h6>
                For general queries on Ph.D. programme, you may call the
                following numbers
              </h6>

              <ul style={{ marginTop: "20px" }}>
                <li>
                  <p>
                  020-6693-6916/6917/6918/6919, 7972282128, 8668988218
                  </p>
                </li>
                <li>
                  <p>
                    <a href="mailto:admissionphd@scri.siu.edu.in">
                      admissionphd@scri.siu.edu.in
                    </a>
                  </p>
                </li>
                <li>
                  <p>
                    Address: Symbiosis Center for Research and Innovation,
                    Symbiosis International (Deemed University) VI Floor, SIT
                    Building Lavale Hill base, Tal: Mulshi, Dist: Pune 412115
                  </p>
                </li>
              </ul>

              <p>
                <strong>Note:</strong> Helpdesk phone support will be available
                between 10:00am to 5:00pm, Monday - Friday.
              </p>
              <p>
                Kindly Visit{" "}
                <a href="https://scri.siu.edu.in/" target="_blank">
                  https://scri.siu.edu.in/
                </a>{" "}
                for details
              </p>
            </div>

            <div className="modal-footer">
              <button
                onClick={closeModalCities}
                type="button"
                className="btn btn-primary"
              >
                Close
              </button>
            </div>
          </div>
        </ModalWrapper>
      </AppModal>

      <AppModal
        show={showModalPayment}
        onHide={closeModalPayment}
        onSave={handleSave}
        size="lg"
      >
        <ModalWrapper>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Important Instructions regarding Admit Card
              </h5>
              <button
                onClick={() => {
                  closeModalPayment();
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div>
                <p>
                  <b>
                    NOTE: PET/SLAT/SITEEE registration fee and programme(s)
                    registration fee of the institute(s) are non-refundable and
                    non-transferable.
                  </b>
                </p>
                <ul>
                  <li>
                    You can pay the PET/SLAT/SITEEE registration amount till the
                    end of registration process.
                  </li>
                  <li>
                    For PET/SLAT/SITEEE, the payment process ends on April 12,
                    2024 and registration fee is INR 2250 per test.
                  </li>
                  <li>
                    The entrance test registration fee for PET/SLAT/SITEEE is
                    INR 2250/- per test. (Nonrefundable and non-transferable).
                  </li>
                  <li>
                    In addition to that you are also expected to pay the
                    programme registration fee of INR 1000/- for each programme
                    that you wish to apply for. (Non-refundable and
                    non-transferable)
                  </li>
                  <li>
                    For each test that you wish to appear for (PET/SLAT/SITEEE),
                    you are required to pay the entrance test registration fee
                    of INR 2250/- per test.
                  </li>
                </ul>
                <b>You have 3 choices of payment modes.</b>
                <ol>
                  <li>Billdesk Payment Gateway</li>
                  <li>Easebuzz payment Gateway</li>
                  <li>
                    Demand Draft:
                    <p>
                      Get a demand draft from any Nationalized Bank in favour of
                      "Symbiosis Test Secretariat" payable at Pune.
                    </p>
                    <b>
                      Note: The DD should reach STS by 5:00 pm on April 12, 2024
                      (Friday). If the DD reaches after this, it will not be
                      considered and will be returned to the candidate.
                    </b>
                    <p>
                      Choose the mode of Payment as 'DD Payment' in "Make
                      Payment" Tab. Take a print of the payment advice form,
                      fill in the DD Details, sign and send it along with the DD
                      to the Symbiosis Test Secretariat only at,
                    </p>
                    <address>
                      Symbiosis Test Secretariat,
                      <br />
                      Symbiosis International (Deemed University),
                      <br />
                      Gram : Lavale, Tal : Mulshi,
                      <br />
                      Dist. : Pune- 412115
                      <br />
                      Phone : 020-61936226 / 27.
                    </address>
                    <b>
                      As per your choice you can Submit a Demand Draft (DD) from
                      any Nationalized Bank in favour of "Symbiosis Test
                      Secretariat" payable at Pune.
                    </b>
                    <p>
                      The Symbiosis Test Secretariat will authenticate the
                      details of Demand Draft and will send you an email
                      confirming the payment. Only after the realization of
                      payment, would you be able to view your Admit Card.
                    </p>
                  </li>
                </ol>
              </div>
            </div>
            <div className="modal-footer">
              <button
                onClick={() => {
                  closeModalPayment();
                }}
                type="button"
                className="btn btn-primary"
              >
                Close
              </button>
            </div>
          </div>
        </ModalWrapper>
      </AppModal>

      <AppModal
        show={showModalAdmitCard}
        onHide={closeModalAdmitCard}
        onSave={handleSave}
        size="lg"
      >
        <ModalWrapper>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Payment Instructions
              </h5>
              <button
                onClick={() => {
                  closeModalAdmitCard();
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <ol className="numberingList instructionsFormFilling">
                <li>
                  PET/SLAT/SITEEE 2024 Admit Card should be printed (color
                  printouts only. B&W printouts will not be accepted) from the
                  PET website{" "}
                  <a href="http://www.PET-test.org/" target="_blank">
                    www.PET-test.org
                  </a>
                  .
                </li>
                <li>
                  If candidate has selected multiple tests, (s)he should carry
                  separate Admit Cards for each test.
                </li>
                <li>
                  <b>
                    SLAT-May 05, 2024 (Sunday) and May 11, 2024 (Saturday) 09.00
                    am to 10.00 am.
                  </b>{" "}
                  Please ensure that you arrive at your assigned Test Centre
                  latest by <b>07.30 AM</b> and occupy your assigned seat latest
                  by <b>08.30 AM.</b>
                </li>
                <li>
                  <b>
                    PET-May 05, 2024 (Sunday) and May 11, 2024 (Saturday) 11.30
                    am to 12.30 pm.
                  </b>{" "}
                  Please ensure that you arrive at your assigned Test Centre
                  latest by <b>10.00 AM</b> and occupy your assigned seat latest
                  by <b>11.00 AM.</b>
                </li>
                <li>
                  <b>
                    SITEEE - May 05, 2024 (Sunday) and May 11, 2024 (Saturday)
                    02.00 pm to 03.00 pm.
                  </b>{" "}
                  Please ensure that you arrive at your assigned Test Centre
                  latest by <b>12.30 PM </b> and occupy your assigned seat
                  latest by <b>01.30 PM.</b>
                </li>
                <li>
                  Please ensure that your latest passport size, colour
                  photograph is pasted on the Admit Card at the place provided
                  for before appearing for the test (Your photograph should not
                  be more than 4 months old).
                </li>
                <li>
                  Admit Card along with valid original Photo Identity proof
                  should be carried to the Test Centre at the time of the test.
                  An acceptable photo identity is any one of the following:
                  <ul>
                    <b>
                      <li>Passport</li>
                      <li>Driving license</li>
                      <li>Voter ID Card</li>
                      <li>PAN Card</li>
                      <li>AADHAR Card (UID)</li>
                      <li>College ID Card</li>
                    </b>
                  </ul>
                  <b>Note:</b> Soft copy of identity proof will not be accepted.
                </li>
                <li>
                  An authorized person will verify and authenticate your Admit
                  Card against the photo-id on the day of the test. Please{" "}
                  <b>preserve</b> this authenticated Admit Card for further
                  PI/WAT or ST-PI processes.
                </li>
                <li>
                  Candidates should note that an authenticated Admit Card is an
                  important document without which the candidate will not be
                  permitted to appear for further selection processes of SIU. If
                  the Authenticated Admit card is lost,{" "}
                  <b>
                    a duplicate Authenticated Admit Card will not be issued.
                  </b>{" "}
                  It should be retained till the admission process is over.
                </li>
                <li>
                  Cell phones, calculators, watch calculators, alarm clocks,
                  digital watches with built-in calculators/memory or any
                  electronic or smart devices are not allowed in the test hall.
                </li>
                <li>
                  Candidates will not be allowed to leave the test hall till the
                  test is completed. After submission of the test, the candidate
                  will not be allowed to re-enter the test hall.
                </li>
                <li>
                  Candidates are advised to locate the allocated test Centre at
                  least a day in advance to avoid any issue on the day of the
                  test. Candidates who reach the test centre late due to any
                  reason will not be allowed to enter the same under any
                  circumstances after the gates are closed.
                </li>
                <li>
                  <b>Travel and staying arrangements</b> will need to be{" "}
                  <b>done by the candidates.</b>
                </li>
                <li>
                  Utmost care is taken to prepare an error-free question paper.
                  However, if an error is detected in the question paper, the
                  Scrutiny Committee shall take an appropriate decision after
                  the examination, which shall be final.
                </li>
                <li>
                  The scores published by the Symbiosis Test Secretariat shall
                  be final and shall not be subject to any appeal or
                  revaluation.
                </li>
                <li>
                  <b>
                    Differently Abled/ Scheduled Caste/ Scheduled Tribes /
                    Kashmiri Migrants and Kashmiri Pandits/Kashmiri Hindu
                    Families (Non-Migrants) living in the Kashmiri Valley
                    category candidates are requested to upload their relevant
                    document(s) while registering for the PET/SLAT/ SITEEE.
                    Candidates failing to do this will be considered under the
                    Open Category.
                  </b>
                </li>
                <li>
                  Any attempt to impersonate or indulge in any malpractice,
                  identified at any stage of the admission process would
                  disqualify the candidate from the admission processes to all
                  UG programmes of Symbiosis International (Deemed University)
                  and may also lead to appropriate legal action as deemed fit.
                </li>
                <li>
                  Any malpractice / use of unfair means will lead to your
                  disqualification from the admission process of SIU. Instances
                  of any incorrect information or process violation detected at
                  any stage of the selection process will lead to immediate
                  disqualification of the candidate from the selection process,
                  and he/she will not be allowed to appear for SIU Entrance
                  Tests in the future. If such instances go undetected during
                  the current selection process but are detected in subsequent
                  years, such disqualification will take place with
                  retrospective effect.
                </li>
              </ol>
            </div>
            <div className="modal-footer">
              <button
                onClick={() => {
                  closeModalAdmitCard();
                }}
                type="button"
                className="btn btn-primary"
              >
                Close
              </button>
            </div>
          </div>
        </ModalWrapper>
      </AppModal>

      <AppModal
        show={showModalHelpDesk}
        onHide={closeModalHelpDesk}
        onSave={handleSave}
        size="lg"
      >
        <ModalWrapper>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Help Desk
              </h5>
              <button
                onClick={() => {
                  closeModalHelpDesk();
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <th>For General Queries:</th>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        Symbiosis Test Secretariat
                        <br />
                        Symbiosis International (Deemed University),
                      </strong>
                      <br />
                      Gram: Lavale, Tal: Mulshi,
                      <br />
                      District: Pune - 412115
                      <br />
                      Phone: +91-020-61936226 / 27 <br />
                      Toll-free number: 18001032420 <br />
                      Telegram link :{" "}
                      <a href="https://t.me/set_2024" target="blank">
                        https://t.me/set_2024
                      </a>{" "}
                      <br />
                      Email :{" "}
                      <a href="mailto:info@set-test.org">info@set-test.org</a>
                    </td>
                  </tr>
                  <tr>
                    <th>For Technical Queries:</th>
                  </tr>
                  <tr>
                    <td>Call: 7200495273 / 9962027165</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                onClick={() => {
                  closeModalHelpDesk();
                }}
                type="button"
                className="btn btn-primary"
              >
                Close
              </button>
            </div>
          </div>
        </ModalWrapper>
      </AppModal>
    </div>
  );
};
export default Login;
// export default withLoader(Login);

const LoginPageWrapper = styled.div`
  && {
    @media (max-width: 992px) {
      .card {
        margin: 0px;
      }
      .left_img img {
        padding: 0px !important;
        padding-top: 16px !important;
      }
      .p992 {
        padding: 32px 16px 0px 16px;
      }
      .footer_info {
        padding: 16px;
        height: auto !important;
      }
    }
    .footer_listing {
      padding-left: 0px;
      list-style: none;
      .active {
      }
      li {
        margin-bottom: 8px;
        cursor: pointer;
      }
      li:hover {
        color: #ff0000;
      }
    }
    .footer_info {
      padding: 16px 0px;
      height: 180px;
      display: flex;
      align-items: center;
      h4 {
        font-size: 20px;
      }
    }
    .form_heading {
      text-align: center;
      h1 {
        font-size: 25px;
        font-weight: 500;
      }
    }
    .auth-box {
      margin: 0;
    }
    .logo-box {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .left_login_box {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
    }
    .left_img img {
      width: 100%;
      height: calc(100vh - 180px);
      padding: 40px 0px 20px;
    }
    .regitser {
      text-align: center;
      p {
        margin-bottom: 8px;
        font-size: 14px;
      }
    }

    .rights {
      text-align: center;
      margin-top: 12px;
      p {
        margin: 0px;
        font-size: 12px;
      }
    }
  }
`;

const ModalWrapper = styled.div`
  && {
    .modal-body {
      max-height: 400px;
      overflow-y: auto;
    }
  }
`;
